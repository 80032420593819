import React, { useEffect, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import { Icon, Tooltip, Spacing } from '@ui'
import { useFloatable } from '@app/util'

import './index.scss'

const DISPLAY_TYPE = Object.freeze({
  DATE: 'date',
  MONTH: 'month'
})

export const PeriodSelect = ({
  value,
  onChange,
  executeOnMonthSelect,
  forbiddenPast,
  forbiddenFuture,
  forbiddenToday,
  forbiddenTooltipText,
  calendar,
  onlyCalendar,
  label,
  displayType,
  singleSelector
}) => {
  const floatable = useFloatable()
  const [form, setForm] = useState({
    date1: null,
    date2: null,
    displayedMonth: (value && value.start && value.end)
      ? moment(value.start).format('YYYY-MM')
      : (calendar && calendar.date)
        ? moment(calendar.date).format('YYYY-MM')
        : moment().format('YYYY-MM'),
    hoverOverDate: null
  })
  const nextMonth = moment(form.displayedMonth).add(1, 'months').format('YYYY-MM')

  useEffect(() => {
    if (value && value.start && value.end) {
      setForm({
        ...form,
        date1: moment(value.start),
        date2: moment(value.end)
      })
    }
    if (executeOnMonthSelect) {
      executeOnMonthSelect(moment(form.displayedMonth).format('YYYY-MM-DD'))
    }
  }, [])

  const isSelectedDate = (d) => {
    const { date1, date2 } = form
    if (!date1 && !date2) return false
    if (date1 && date1.format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD')) return true
    if (date2 && date2.format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD')) return true
    if (date1 && date2 && date1.format('YYYY-MM-DD') < moment(d).format('YYYY-MM-DD') && date2.format('YYYY-MM-DD') > moment(d).format('YYYY-MM-DD')) return true
    return false
  }

  const isHighlightedDate = (d) => {
    const { date1, date2, hoverOverDate } = form
    if (date2) return false
    if (!date1) return false
    if (!hoverOverDate) return false
    if (date1 && hoverOverDate && date1.format('YYYY-MM-DD') < moment(d).format('YYYY-MM-DD') && hoverOverDate.format('YYYY-MM-DD') >= moment(d).format('YYYY-MM-DD')) return true
    return false
  }

  const onClickDate = (d) => {
    const { date1, date2 } = form
    if (forbiddenPast && d.startOf('day').isBefore(moment().startOf('day'))) return false
    if (forbiddenFuture && d.startOf('day').isAfter(moment().startOf('day'))) return false
    if (forbiddenToday && d.startOf('day').isBefore(moment().add(1, 'day').startOf('day'))) return false

    const getFixedDate1 = (beforeFix) => {
      if (displayType === DISPLAY_TYPE.MONTH) {
        let ret = moment(beforeFix).startOf('month')
        if (forbiddenPast && ret.startOf('day').isBefore(moment().startOf('day'))) ret = moment().startOf('day')
        return ret
      } else {
        return beforeFix
      }
    }
    const getFixedDate2 = (beforeFix) => {
      if (displayType === DISPLAY_TYPE.MONTH) {
        let ret = moment(beforeFix).endOf('month')
        if (forbiddenFuture && ret.startOf('day').isAfter(moment().startOf('day'))) ret = moment().endOf('day')
        return ret
      } else {
        return beforeFix
      }
    }

    if (!date1 || moment(d).format('YYYY-MM-DD') <= date1.format('YYYY-MM-DD') || (date1 && date2)) {
      const d1 = getFixedDate1(moment(d))
      const d2 = null

      setForm({
        ...form,
        date1: d1,
        date2: d2
      })
      onChange && onChange({
        start: d1,
        end: d2
      })
    }
    if (date1 && !date2) {
      if (moment(d).format('YYYY-MM-DD') >= date1.format('YYYY-MM-DD')) {
        const d2 = getFixedDate2(moment(d)).endOf('day')

        setForm({
          ...form,
          date2: d2
        })
        onChange && onChange({
          start: date1,
          end: d2
        })
      } else {
        const d1 = getFixedDate1(moment(d))

        setForm({
          ...form,
          date1: d1
        })
      }
    }
  }

  const getCalRows = (month) => {
    const rows = []
    let i = 0
    let forward = moment(month).add(i, 'weeks').startOf('week')
    let back = moment(month).add(-i, 'weeks').endOf('week')

    while (forward.format('YYYY-MM') === month || back.format('YYYY-MM') === month) {
      const rowBack = []
      const rowForward = []
      for (let j = 0; j < 7; j++) {
        const db = back.clone().startOf('week').add(j, 'days')
        const df = forward.clone().startOf('week').add(j, 'days')
        rowBack.push({
          day: db,
          isSelected: isSelectedDate(db),
          isHighlighted: isHighlightedDate(db),
          thisMonth: db.format('YYYY-MM') === month,
          isToday: db.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
        rowForward.push({
          day: df,
          isSelected: isSelectedDate(df),
          isHighlighted: isHighlightedDate(df),
          thisMonth: df.format('YYYY-MM') === month,
          isToday: df.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        })
      }
      if (back.format('YYYY-MM') === month) {
        rows.unshift({ days: rowBack, date: back.clone().startOf('week') })
      }
      if (forward.format('YYYY-MM') === month) {
        rows.push({ days: rowForward, date: forward.clone().startOf('week') })
      }

      i += 1
      forward = moment(month).add(i, 'weeks').startOf('week')
      back = moment(month).add(-i, 'weeks').endOf('week')
    }

    // make sure we don't have duplicate weeks in the list
    if (rows.length >= 2 && rows[0].date.format('YYYY-MM-DD') === rows[1].date.format('YYYY-MM-DD')) {
      rows.shift()
    }

    return rows
  }

  const dayClassNames = (d, isDayForbidden, id, r) => {
    return classNames(
      'ds-period-select-cal-day',
      {
        date1: moment(d.day).isSame(form.date1, 'day'),
        date2: moment(d.day).isSame(form.date2, 'day'),
        today: d.isToday,
        'is-selected': d.isSelected,
        'is-first': (d.isSelected && id === 0) || (d.isHighlighted && id === 0),
        'is-last': (d.isSelected && id === (r.days.length - 1)) || (d.isHighlighted && id === (r.days.length - 1)),
        'is-highlighted': d.isHighlighted,
        'is-past-forbidden': isDayForbidden,
        'is-same': moment(form.date1).isSame(form.date2)
      },
      'date_' + moment(d.day).format('YYYY-MM-DD')
    )
  }

  const generateWeekLabels = (
    <>
      {[1, 2, 3, 4, 5, 6, 7].map((day, index) => (
        <div key={day} className='ds-period-select-cal-weekdays-day'>
          {moment().startOf('week').add(index, 'days').format('dd')}
        </div>
      ))}
    </>
  )

  const month1 = getCalRows(form.displayedMonth)
  const month2 = getCalRows(nextMonth)
  const firstMonth = moment(form.displayedMonth).format('MMMM YYYY')
  const secondMonth = moment(nextMonth).format('MMMM YYYY')

  const handleChange = (dateObject) => {
    // if past is forbidden, make sure date1 isn't in the past
    if (forbiddenPast && dateObject?.date1 && moment(dateObject?.date1).isBefore(moment().startOf('day'))) {
      if (forbiddenToday) {
        dateObject.date1 = moment().startOf('day').add(1, 'day')
      } else {
        dateObject.date1 = moment().startOf('day')
      }
    }
    // if future is forbidden, make sure date2 isn't in the future
    if (forbiddenFuture && dateObject?.date2 && moment(dateObject?.date2).isAfter(moment().endOf('day'))) {
      if (forbiddenToday) {
        dateObject.date2 = moment().endOf('day').subtract(1, 'day')
      } else {
        dateObject.date2 = moment().endOf('day')
      }
    }

    if (onChange) {
      onChange({
        start: dateObject.date1,
        end: dateObject.date2
      })
    }
    setForm({
      ...form,
      displayedMonth: singleSelector
        ? moment(dateObject.date1).format('YYYY-MM')
        : moment(dateObject.date2).subtract(1, 'month').format('YYYY-MM'),
      ...dateObject
    })
  }

  const addMonth = (date) => t('MONTH_' + moment(date).format('M')) + ' ' + moment(date).format('YYYY')
  const handleLabel = () => {
    if (displayType === DISPLAY_TYPE.MONTH) {
      return (
        (form.date1 ? addMonth(form.date1) : '?') + (
          form.date2
            ? addMonth(form.date1) === addMonth(form.date2)
              ? ''
              : ' - ' + addMonth(form.date2)
            : ' - ?'
        )
      )
    }
    return (
      (form.date1 ? moment(form.date1).format('D.M.YYYY') : '?') +
      ' - ' +
      (form.date2 ? moment(form.date2).format('D.M.YYYY') : '?')
    )
  }
  const currentQuarter = moment().quarter()

  const handleControlClick = (e, direction) => {
    const newMonth = moment(form.displayedMonth, 'YYYY-MM').add(direction, 'month')
    setForm({ ...form, displayedMonth: newMonth.format('YYYY-MM') })
    if (executeOnMonthSelect) executeOnMonthSelect(newMonth.format('YYYY-MM-DD'))
    e.stopPropagation()
  }
  const renderCalendar = () => (
    <div
      className={classNames('ds-period-select', { 'single-selector': singleSelector })}
    >
      <div className='ds-period-select-controls'>
        <div className='period-prev-container'>
          <Icon
            className='ds-period-select-next'
            onClick={(e) => handleControlClick(e, -1)}
            ico={Icon.ICONS.arrowLeft}
            color={Icon.COLORS.PRIMARY}
          />
          <div className='ds-title'>{firstMonth}</div>
        </div>
        <div className='period-next-container'>
          {singleSelector ? null : <div className='ds-title'>{secondMonth}</div>}
          <Icon
            className='ds-period-select-next'
            onClick={(e) => handleControlClick(e, 1)}
            ico={Icon.ICONS.arrowRight}
            color={Icon.COLORS.PRIMARY}
          />
        </div>
      </div>
      <div className='ds-months-calendar-container'>
        <div className='ds-calendar-container'>
          <div className='ds-period-select-cal-weekdays'>{generateWeekLabels}</div>

          {month1.map((r, iw) => (
            <div key={'week_' + iw} className={['ds-period-select-cal-week'].join(' ')}>
              {r.days.map((d, id) => {
                const isDayForbidden =
                  (forbiddenPast &&
                    d.day.startOf('day').isBefore(moment().startOf('day'))) ||
                  (forbiddenFuture &&
                    d.day.startOf('day').isAfter(moment().startOf('day'))) ||
                  (forbiddenToday &&
                    d.day.startOf('day').isBefore(moment().add(1, 'day').startOf('day')))
                const dayHTML = d.thisMonth ? (
                  <div
                    onClick={(e) => {
                      onClickDate(d.day)
                      e.stopPropagation()
                    }}
                    onMouseOver={() => setForm({ ...form, hoverOverDate: moment(d.day) })}
                    key={'day_' + iw.toString() + '_' + id.toString()}
                    className={dayClassNames(d, isDayForbidden, id, r)}
                  >
                    <div className='ds-day-label'>{moment(d.day).format('D')}</div>
                  </div>
                ) : (
                  <div className='empty-space' />
                )

                if (forbiddenTooltipText && isDayForbidden) {
                  return (
                    <Tooltip
                      key={'forbidden_day_' + iw.toString() + '_' + id.toString()}
                      anchor={dayHTML}
                    >
                      <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.BOTH}>
                        {forbiddenTooltipText}
                      </Spacing>
                    </Tooltip>
                  )
                } else {
                  return dayHTML
                }
              })}
            </div>
          ))}
        </div>
        {singleSelector ? null : (
          <div className='ds-calendar-container'>
            <div className='ds-period-select-cal-weekdays'>{generateWeekLabels}</div>
            {month2.map((r, iw) => (
              <div key={'week_' + iw} className={['ds-period-select-cal-week'].join(' ')}>
                {r.days.map((d, id) => {
                  const isDayForbidden =
                    (forbiddenPast &&
                      d.day.startOf('day').isBefore(moment().startOf('day'))) ||
                    (forbiddenFuture &&
                      d.day.startOf('day').isAfter(moment().startOf('day'))) ||
                    (forbiddenToday &&
                      d.day
                        .startOf('day')
                        .isBefore(moment().add(1, 'day').startOf('day')))
                  const dayHTML = d.thisMonth ? (
                    <div
                      onClick={(e) => {
                        onClickDate(d.day)
                        e.stopPropagation()
                      }}
                      onMouseOver={() =>
                        setForm({ ...form, hoverOverDate: moment(d.day) })}
                      key={'day_' + iw.toString() + '_' + id.toString()}
                      className={dayClassNames(d, isDayForbidden, id, r)}
                    >
                      <div className='ds-day-label'>{moment(d.day).format('D')}</div>
                    </div>
                  ) : (
                    <div className='empty-space' />
                  )

                  if (forbiddenTooltipText && isDayForbidden) {
                    return (
                      <Tooltip
                        key={'forbidden_day_' + iw.toString() + '_' + id.toString()}
                        anchor={dayHTML}
                      >
                        {forbiddenTooltipText}
                      </Tooltip>
                    )
                  } else {
                    return dayHTML
                  }
                })}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
  return (
    <div className='ds-period-select-input'>
      {onlyCalendar ? renderCalendar() : (
        <>
          {label ? <div className='input-label'>{label}</div> : null}
          <div className='container' {...floatable.floatAnchor}>
            <Icon ico={Icon.ICONS.calendar} size={Icon.SIZES.SMALL} />
            <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
            {!form.date1 && !form.date2 ? t('SELECT_PERIOD') : handleLabel()}
          </div>
          {floatable.open && (
            <div
              className={classNames('content', {
                'single-selector': singleSelector
              })}
              {...floatable.floatContainer}
            >
              {renderCalendar()}
              <div className='separator' />
              <div
                className={classNames('actions-group', {
                  'single-selector': singleSelector
                })}
              >
                <div
                  className='button'
                  onClick={() =>
                    handleChange({
                      date1: moment().startOf('month'),
                      date2: moment().endOf('month')
                    })}
                >
                  {t('THIS_MONTH')}
                </div>
                <div
                  className='button'
                  onClick={() =>
                    handleChange({
                      date1: moment().subtract(1, 'month').startOf('month'),
                      date2: moment().subtract(1, 'month').endOf('month')
                    })}
                >
                  {t('LAST_MONTH')}
                </div>
                <div
                  className='button'
                  onClick={() =>
                    handleChange({
                      date1: moment().quarter(currentQuarter).startOf('quarter'),
                      date2: moment().quarter(currentQuarter).endOf('quarter')
                    })}
                >
                  {t('THIS_QUARTER')}
                </div>
                <div
                  className='button'
                  onClick={() =>
                    handleChange({
                      date1: moment().startOf('year'),
                      date2: moment().endOf('year')
                    })}
                >
                  {t('THIS_YEAR')}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

PeriodSelect.propTypes = {
  // value: PropTypes.,
  onChange: PropTypes.func,
  executeOnMonthSelect: PropTypes.func,
  forbiddenPast: PropTypes.bool,
  forbiddenFuture: PropTypes.bool,
  forbiddenToday: PropTypes.bool,
  className: PropTypes.string,
  forbiddenTooltipText: PropTypes.string,
  onlyCalendar: PropTypes.bool,
  label: PropTypes.string,
  displayType: PropTypes.oneOf(Object.values(DISPLAY_TYPE)),
  singleSelector: PropTypes.bool
}
PeriodSelect.defaultProps = {
  // value,
  onChange: null,
  executeOnMonthSelect: null,
  forbiddenPast: false,
  forbiddenFuture: false,
  forbiddenToday: false,
  className: null,
  forbiddenTooltipText: null,
  onlyCalendar: false,
  displayType: DISPLAY_TYPE.DATE,
  singleSelector: false
}

PeriodSelect.DISPLAY_TYPE = DISPLAY_TYPE
