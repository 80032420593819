import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `query EmailCheckPublicQuery (
  $email: String!
) {
  emailCheckPublic (
    email: $email
  )
}`

async function requestEmailCheckPublic (variables) {
  const response = await fetchRequest({
    variables,
    query
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('email-check-public', errors)
  }
  return data.emailCheckPublic
}

export default requestEmailCheckPublic
