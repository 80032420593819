import store from '../store'
import isLoading from './is-loading'
import loadEmployeeDetail from './load-employee-detail'
import requestUserUpdate from '../request/user-update'
import addError from '../ac/add-error'
import { firstLoad } from '@app/ac/index'

export default (user, data) => {
  return async (dispatch) => {
    const { auth, me } = store.getState()

    let loadingType = 'user-update-personal'
    if (data?.language) loadingType = 'user-update-language'
    if (data?.newPassword) loadingType = 'user-update-password'
    if (data?.oldPassword) loadingType = 'user-update-password'
    if (data?.notificationPreferences) loadingType = 'user-update-notification-preferences'

    dispatch(isLoading(loadingType))

    const vars = {}
    if (data?.telephone) {
      vars.telephone = data.telephone
    }
    if (data?.language) {
      vars.language = data.language
    }
    if (data?.firstName) {
      vars.firstName = data.firstName
    }
    if (data?.lastName) {
      vars.lastName = data.lastName
    }
    if (data?.email) {
      vars.email = data.email
    }
    if (data?.newPassword) {
      vars.password = { newPassword: data.newPassword, oldPassword: (data.oldPassword || undefined) }
    }
    if (data?.notificationPreferences) {
      vars.notificationPreferences = data.notificationPreferences
    }

    const result = await requestUserUpdate({
      id: user,
      data: vars
    }, auth)
    if (result.error) {
      dispatch(addError(result.error))
    } else {
      // update me if updated user is myself
      if (me.id === user) {
        await dispatch(firstLoad(true, true)) // reload store.me silently
      } else {
        await dispatch(loadEmployeeDetail(user))
      }
    }
    dispatch(isLoading(loadingType, true))
    return result
  }
}
