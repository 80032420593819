"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNull = exports.isNotNull = exports.isUndefined = exports.isDefined = void 0;
const isDefined = (value) => value !== undefined;
exports.isDefined = isDefined;
const isUndefined = (value) => value === undefined;
exports.isUndefined = isUndefined;
const isNotNull = (value) => value !== null;
exports.isNotNull = isNotNull;
const isNull = (value) => value === null;
exports.isNull = isNull;
