import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.calendar, action) => {
  switch (action.type) {
    case aT.SET_CALENDAR:
      return action.data
    case aT.RESET_STORE:
      return initial.calendar
    case aT.SWITCH_WORKSPACE:
      return initial.calendar
    case aT.SET_CALENDAR_ROLE_VACATION_FUND: {
      const { id, displayVacationsAsDays, value } = action.data

      const role = state.roleStats[id]

      const newRole = displayVacationsAsDays
        ? { ...role, yearVacationRemainingDays: value - role.yearVacationDays }
        : { ...role, yearVacationRemainingMinutes: value - role.yearVacationMinutes }

      return {
        ...state, roleStats: { ...state.roleStats, [id]: newRole }
      }
    }
    default:
      return state
  }
}
