import React from 'react'
import { t } from 'i18next'

import {
  Button,
  CircledNumber,
  Icon,
  Input,
  Text,
  Flex,
  Spacing
} from '@ui'

export const UserAttributes = ({
  deleteAttribute,
  state,
  setState,
  setForm,
  hasWritePermission
}) => {
  const openId = state.openUserAtt
  const userAttributes = state.userAttributes || []
  const customAttributes = state.form.role?.customAttributes
  const sectionName = 'ds-ep-card-self'

  const activeAtt = userAttributes ? userAttributes.find((att) => att.id === openId) || {} : {}
  const activeRoleAtt = customAttributes ? customAttributes.find((att) => att.attributeId === openId) : null

  const setOpenId = (id) => setState({ ...state, openUserAtt: id })

  const createNewAttribute = () => {
    setState({
      ...state,
      openUserAtt: 'new',
      form: {
        ...state.form,
        role: {
          ...state.form.role,
          customAttributes: [
            ...customAttributes,
            {
              attributeId: 'new'
            }
          ]
        }
      },
      userAttributes: [
        ...userAttributes,
        { id: 'new' }
      ]
    })
  }

  const handleRoleAttributeSet = (attributeId, value) => {
    const attCopy = [...state.form.role.customAttributes]
    const index = attCopy.findIndex((att) => att.attributeId === attributeId)
    if (index >= 0) {
      attCopy[index] = { attributeId, value }
      setForm('role', {
        ...state.form.role,
        customAttributes: attCopy
      })
    } else {
      setForm('role', {
        ...state.form.role,
        customAttributes: [
          ...attCopy,
          { attributeId, value }
        ]
      })
    }
  }
  const handleAttributeSet = (id, key, value) => {
    const attributesCopy = [...state.userAttributes]
    const index = attributesCopy.findIndex((item) => item.id === id)
    if (index >= 0) {
      attributesCopy[index] = {
        ...attributesCopy[index],
        [key]: value
      }
      setState({ ...state, userAttributes: attributesCopy })
    }
  }

  const closeBtn = (
    <Button
      style={Button.STYLES.UNDERLINED}
      ico={Icon.ICONS.close1}
      onClick={() => setOpenId(null)}
      label={t('CLOSE')}
    />
  )

  const form = (attribute) => (
    <>
      <Spacing size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Input
          size={Input.SIZES.FULL_WIDTH}
          value={activeAtt.name}
          label={t('NAME_V2')}
          onChange={(value) => handleAttributeSet(openId, 'name', value)}
        />
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        <Input
          size={Input.SIZES.FULL_WIDTH}
          value={activeRoleAtt ? activeRoleAtt.value : ''}
          label={t('VALUE')}
          type={attribute.type === 'number' ? Input.TYPES.NUMBER : Input.TYPES.TEXT}
          onChange={(value) => handleRoleAttributeSet(
            openId,
            attribute.type === 'number' ? parseFloat(value) : value
          )}
        />
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_32} />
    </>
  )

  return (
    <>
      <Text
        type={Text.TYPES.BODY_LARGE}
        weight={Text.WEIGHTS.BOLD}
        color={state.activeCard === sectionName ? Text.COLORS.PRIMARY : Text.COLORS.BLACK}
      >
        <Flex align={Flex.POSITION.CENTER}>
          <Icon ico={Icon.ICONS.properties} size={Icon.SIZES.SMALL} />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          {t('CUSTOM')}
        </Flex>
      </Text>
      <Spacing size={Spacing.SIZES.SIZE_18} />
      {userAttributes && userAttributes.map((attribute, i) => (
        <div key={attribute.id}>
          <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.CENTER}>
            <Flex align={Flex.POSITION.CENTER}>
              <CircledNumber number={i + 1} />
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
              <Text
                color={Text.COLORS.PRIMARY}
                type={Text.TYPES.BODY_LARGE}
                weight={Text.WEIGHTS.BOLD}
                text={attribute.name || t('NAME_V2')}
              />
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
              <Text
                type={Text.TYPES.BODY_LARGE}
                weight={Text.WEIGHTS.BOLD}
                text={customAttributes ? customAttributes.find((item) => item.attributeId === attribute.id)?.value : t('VALUE')}
              />
            </Flex>
            {openId === attribute.id ? (
              <Flex>
                {closeBtn}
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                {openId !== 'new' && (
                  <Button
                    style={Button.STYLES.UNDERLINED}
                    color={Button.COLORS.RED}
                    onClick={() => deleteAttribute(attribute.id)}
                    ico={Icon.ICONS.delete}
                    label={t('DELETE')}
                  />
                )}
              </Flex>
            ) : hasWritePermission && (
              <Button
                style={Button.STYLES.UNDERLINED}
                ico={Icon.ICONS.edit}
                onClick={() => setOpenId(attribute.id)}
                label={t('EDIT')}
              />
            )}
          </Flex>
          <Spacing size={Spacing.SIZES.SIZE_14} />
          {openId === attribute.id && form(attribute)}
        </div>
      ))}
      {openId !== 'new' && hasWritePermission && (
        <Button
          style={Button.STYLES.UNDERLINED}
          ico={Icon.ICONS.plus}
          label={t('EMPLOYEE_PROFILE_USER_ATT_ADD_OWN')}
          onClick={() => {
            if (!userAttributes?.some((att) => att.id === 'new')) {
              createNewAttribute()
            } else {
              setOpenId('new')
            }
          }}
        />
      )}
    </>
  )
}

UserAttributes.propTypes = {}
UserAttributes.defaultProps = {}
