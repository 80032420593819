import React from 'react'

import { stringUtil } from '@app/util'

import './index.scss'

const Position = ({
  name,
  color,
  skill,
  lg,
  sm,
  esm,
  style,
  shortcut
}) => {
  const cN = ['ds-position']
  if (lg) cN.push('is-lg')
  if (sm) cN.push('is-sm')
  if (esm) cN.push('is-extra-sm')

  return (
    <div className={'ds-position-badge-wrapper' + (sm ? ' is-sm' : '') + (lg ? ' is-lg' : '')}>
      <div
        className={cN.join(' ')}
        style={style ? { ...style, backgroundColor: `#${color}` } : { backgroundColor: `#${color}` }}
        title={name}
      >
        <div className='ds-position-name'>{shortcut ? stringUtil.getShortcut(name, true) : name}</div>
      </div>
    </div>
  )
}

export default Position
