import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation PositionAssignUpdateMutation (
  $workspace: ID!,
  $user: ID!,
  $position: ID!,
  $contract: ID!
  $skill: Int
  $productionForecast: Float
) {
  positionAssignUpdate (
    workspace: $workspace
    user: $user
    position: $position
    contract: $contract
    skill: $skill
    productionForecast: $productionForecast
  ) {
    positionId
  }
}`

async function requestPositionAssignUpdate (variables, token) {
  const response = await fetchRequest({
    variables,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('position-assign-update', errors)
  }
  return data.positionAssignUpdate
}

export default requestPositionAssignUpdate
