"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// bump to reset local storage for everyone
const STORE_VERSION = 10;
exports.default = {
    // jwt
    auth: null,
    // application variables
    // variables: null,
    // loads asap if jwt is present
    me: {
        id: null,
        firstName: null,
        lastName: null,
        name: null,
        email: null,
        notificationPreferences: null,
        acl: {},
    },
    // me.workspaces
    workspaces: [],
    // active workspace id
    workspaceId: null,
    // active organization id
    organizationId: null,
    // common for all pages and requests
    loading: [],
    success: [],
    errors: [],
    fresh: {
        positions: [],
    },
    industries: [],
    positions: [],
    templates: [],
    shifts: [],
    availabilities: [],
    timeOffs: [],
    offers: [],
    workspaceEvents: [],
    holidays: [],
    notifications: [],
    tasks: [],
    reply: null,
    workspaceStats: null,
    myRole: null,
    shiftDetail: null,
    // potential candidates for a new
    // shift we're creating at the moment
    dummyShiftCandidates: null,
    locale: null,
    // either estimated from IP or real
    location: null,
    exportToken: null,
    // details about each employee - indexed by employee's ID
    employees: {},
    // full user info about one employee
    employeeDetail: null,
    cycleDetail: {
        data: [],
        calendar: {},
        lastSavedCycleShift: null,
    },
    // component/sidebar
    sidebar: null,
    // component/modal
    modal: null,
    // calendar-related
    calendar: null,
    calendarMultiSelect: {
        action: null,
        sourceEvents: [],
        targets: [],
        isActive: false,
        isSelectingTargets: false,
    },
    calendarSearch: {
        query: '',
        results: [],
    },
    calendarFilters: [],
    tablesConfig: {},
    enforcedLocality: null,
    // bump to reset local storage for everyone
    version: STORE_VERSION,
    // show/hide admin panel
    showAdminPanel: false,
    // Create news feed
    newsFeed: {},
    //  Announce Shift
    announceShifts: {
        announce: [],
        known: [],
        periodStart: '',
        periodEnd: '',
    },
    organization: {
        members: [],
        roles: [],
        countries: [],
        positions: [],
        hidden: null,
        settings: null,
    },
    // attendance
    // attendance: {
    //   attendance: [],
    //   config: {
    //     initial: true,
    //     view: {
    //       type: '',
    //       layout: '',
    //       info: {
    //         period: {
    //           end: '',
    //           start: '',
    //         },
    //         actualMonth: {
    //           period: {
    //             end: '',
    //             start: '',
    //           },
    //           currentDay: '',
    //         },
    //         daysNmb: null,
    //         year: '',
    //         monthDate: '',
    //         date: '',
    //       },
    //       loader: false,
    //     },
    //   },
    //   allStats: {
    //     plan: null,
    //     reality: null,
    //     inWork: null,
    //     overTime: null,
    //     confirmed: null,
    //     unavailabilityTime: null,
    //     pauseDuration: null,
    //   },
    //   attendanceByDays: [],
    //   attendanceDetails: {
    //     events: [],
    //     name: '',
    //     attendanceStats: {
    //       plan: null,
    //       reality: null,
    //       inWork: null,
    //       overTime: null,
    //       confirmed: null,
    //       unavailabilityTime: null,
    //       pauseDuration: null,
    //     },
    //     day: '',
    //     employeeId: null,
    //   },
    // },
    chat: {
        unread: 0,
    },
};
