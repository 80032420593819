import { fetchRequest, errorUtil } from '@app/util'

// $type: CustomAttributeValueType!
const query = `mutation UserAttributeUpdate(
    $workspaceId: ID!,
    $id: ID!
    $name: String,
    $shortName: String,
    ) {
      userAttributeUpdate(
        workspace: $workspaceId,
        shortName: $shortName,
        id: $id,
        name: $name
      ) {
        id
      }
}`

async function updateUserAttributes (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('', errors)
  }
  return data.userAttributeUpdate
}

export default updateUserAttributes
