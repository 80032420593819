import './index.scss'
import React from 'react'

export default ({ children, label, value, className, onClick, disabled }) => {
  const cN = ['ds-radio']
  if (value) cN.push('is-selected')
  if (disabled) cN.push('is-disabled')
  if (className) cN.push(className)

  return (
    <div onClick={disabled ? null : onClick} className={cN.join(' ')}>
      <div className='ds-radio-el' />
      {children || label
        ? <div className='ds-radio-label'>{children || label}</div>
        : null}
    </div>
  )
}
