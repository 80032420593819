import React from 'react'
import { t } from 'i18next'

import { Flex, Spacing } from '@ui'
import { numberUtil, timeUtil } from '@app/util'
import { formatHours } from '@app/util/format-hours'
import { InfoBoxV2 } from '@app/component/ui/info-box/InfoBoxV2'

export function InfoBoxes ({
  roleStats,
  hasFixedHoursContract,
  hasContractWithVacationFund,
  wsBalancingPeriodRelevant,
  wsDisplayVacationsAsDays,
  contracts,
  workspaceId,
  userId
}) {
  const monthPlanned = timeUtil.asHour(roleStats.monthPlannedMinutes)
  const monthContract = timeUtil.asHour(roleStats.monthContractMinutes)
  const monthOverTime = timeUtil.asHour(roleStats.monthOverTimeMinutes)

  const balancingPeriodPlanned = timeUtil.asHour(roleStats.balancingPeriodPlannedMinutes)
  const balancingPeriodContract = timeUtil.asHour(roleStats.balancingPeriodContractMinutes)

  const vacationLabels = t('EMPLOYEE_PROFILE_CARD_OVERVIEW_WIDGET_CHOSEN').split('/')
  let vacationFund = wsDisplayVacationsAsDays
    ? roleStats.yearVacationDays + roleStats.yearVacationRemainingDays
    : roleStats.yearVacationMinutes + roleStats.yearVacationRemainingMinutes
  let vacationTaken = wsDisplayVacationsAsDays
    ? roleStats.yearVacationDays
    : roleStats.yearVacationMinutes
  // format the vacation numbers depending on whether the workspace uses vacation hours or days (wsDisplayVacationsAsDays)
  if (wsDisplayVacationsAsDays) {
    vacationFund = numberUtil.round2decimals(vacationFund) + ' ' + t('DAYS_COUNT')
    vacationTaken = numberUtil.round2decimals(vacationTaken) + ' ' + t('DAYS_COUNT')
  } else {
    vacationFund = timeUtil.asHour(vacationFund)
    vacationTaken = timeUtil.asHour(vacationTaken)
  }

  return (
    <Flex>
      <InfoBoxV2
        title={t('THIS_MONTH')}
        cols={[{
          data: [
            {
              label: t('ATTENDANCE'),
              value: monthPlanned,
              dataType: InfoBoxV2.DATA_TYPES.HOURS
            },
            {
              label: t('FOND'),
              value: monthContract,
              dataType: InfoBoxV2.DATA_TYPES.HOURS
            },
            {
              label: t('OVERTIME'),
              value: `(+${formatHours(monthOverTime)})`,
              dataType: InfoBoxV2.DATA_TYPES.TEXT,
              helpText: t('ATTEN_WIDG_HELP_OVERTIME')
            },
            {
              label: t('DIFFERENCE'),
              value: monthPlanned - monthContract,
              dataType: InfoBoxV2.DATA_TYPES.HOURS,
              fieldType: InfoBoxV2.FIELD_TYPES.DIFFERENCE
            }
          ]
        }]}
      />
      {wsBalancingPeriodRelevant && hasFixedHoursContract && (
        <>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
          <InfoBoxV2
            title={t('BALANCING_PERIOD')}
            progress={
              balancingPeriodContract === 0
                ? 100
                : numberUtil.calculatePercentage({
                  baseValue: balancingPeriodContract,
                  currentValue: balancingPeriodPlanned
                })
            }
            cols={[{
              data: [
                {
                  label: t('ATTENDANCE'),
                  value: balancingPeriodPlanned,
                  dataType: InfoBoxV2.DATA_TYPES.HOURS
                },
                {
                  label: t('FOND'),
                  value: balancingPeriodContract,
                  dataType: InfoBoxV2.DATA_TYPES.HOURS
                }
              ]
            }]}
          />
        </>
      )}
      {hasContractWithVacationFund && (
        <>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
          <InfoBoxV2
            title={t('EMPLOYEE_PROFILE_CARD_OVERVIEW_WIDGET_HOLIDAY')}
            progress={vacationFund === 0
              ? 100
              : numberUtil.calculatePercentage({
                baseValue: vacationFund,
                currentValue: vacationTaken
              })}
            cols={[
              {
                data: [
                  {
                    label: vacationLabels[0],
                    value: vacationTaken,
                    dataType: InfoBoxV2.DATA_TYPES.HOURS
                  },
                  {
                    label: vacationLabels[1],
                    value: vacationFund,
                    dataType: InfoBoxV2.DATA_TYPES.HOURS
                  }
                ]
              }
            ]}
          />
        </>
      )}

    </Flex>
  )
}
