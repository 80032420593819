import React from 'react'
import { t } from 'i18next'
import QRCode from 'qrcode.react'

import Android from '@app/image/android-store.svg'
import Apple from '@app/image/apple-store.svg'
import {
  Icon,
  Link,
  Text,
  Flex,
  Spacing
} from '@ui'

export const Mobile = ({ activeCard }) => {
  const androidLink = 'https://play.google.com/store/apps/details?id=com.dayswaps.app'
  const iOsLink = 'https://apps.apple.com/app/id1544842253'
  const qrSize = '4.5rem'
  return (
    <Flex justify={Flex.POSITION.SPC_BETWEEN}>
      <div style={{ width: '10.75rem' }}>
        <Text
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.BOLD}
          color={activeCard === 'ds-ep-card-mobile' ? Text.COLORS.PRIMARY : Text.COLORS.BLACK}
        >
          <Flex align={Flex.POSITION.CENTER}>
            <Icon ico={Icon.ICONS.phone} size={Icon.SIZES.SMALL} />
            <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
            {t('ACC_SET_APP_TITLE')}
          </Flex>
        </Text>
        <Spacing size={Spacing.SIZES.SIZE_18} />
        <Text
          type={Text.TYPES.BODY_MEDIUM}
          text={t('ACC_SET_APP_TXT')}
          color={Text.COLORS.GRAY}
        />
      </div>
      <Flex>
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            text='Android'
          />
          <Spacing size={Spacing.SIZES.SIZE_18} />
          <Link
            to={androidLink}
            type={Link.TYPES.A_TAG}
          >
            <QRCode
              renderAs='canvas'
              style={{ width: qrSize, height: qrSize }}
              value={androidLink}
            />
            <Spacing size={Spacing.SIZES.SIZE_8} />
            <Android width={qrSize} />
          </Link>
        </Flex>
        <Spacing size={Spacing.SIZES.SIZE_48} type={Spacing.TYPES.HORIZONTAL}>
          <Flex direction={Flex.DIRECTION.COLUMN}>
            <Text
              type={Text.TYPES.BODY_MEDIUM}
              weight={Text.WEIGHTS.BOLD}
              text='iOS'
            />
            <Spacing size={Spacing.SIZES.SIZE_18} />
            <Link
              to={iOsLink}
              type={Link.TYPES.A_TAG}
            >
              <QRCode
                renderAs='canvas'
                style={{ width: qrSize, height: qrSize }}
                value={iOsLink}
              />
              <Spacing size={Spacing.SIZES.SIZE_8} />
              <Apple width={qrSize} />
            </Link>
          </Flex>
        </Spacing>
      </Flex>
    </Flex>
  )
}

Mobile.propTypes = {}
Mobile.defaultProps = {}
