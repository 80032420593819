import React, { Fragment } from 'react'
import { t } from 'i18next'

import {
  Position,
  Text,
  Input,
  Locality,
  PhoneInput,
  Flex,
  Spacing,
  Icon,
  Tooltip
} from '@ui'
import { miscUtil } from '@app/util'

import connect from '../connect'
import moment from 'moment'

export const Data = connect(({
  isPreventedByPlugin,
  disabled,
  form,
  handleChange,
  handleGenerateTerminalPIN,
  ee,
  isMe,
  workspace,
  contracts,
  validateEmail,
  emailError
}) => {
  const activity = miscUtil.getEmployeeActivity(ee)
  const formData = form?.data
  const formRole = form?.role
  // if workspace?.displayVacationsAsDays is not true, we will need to convert vacation fund hours to days (because BE keeps this in days only),
  // so we prepare the conversion here
  // also in EmployeeCard.js
  const wsDisplayVacationsAsDays = workspace?.displayVacationsAsDays
  const filteredSortedContracts = contracts
    .filter(c => {
      const ct = workspace?.contractTypes?.find(ct => ct.id === c.type)
      return ct?.rules?.fixedWorkHours
    })
    .sort((a, b) => moment(a?.period?.start).isBefore(b?.period?.start) ? 1 : -1)
  const vacationDaysToHoursMultiplier = filteredSortedContracts?.length
    ? filteredSortedContracts[0]?.options?.operation === 'threeShift'
      ? 7.5
      : filteredSortedContracts[0]?.options?.operation === 'twoShift'
        ? 7.75
        : 8
    : 8
  const handleDataChange = (key, value) => handleChange('data', { [key]: value })
  const handleRoleChange = (key, value) => handleChange('role', {
    ...formRole,
    [key]: value
  })

  return (
    <>
      <Flex direction={Flex.DIRECTION.ROW}>
        <Flex direction={Flex.DIRECTION.COLUMN} flex='1'>
          <Text
            text={t('POSITIONS')}
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
          />
          <Flex direction={Flex.DIRECTION.ROW} wrap='wrap'>
            {ee?.positions?.filter(p => !p.archived).map((elem) => {
              return (
                <Fragment key={elem.id}>
                  <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.HORIZONTAL}>
                    <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.VERTICAL}>
                      <Position key={'quick_pos_' + elem.id} {...elem} />
                    </Spacing>
                  </Spacing>
                </Fragment>
              )
            })}
          </Flex>
        </Flex>
        <Flex direction={Flex.DIRECTION.COLUMN} flex='1'>
          <Text
            text={t('LOCALITY')}
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
          />
          <Flex direction={Flex.DIRECTION.ROW} wrap='wrap'>
            {ee.localities && ee.localities.map((elem) => (
              <Fragment key={elem.id}>
                <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.HORIZONTAL}>
                  <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.VERTICAL}>
                    <Locality key={elem} id={elem.id || elem} />
                  </Spacing>
                </Spacing>
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Input
          disabled={!isMe}
          size={Input.SIZES.FULL_WIDTH}
          value={isMe ? formData?.email : ee.dummy ? t('NOT_SET') : ee.email}
          label={t('EMAIL')}
          onChange={(val) => handleDataChange('email', val)}
          onBlur={validateEmail}
          placeholder={t('REG_EMAIL_PLACEHOLDER')}
          hasError={emailError}
          errorMessage={emailError ? t('FORM_ERR_UNAVAILABLE_EMAIL') : null}
        />
        <Spacing
          type={Spacing.TYPES.HORIZONTAL}
          size={Spacing.SIZES.SIZE_8}
        />
        <PhoneInput
          size={Input.SIZES.FULL_WIDTH}
          value={formData?.telephone || undefined}
          label={t('PHONE')}
          placeholder={t('REG_PHONE_PLACEHOLDER')}
          onChange={(val) => handleDataChange('telephone', val)}
        />
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        {isMe
          ? (
            // NAME change inputs if I'm viewing my own profile
            <>
              <Input
                size={Input.SIZES.FULL_WIDTH}
                value={formData?.lastName || ''}
                label={t('LAST_NAME')}
                disabled={false /* user can change their name on their own User object any time */}
                placeholder={t('REG_LAST_NAME_PLACEHOLDER')}
                onChange={(val) => {
                  handleDataChange('lastName', val)
                }}
              />
              <Spacing
                type={Spacing.TYPES.HORIZONTAL}
                size={Spacing.SIZES.SIZE_8}
              />
              <Input
                size={Input.SIZES.FULL_WIDTH}
                value={formData?.firstName || ''}
                label={t('FIRST_NAME')}
                disabled={false /* we can change our User's name any time */}
                placeholder={t('REG_FIRST_NAME_PLACEHOLDER')}
                onChange={(val) => {
                  handleDataChange('firstName', val)
                }}
              />
            </>
          )
          : (
            // NAME change inputs if I'm a manager and I'm viewing some other employee
            <>
              <Input
                size={Input.SIZES.FULL_WIDTH}
                value={formRole?.customData?.lastName || formData?.lastName || ''}
                label={t('LAST_NAME')}
                disabled={disabled || isPreventedByPlugin('editEmployeeDataName')}
                placeholder={t('REG_LAST_NAME_PLACEHOLDER')}
                onChange={(val) => handleRoleChange('customData', { ...formRole?.customData, lastName: val })}
              />
              <Spacing
                type={Spacing.TYPES.HORIZONTAL}
                size={Spacing.SIZES.SIZE_8}
              />
              <Input
                size={Input.SIZES.FULL_WIDTH}
                value={formRole?.customData?.firstName || formData?.firstName || ''}
                label={t('FIRST_NAME')}
                disabled={disabled || isPreventedByPlugin('editEmployeeDataName')}
                placeholder={t('REG_FIRST_NAME_PLACEHOLDER')}
                onChange={(val) => handleRoleChange('customData', { ...formRole?.customData, firstName: val })}
              />
            </>
          )}

      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Input
          size={Input.SIZES.FULL_WIDTH}
          value={formRole?.customData?.address || ''}
          label={t('EMPLOYEE_PROFILE_ADDRESS')}
          disabled={disabled || isPreventedByPlugin('editEmployeeDataAddress')}
          placeholder={t('EMPLOYEE_PROFILE_ADDRESS_PLACEHOLDER')}
          onChange={(val) => handleRoleChange('customData', { ...formRole?.customData, address: val })}
        />
        <Spacing
          type={Spacing.TYPES.HORIZONTAL}
          size={Spacing.SIZES.SIZE_8}
        />
        <Input
          type='date'
          size={Input.SIZES.FULL_WIDTH}
          value={moment(formRole?.birthdate).isValid()
            ? moment(formRole?.birthdate).format('YYYY-MM-DD')
            : ''}
          label={t('EMPLOYEE_PROFILE_BIRTHDATE')}
          disabled={disabled || isPreventedByPlugin('editEmployeeDataBirthDate')}
          max={moment().format('YYYY-MM-DD')}
          placeholder='1985-01-01'
          onChange={(val) => handleRoleChange('birthdate', val)}
        />
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Flex flex='1'>

          <Input
            size={Input.SIZES.FULL_WIDTH}
            value={formRole?.customData?.employeeId || ''}
            label={t('EMPLOYEE_PROFILE_CARD_ID')}
            disabled={disabled || isPreventedByPlugin('editEmployeeDataEmployeeId')}
            placeholder={t('EMPLOYEE_PROFILE_CARD_ID_PLACEHOLDER')}
            onChange={(val) => handleRoleChange('customData', { ...formRole?.customData, employeeId: `${val}` })}
          />
        </Flex>
        <Spacing
          type={Spacing.TYPES.HORIZONTAL}
          size={Spacing.SIZES.SIZE_8}
        />
        <Flex flex='1' align={Flex.POSITION.CENTER} justify={Flex.POSITION.START}>
          <Flex flex='1' align={Flex.POSITION.CENTER} justify={Flex.POSITION.START}>
            {wsDisplayVacationsAsDays ? (
              <Input
                size={Input.SIZES.FULL_WIDTH}
                value={formRole?.vacationDays || 0}
                label={t('EMPLOYEE_PROFILE_HOLIDAY_FUND')}
                type='number'
                min={0}
                max={365}
                step={1}
                disabled={disabled || isPreventedByPlugin('editEmployeeDataVacationDays')}
                placeholder={t('EMPLOYEE_PROFILE_HOLIDAY_FUND_PLACEHOLDER')}
                onChange={(val) => {
                  const finalVal = !isNaN(parseFloat(val)) ? parseFloat(val) : null
                  return handleRoleChange('vacationDays', finalVal > 365 ? 365 : finalVal)
                }}
              />
            )
              : (
                <Input
                  size={Input.SIZES.FULL_WIDTH}
                  value={(formRole?.vacationDays !== null) ? Math.round((formRole?.vacationDays * vacationDaysToHoursMultiplier) * 100) / 100 : 0}
                  label={t('EMPLOYEE_PROFILE_HOLIDAY_FUND')}
                  type='number'
                  min={0}
                  max={365 * vacationDaysToHoursMultiplier}
                  step={1}
                  disabled={disabled || isPreventedByPlugin('editEmployeeDataVacationDays')}
                  placeholder={t('EMPLOYEE_PROFILE_HOLIDAY_FUND_PLACEHOLDER_HOURS')}
                  onChange={(val) => {
                    let finalVal = !isNaN(parseFloat(val)) ? parseFloat(val) : null
                    if (finalVal > 365 * vacationDaysToHoursMultiplier) {
                      finalVal = 365 * vacationDaysToHoursMultiplier
                    }
                    if (finalVal) {
                      finalVal = finalVal / vacationDaysToHoursMultiplier
                    }
                    return handleRoleChange('vacationDays', finalVal)
                  }}
                />
              )}
          </Flex>
          <Spacing
            type={Spacing.TYPES.HORIZONTAL}
            size={Spacing.SIZES.SIZE_8}
          />
          <Flex flex='1' align={Flex.POSITION.CENTER} justify={Flex.POSITION.START}>
            <Text
              type={Text.TYPES.BODY_MEDIUM}
              weight={Text.WEIGHTS.BOLD}
              style={{ paddingTop: '1.1rem' }}
              color={Text.COLORS.PRIMARY}
            >
              {t(wsDisplayVacationsAsDays ? 'DAYS_COUNT' : 'HOURS_COUNT')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Flex flex='1' align={Flex.POSITION.END}>
          <div className='ds-input-disabled-but-blue'>
            <Input
              size={Input.SIZES.FULL_WIDTH}
              value={formRole?.terminalPIN
                ? String(formRole.terminalPIN).padStart(4, '0')
                : ''}
              label={t('EMPOYEE_PROFILE_TERMINAL_PIN')}
              disabled
              placeholder={t('EMPLOYEE_PROFILE_TERMINAL_PIN_PLACEHOLDER')}
            />
          </div>
          <Spacing
            type={Spacing.TYPES.HORIZONTAL}
            size={Spacing.SIZES.SIZE_4}
          />
          {(!disabled || isMe) && (
            <Spacing
              type={Spacing.TYPES.VERTICAL}
              size={Spacing.SIZES.SIZE_8}
            >
              <Tooltip
                anchor={
                  <Icon
                    color={Icon.COLORS.PRIMARY}
                    onClick={async () => {
                      await handleGenerateTerminalPIN()
                    }}
                    ico={Icon.ICONS.back}
                  />
                }
                width='18rem'
                className='help-tooltip-content'
              >
                {t('EMPOYEE_PROFILE_TERMINAL_PIN_GENERATE_RANDOM')}
              </Tooltip>
            </Spacing>
          )}
          {(formRole?.customData?.employeeId && !disabled) ? (
            <Spacing
              type={Spacing.TYPES.VERTICAL}
              size={Spacing.SIZES.SIZE_8}
            >
              <Tooltip
                anchor={
                  <Icon
                    color={Icon.COLORS.PRIMARY}
                    onClick={async () => {
                      await handleGenerateTerminalPIN({ useEmployeeId: true })
                    }}
                    ico={Icon.ICONS.hash}
                  />
                }
                width='18rem'
                className='help-tooltip-content'
              >
                {t('EMPOYEE_PROFILE_TERMINAL_PIN_SET_AS_EMPLOYEE_ID')}
              </Tooltip>
            </Spacing>
          ) : null}
        </Flex>

        <Spacing
          type={Spacing.TYPES.HORIZONTAL}
          size={Spacing.SIZES.SIZE_8}
        />

        <Flex flex='1'>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            value={formRole?.calendarOrder || ''}
            label={t('EMPOYEE_PROFILE_CALENDAR_ORDER')}
            disabled={disabled}
            pattern='[0-9]{5}'
            placeholder={t('EMPLOYEE_PROFILE_CALENDAR_ORDER_PLACEHOLDER')}
            onChange={(val) => handleRoleChange('calendarOrder', !isNaN(parseInt(val)) ? parseInt(val) : null)}
          />
        </Flex>
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Flex>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            value={formRole?.customData?.kmToWork || ''}
            label={t('EMPOYEE_PROFILE_KM_TO_WORK')}
            disabled={disabled}
            pattern='[0-9]{5}'
            placeholder={t('EMPLOYEE_PROFILE_KM_TO_WORK_PLACEHOLDER')}
            onChange={(val) => handleRoleChange('customData', { ...formRole?.customData, kmToWork: parseFloat(val) })}
          />
        </Flex>
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Flex flex='1' direction={Flex.DIRECTION.COLUMN}>
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            style={{ margin: '0.25rem 0' }}
          >
            {t('EMPLOYEE_PROFILE_STATUS')}
          </Text>
          <Flex
            direction={Flex.DIRECTION.ROW}
            align={Flex.POSITION.CENTER}
            flex='1'
          >
            <Text
              color={activity.warningType}
              type={Text.TYPES.BODY_MEDIUM}
              weight={Text.WEIGHTS.BOLD}
            >
              {activity.text}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
})
