import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation UserUpdateMutation (
    $id: ID!
    $data: UserUpdateInputData!
  ) {
    userUpdate (
      id: $id
      data: $data
    ) {
      id
    }
}`

async function requestUserUpdate (variables, token) {
  const varsFixed = { ...variables }
  Object.keys(varsFixed?.data || {}).forEach(k => {
    // none of the props in UserUpdateInputData 'data' allow null value, so we don't send those
    if (varsFixed.data[k] === null) delete varsFixed.data[k]
  })

  const response = await fetchRequest({
    variables: varsFixed,
    query,
    token
  })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('user-update', errors)
  }
  return data.userUpdate
}

export default requestUserUpdate
