import React, { useEffect } from 'react'
import moment from 'moment'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'

import { Loader } from '@ui'
import { AppContainer } from '@core'
import {
  miscUtil,
  useAccessRole
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import { NotFound } from './NotFound'
import { useRoutingConfig } from '@app/routing'
import { setTitleAndMetaDescription } from './helper'
import connect from './connect'

const Login = React.lazy(() => import('../component/pages/other/login'))

const Routes = ({
  auth,
  isLoading,
  isLoadingOrganization,
  setModal,
  workspaceId,
  workspace,
  isPluginEnabled,
  subscription,
  isOrgHidden,
  me,
  calendarDate,
  calendarView,
  isOrgOwner,
  routeChange,
  getOrganization,
  organization
}) => {
  const routes = useRoutingConfig({
    auth,
    isPluginEnabled,
    setModal,
    workspaceId,
    workspace,
    me,
    subscription,
    isOrgHidden,
    isOrgOwner
  })

  const location = useLocation()
  const isOrg = miscUtil.isDayswapsProInterface()
  const isNotPaidAndNotTrial = !subscription?.paid && !subscription?.trial
  const currentRoutes = routes[isOrg ? 'pro' : 'app']
  const allAllowedRoutes = routes.additional.concat(currentRoutes).filter((item) => item.allowed)
  const scheduleDate = calendarDate && moment(calendarDate).isValid() && moment(calendarDate).format('YYYY-MM-DD')
  const itemsInUrl = location.pathname.split('/').filter((i) => !!i).length
  const isOnSubpage = isOrg ? (itemsInUrl > 2) : (itemsInUrl > 1)
  const { canRead } = useAccessRole()

  const decideRedirect = () => {
    // no auth -> /login
    if (!auth) return '/login'

    // user has no password yet -> /registration/complete
    if (!me.hasPassword) {
      return '/registration/complete'
    }

    // manager/admin on unpaid WS -> /paywall
    if (isNotPaidAndNotTrial && canRead(PERMISSIONS.SETTINGS)) {
      if (isOrg) return '/organization/paywall'
      return '/paywall'
    }

    // basic app paths being tried by priority
    const currentlyAllowedAppRoutes = routes.app.filter((item) => item.allowed)
    const appPathsByPriority = ['/schedule', '/workspace', '/dashboard-emp', '/my-timetable', '/backoffice']
    for (const p of appPathsByPriority) {
      if (currentlyAllowedAppRoutes.some(all => all.path === p)) {
        if (p === '/schedule' && calendarView && scheduleDate) {
          return '/schedule/' + calendarView + '/' + scheduleDate
        }
        return p
      }
    }

    // DS PRO paths, if some are allowed
    const currentlyAllowedProRoutes = routes.pro.filter((item) => item.allowed)
    if (currentlyAllowedProRoutes.length) {
      return '/organization' + currentlyAllowedProRoutes[0].path
    }

    // fallback -> /no-workspace
    return '/no-workspace'
  }
  useEffect(() => {
    setTitleAndMetaDescription(location.pathname)
    routeChange()

    // when we switch to DS PRO, we must call getOrganization()
    if (location.pathname.includes('/organization') && !isLoadingOrganization && !organization?.id) {
      getOrganization()
    }
  }, [location.pathname])

  if (isLoading) return <Loader size={Loader.SIZES.LARGE} />
  return (
    <React.Suspense fallback={<Loader size={Loader.SIZES.LARGE} />}>
      <Switch>
        {!auth && <Route path='/login' component={Login} />}
        {allAllowedRoutes.map((route) => {
          const allowedSubPages = route.subpages && route.subpages.filter((item) => item.allowed)
          const firstPath = allowedSubPages && route.path + allowedSubPages[0]?.path
          const LazyComp = route.componentPath && React.lazy(() => import(`../component/pages/${route.componentPath}`))
          return (
            <Route
              key={route.path}
              path={isOrg ? '/organization' + route.path : route.path}
              exact={!!route.exact}
            >
              {!isOnSubpage && !!route.subpages && <Redirect to={isOrg ? '/organization' + firstPath : firstPath} />}
              {route.componentPath && (
                <AppContainer {...route.containerConfig}>
                  <LazyComp location={location} {...route} />
                </AppContainer>
              )}
              {route.component && (
                <AppContainer {...route.containerConfig}>
                  <route.component location={location} {...route} />
                </AppContainer>
              )}
              {route.subpages && (
                <AppContainer {...route.containerConfig}>
                  <Switch>
                    {route.subpages && route.subpages.map((sub) => {
                      const LazySubComp = React.lazy(() => import(`../component/pages/${sub.componentPath}`))
                      return (
                        <Route
                          location={location}
                          key={sub.path}
                          path={isOrg ? '/organization' + route.path + sub.path : route.path + sub.path}
                          exact={!!sub.exact}
                          component={LazySubComp}
                        />
                      )
                    })}
                  </Switch>
                </AppContainer>
              )}
            </Route>
          )
        })}
        <Route exact path={['/', '/login', '/paywall', '/organization']}>
          <Redirect to={decideRedirect()} />
        </Route>
        <Route>
          {!auth ? <Redirect to='/login' /> : (
            <AppContainer>
              <NotFound isOrg={isOrg} />
            </AppContainer>
          )}
        </Route>
      </Switch>
    </React.Suspense>
  )
}

export default connect(Routes)
