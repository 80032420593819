import i18next from 'i18next'
import moment from 'moment'
import {
  isLanguageSupported,
  Streami18n
} from 'stream-chat-react'

import { momentUtil } from '@app/util'
import { translationsMapper } from './translationsMapper'
import { formatHours } from '../format-hours'

export const streamI18n = new Streami18n({
  DateTimeParser: moment
})

function init (lng, locales) {
  // hack to let translators use linebreaks
  // by {{br}}
  const interpolation = {
    format: function (value, format) {
      if (format === 'uppercase') return value.toUpperCase()
      if (format === 'lowercase') return value.toLowerCase()
      if (format === 'mins_as_hours') return Math.round(value / 60 * 100) / 100
      if (format === 'mins_as_hours_formatted') return formatHours(value / 60)
      if (format === 'date_formatted') return moment(value).format('D.M.YYYY')
      return value
    },
    defaultVariables: {
      br: '\n'
    }
  }

  // i18next expects one more level of nesting inside "translation"
  const resources = {}
  Object.keys(locales).forEach((key) => {
    resources[key] = {
      translation: locales[key]
    }
  })

  const i18nConfig = {
    lng,
    resources,
    interpolation,
    pluralSeparator: ':',
    nsSeparator: '-'
  }

  return i18next.init(i18nConfig)
}

export function getLanguage () {
  return i18next.language
}

export const t = i18next.t

export const decideLanguageCode = (language) => {
  const includes = (source) => source.includes(language)
  if (includes(['cs-CZ', 'cs'])) return 'cs'
  if (includes(['sk-SK', 'sk'])) return 'sk'
  if (includes(['uk'])) return 'uk'
  if (includes(['hr'])) return 'hr'
  if (includes(['bg'])) return 'bg'
  if (includes(['hu'])) return 'hu'
  if (includes(['ro'])) return 'ro'
  if (includes(['es'])) return 'es'
  if (includes(['de'])) return 'de'
  if (includes(['it'])) return 'it'
  if (includes(['pl'])) return 'pl'
  if (includes(['pt'])) return 'pt'
  if (includes(['fr'])) return 'fr'
  if (includes(['ru'])) return 'ru'
  return 'en'
}

export default async (lng) => {
  let lngCode = lng
  if (!lng) {
    const language = window.navigator.language
    lngCode = decideLanguageCode(language)
  }
  const newLocales = await window.fetch('https://delivery.localazy.com/_a8145436973175020559b6b98b66/_e0.v2.json')
    .then((res) => res.json())
    .then(async (res) => {
      const fileId = '114edab6a7875fb1a1a76a8bf14a487a437a6c7f'
      const desiredLang = res.files[fileId].locales.find((loc) => loc.language === lngCode)
      if (!desiredLang || !desiredLang.uri) return res
      return window.fetch('https://delivery.localazy.com' + desiredLang.uri)
        .then((res) => res.json())
    })
  await init(lngCode, newLocales)
  momentUtil.setLocale(lngCode)
  if (i18next.options.lng) {
    // set locale
    await i18next.addResourceBundle(lngCode, 'translation', newLocales)
    await i18next.changeLanguage(lngCode)
  }
  try {
    const translations = i18next.getDataByLanguage(lngCode)?.translation

    if (isLanguageSupported(lngCode)) {
      await streamI18n.setLanguage(lngCode)
    } else if (translations) {
      await streamI18n.registerTranslation(lngCode, translationsMapper(translations))
      await streamI18n.setLanguage(lngCode)
    }
  } catch (error) {
    console.log('streami18n.setLanguage failed', error)
  }
  return lngCode
}
