import React from 'react'
import connect from './connect'
import { apiEndpoints } from '@app/const/config'
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import q from '@app/request/query'
import store from '../store'
import moment from 'moment'
import { t } from 'i18next'
import miscUtil from '@app/util/misc'
import calendarUtil from '@app/util/calendar'
import { flushSync } from 'react-dom'
import {
  notification,
  permissionsUtil
} from '@app/util'
import { PERMISSIONS } from '@app/const'

class Websockets extends React.Component {
  constructor (props) {
    super(props)
    this.processBatchUpdates = this._processBatchUpdates.bind(this)
    this.processReloads = this._processReloads.bind(this)
    this.processDisconnects = this._processDisconnects.bind(this)
    this.initSubscriptions = this._initSubscriptions.bind(this)
    this.logDisconnect = this._logDisconnect.bind(this)
    this.logReconnect = this._logReconnect.bind(this)
    this.scheduleBatchReload = this._scheduleBatchReload.bind(this)
    this.getSecondsFromLastWarningUpdate = this._getSecondsFromLastWarningUpdate.bind(this)
    this.state = {
      processUpdatesInterval: setInterval(this.processBatchUpdates, 1000),
      processReloadsInterval: setInterval(this.processReloads, 8500),
      processDisconnectsInterval: setInterval(this.processDisconnects, 7000),
      batchUpdatesShifts: [],
      batchUpdatesTimeOffs: [],
      batchUpdatesAvailabilities: [],
      batchUpdatesWarnings: [],
      batchUpdatesOffers: [],
      batchUpdatesEmployees: [],
      batchReloads: [],
      warningUpdatesLog: {},
      disconnectsLog: []
    }
    this.setStateCustom = this._setStateCustom.bind(this)
  }

  // our custom setState function that wraps default this.setState() in flushSync().
  // reason: React18+ does automatic state update batching, which potentially causes each state update to overwrite some other
  //         recent state update call. the flushSync disables the automatic state update batching.
  _setStateCustom (stateUpdateFn) {
    flushSync(() => {
      this.setState(stateUpdateFn)
    })
  }

  _logDisconnect () {
    const newDisconnectLog = this.state.disconnectsLog
    newDisconnectLog.push({ reconnected: false, time: moment().clone(), downtime: 0 })
    this.setStateCustom(s => Object.assign({}, s, { disconnectsLog: newDisconnectLog }))
  }

  _logReconnect () {
    const oldestUnreconnectedIdx = this.state.disconnectsLog.findIndex(d => !d.reconnected)
    if (oldestUnreconnectedIdx !== -1) {
      const newDisconnectLog = this.state.disconnectsLog
      newDisconnectLog[oldestUnreconnectedIdx].reconnected = true
      newDisconnectLog[oldestUnreconnectedIdx].downtime = moment().clone().diff(newDisconnectLog[oldestUnreconnectedIdx].time)
      this.setStateCustom(s => Object.assign({}, s, { disconnectsLog: newDisconnectLog }))
    }
  }

  _processDisconnects () {
    const maxDisconnects = 13
    const maxDisconnectDuration = 20000

    // if we can't edit the calendar, don't watch the connection stability. it doesn't matter.
    if (!permissionsUtil.canWrite(PERMISSIONS.CALENDAR)) return

    // forget disconnections older than 2 minutes
    this.setStateCustom(s => Object.assign({}, s, { disconnectsLog: this.state.disconnectsLog.filter(d => moment(d.time).isAfter(moment().clone().add(-2, 'minutes'))) }))

    // count disconnects & long disconnects last minute
    const disconnectsLastMinute = this.state.disconnectsLog.filter(d => moment(d.time).isAfter(moment().clone().add(-1, 'minute')))
    const longDisconnectsLastMinute = disconnectsLastMinute.filter(d => d.downtime > maxDisconnectDuration || (!d.reconnected && moment(d.time).isBefore(moment().clone().add(-1 * maxDisconnectDuration, 'milliseconds'))))
    // if there were > maxDisconnects disconnects last minute, or one over maxDisconnectDuration milliseconds, display the notice
    if (disconnectsLastMinute.length > maxDisconnects || longDisconnectsLastMinute.length > 1) {
      notification.warn({
        message: t('RELOAD_NOTICE_UNSTABLE_CONNECTION_1'), // + t('RELOAD_NOTICE_UNSTABLE_CONNECTION_2') + t('RELOAD_NOTICE_UNSTABLE_CONNECTION_3'),
        config: {
          duration: -1,
          id: 'connection'
        }
      })
      // and print it to the console
      const errorMessage = (this.props.me ? (this.props.me.id + ' ') : '') + 'Unstable connection! ' + disconnectsLastMinute.length.toString() + ' disconnects, ' + longDisconnectsLastMinute.length.toString() + ' long ones.'
      console.log(errorMessage)
    } else {
      notification.remove('connection')
    }
  }

  _getSecondsFromLastWarningUpdate (usrId) {
    if (this.state.warningUpdatesLog && this.state.warningUpdatesLog[usrId]) {
      return Math.floor(Date.now() / 1000) - this.state.warningUpdatesLog[usrId]
    } else {
      return 9999999
    }
  }

  // returns true if newly obtained event s2 is identical to older event s1 from store
  _isEventIdentical (s2, s1) {
    let same = true
    for (var prop in s2) {
      // treat 'period' prop differently (use moment.js to compare period.start and period.end)
      if (prop === 'period' && s1.period.start && s1.period.end && s2.period.start && s2.period.end) {
        if (!moment(s1.period.start).isSame(s2.period.start) || !moment(s1.period.end).isSame(s2.period.end)) {
          same = false
          break
        }
      } else if (['pauses', 'overTime'].includes(prop)) {
        // use misc.safeStringify to compare 'pauses' and 'overTime'
        if (miscUtil.safeStringify(s1.pauses) !== miscUtil.safeStringify(s2.pauses)) {
          same = false
          break
        }
        // treat 'warnings' prop differently (check if all the warnings from s2 are also in s1 and vice versa)
      } else if (prop === 'warnings') {
        if (s2.warnings && s2.warnings.length === 0 && !s1.warnings) {
          break
        }
        if ((s2.warnings && !s1.warnings) || (!s2.warnings && s1.warnings) || (s2.warnings && s1.warnings && s2.warnings.length !== s1.warnings.length)) {
          same = false
          break
        } else {
          s2.warnings.map((w2) => {
            if (!s1.warnings.find((w1) => w1.name === w2.name)) {
              same = false
            }
          })
          s1.warnings.map((w1) => {
            if (!s2.warnings.find((w2) => w2.name === w1.name)) {
              same = false
            }
          })
          if (!same) break
        }
        // all the other props: just check if they're equal
      } else {
        if (s1[prop] !== s2[prop]) {
          same = false
          break
        }
      }
    }
    return same
  }

  _scheduleBatchReload (reloadType, targets = undefined) {
    const newBatchReloads = [...this.state.batchReloads]
    if (!newBatchReloads.some(br => br.reloadType === reloadType)) {
      // if state.batchReloads doesn't contain this reload type yet, we push it there
      newBatchReloads.push({ reloadType, targets })

      this.setStateCustom(s => Object.assign({}, s, { batchReloads: newBatchReloads }))
    } else {
      // if state.batchReloads already contains this reload type ...
      if (targets) {
        // ... and if we have some 'targets' for the reload, we just extend the targets list of the scheduled batchReload that's already in state.batchReloads
        const updIdx = newBatchReloads.findIndex(br => br.reloadType === reloadType)
        if (!newBatchReloads[updIdx].targets) newBatchReloads[updIdx].targets = []
        targets.forEach(t => {
          if (!newBatchReloads[updIdx].targets.includes(t)) newBatchReloads[updIdx].targets.push(t)
        })
        this.setStateCustom(s => Object.assign({}, s, { batchReloads: newBatchReloads }))
      }
    }
  }

  _processReloads () {
    const { loadExternalEmployees, loadChangedShiftsCount, loadRoleStatsMulti } = this.props
    const { calendar } = store.getState()

    // process batch reloads
    if (this.state.batchReloads && this.state.batchReloads.length) {
      this.state.batchReloads.forEach(br => {
        // reload 'external-employees'
        if (br.reloadType === 'external-employees') {
          loadExternalEmployees({ loadForPeriod: calendarUtil.getReloadPeriod(store.getState().calendar) })
        }

        // reload 'changed-shifts-count'
        if (br.reloadType === 'changed-shifts-count') {
          loadChangedShiftsCount()
        }

        // reload 'role-stats'
        if (br.reloadType === 'role-stats') {
          if (calendar?.date) loadRoleStatsMulti(calendar.date, br.targets)
        }

        // remove this type from state.batchReloads
        this.setStateCustom(s => Object.assign({}, s, { batchReloads: this.state.batchReloads.filter(obr => obr.reloadType !== br.reloadType) }))
      })
    }
  }

  _processBatchUpdates () {
    const { setShifts, setTimeOffs, setAvailabilities, updateWarnings, setOffers, setEmployees, isPluginEnabled, loadEmployeeDetail } = this.props
    const { calendar, calendarFilters, employees } = store.getState()
    const stateUpdate = {}

    // collect the list of IDs of users who can be influenced by the updates (we'll need to update their RoleStats & Warnings)
    const relevantUserIds = []
    const addRelevantUserId = (upd) => {
      const potentialUserIds = [
        upd.obj?.userId,
        upd.obj?.revision?.diff?.userId?.__old,
        upd.obj?.revision?.diff?.userId?.__new
      ].filter(Boolean)
      potentialUserIds.forEach(puid => {
        if (!relevantUserIds.includes(puid) && employees[puid] && !employees[puid].external) relevantUserIds.push(puid)
      })
    }

    // batch-update shifts
    if (this.state.batchUpdatesShifts?.length) {
      const { shifts } = store.getState()

      // update types: deleted / updated / created
      const deleted = this.state.batchUpdatesShifts.filter(upd => upd.type === 'deleted') || []
      const created = this.state.batchUpdatesShifts.filter(upd => upd.type === 'created') || []
      const updated = this.state.batchUpdatesShifts.filter(upd => upd.type === 'updated') || []

      // add influenced users to 'relevantUserIds'
      this.state.batchUpdatesShifts.forEach(upd => {
        addRelevantUserId(upd)
      })

      // only update if updated shift is different than what we already have in store.
      // we want to avoid updating the store too much, because it would trigger unnecessary calendar render()
      let skipUpdate = false
      if (updated.length === 1 && !deleted.length && !created.length) {
        const inStoreShift = shifts.find(sf => sf.id === updated[0].obj.id)
        if (inStoreShift && this._isEventIdentical(updated[0].obj, inStoreShift)) {
          skipUpdate = true
        }
      }

      // run the update
      if (!skipUpdate) {
        setShifts(
          // start with current store.shifts
          shifts
            // filter out shifts that are supposed to be deleted
            .filter(sf => !deleted.find(upd => upd.obj.id === sf.id))
            // add shifts that are supposed to be created
            .concat(created.map(upd => upd.obj))
            // modify shifts that are supposed to be updated
            .map(sf => {
              const updatedSf = updated.find(upd => upd.obj.id === sf.id)
              if (updatedSf) {
                return Object.assign({}, sf, updatedSf.obj)
              } else {
                return sf
              }
            })
        )
      }

      // reload the number of changed shifts on WS
      this.scheduleBatchReload('changed-shifts-count')

      // reset the state.batchUpdatesShifts
      stateUpdate.batchUpdatesShifts = []
    }

    // batch-update timeOffs
    if (this.state.batchUpdatesTimeOffs && this.state.batchUpdatesTimeOffs.length) {
      const { timeOffs } = store.getState()

      // update types: deleted / updated / created
      const deleted = this.state.batchUpdatesTimeOffs.filter(upd => upd.type === 'deleted') || []
      const created = this.state.batchUpdatesTimeOffs.filter(upd => upd.type === 'created') || []
      const updated = this.state.batchUpdatesTimeOffs.filter(upd => upd.type === 'updated') || []

      // add influenced users to 'relevantUserIds'
      this.state.batchUpdatesTimeOffs.forEach(upd => {
        addRelevantUserId(upd)
      })

      // only update if updated event is different than what we already have in store.
      // we want to avoid updating the store too much, because it would trigger unnecessary calendar render()
      let skipUpdate = false
      if (updated.length === 1 && !deleted.length && !created.length) {
        const inStoreEvent = timeOffs.find(sf => sf.id === updated[0].obj.id)
        if (inStoreEvent && this._isEventIdentical(updated[0].obj, inStoreEvent)) {
          skipUpdate = true
        }
      }

      // run the update
      if (!skipUpdate) {
        setTimeOffs(
          // start with current store.timeOffs
          timeOffs
            // filter out events that are supposed to be deleted
            .filter(sf => !deleted.find(upd => upd.obj.id === sf.id))
            // add events that are supposed to be created
            .concat(created.map(upd => upd.obj))
            // modify events that are supposed to be updated
            .map(sf => {
              const updatedSf = updated.find(upd => upd.obj.id === sf.id)
              if (updatedSf) {
                return updatedSf.obj
              } else {
                return sf
              }
            })
        )
      }

      // reset the state.batchUpdatesTimeOffs
      stateUpdate.batchUpdatesTimeOffs = []
    }

    // batch-update warnings
    if (this.state.batchUpdatesWarnings?.length) {
      const { shifts, timeOffs } = store.getState()
      setShifts(
        shifts.map(sf => {
          const updatedSf = this.state.batchUpdatesWarnings.find(upd => upd.eventId === sf.id)
          if (updatedSf) {
            return Object.assign({}, sf, { warnings: updatedSf.warnings })
          } else {
            return sf
          }
        })
      )
      setTimeOffs(
        timeOffs
          .map(sf => {
            const updatedSf = this.state.batchUpdatesWarnings.find(upd => upd.eventId === sf.id)
            if (updatedSf) {
              return Object.assign({}, sf, { warnings: updatedSf.warnings })
            } else {
              return sf
            }
          })
      )

      // reset the state.batchUpdatesWarnings
      stateUpdate.batchUpdatesWarnings = []
    }

    // batch-update availabilities
    if (this.state.batchUpdatesAvailabilities && this.state.batchUpdatesAvailabilities.length) {
      const { availabilities } = store.getState()

      // update types: deleted / updated / created
      const deleted = this.state.batchUpdatesAvailabilities.filter(upd => upd.type === 'deleted') || []
      const created = this.state.batchUpdatesAvailabilities.filter(upd => upd.type === 'created') || []
      const updated = this.state.batchUpdatesAvailabilities.filter(upd => upd.type === 'updated') || []

      // only update if updated event is different than what we already have in store.
      // we want to avoid updating the store too much, because it would trigger unnecessary calendar render()
      let skipUpdate = false
      if (updated.length === 1 && !deleted.length && !created.length) {
        const inStoreEvent = availabilities.find(sf => sf.id === updated[0].obj.id)
        if (inStoreEvent && this._isEventIdentical(updated[0].obj, inStoreEvent)) {
          skipUpdate = true
        }
      }

      // run the update
      if (!skipUpdate) {
        setAvailabilities(
          // start with current store.availabilities
          availabilities
            // filter out events that are supposed to be deleted
            .filter(sf => !deleted.find(upd => upd.obj.id === sf.id))
            // add events that are supposed to be created
            .concat(created.map(upd => upd.obj))
            // modify events that are supposed to be updated
            .map(sf => {
              const updatedSf = updated.find(upd => upd.obj.id === sf.id)
              if (updatedSf) {
                return updatedSf.obj
              } else {
                return sf
              }
            })
        )
      }

      // reset the state.batchUpdatesAvailabilities
      stateUpdate.batchUpdatesAvailabilities = []
    }

    // batch-update offers
    if (this.state.batchUpdatesOffers && this.state.batchUpdatesOffers.length) {
      const { offers } = store.getState()
      const newOffers = (
        // start with the original offers from the store
        offers
          // filter out the offers that were just resolved,
          .filter(con => !this.state.batchUpdatesOffers.find(obj => obj.status === 'resolved' && obj.id === con.id))
          // update the remaining with the data from the WSS updates,
          .map(con => {
            const obj = this.state.batchUpdatesOffers.find(obj => obj.id === con.id)
            if (obj) {
              return Object.assign({}, con, obj)
            } else {
              return con
            }
          })

      )
      // and add the new ones
      newOffers.push(...this.state.batchUpdatesOffers.filter(obj => !offers.find(con => con.id === obj.id)))
      setOffers(newOffers)

      // reset the state.batchUpdatesOffers
      stateUpdate.batchUpdatesOffers = []
    }

    // batch-update employees
    if (this.state.batchUpdatesEmployees && this.state.batchUpdatesEmployees.length) {
      const { employees, employeeDetail, calendar } = store.getState()

      const newEmps = Object.assign({}, employees)
      this.state.batchUpdatesEmployees.forEach(upd => {
        if (upd.type === 'deleted') newEmps[upd.obj.id] = Object.assign({}, newEmps[upd.obj.id], { external: true })
        if (upd.type === 'updated') newEmps[upd.obj.id] = Object.assign({}, newEmps[upd.obj.id], upd.obj)
        if (upd.type === 'created') newEmps[upd.obj.id] = Object.assign({}, upd.obj)

        // if employee's terminateDate was set to some past timestamp, we'll mark the employee as external in store
        if (newEmps[upd.obj.id].terminateDate && moment(newEmps[upd.obj.id].terminateDate).isSameOrBefore(moment())) newEmps[upd.obj.id].external = true

        // if employee was marked as hidden, we take them out of newEmployees array
        if (newEmps[upd.obj.id].hidden) delete newEmps[upd.obj.id]

        // if employee's contracts were changed while calendar.roleStats are loaded for this employee, we need to reload their roleStats
        const isContractsChanged = upd.type === 'updated' && (employees && Object.keys(employees).includes(upd.obj?.id)) && miscUtil.safeStringify(upd.obj.contracts) !== miscUtil.safeStringify(employees[upd.obj.id].contracts)
        if (isContractsChanged && calendar && calendar.date && calendar.roleStats && calendar.roleStats[upd.obj.id]) {
          this.scheduleBatchReload('role-stats', [upd.obj.id])
        }

        // if we have loaded employeeDetail for this employee, reload the employeeDetail
        if (employeeDetail?.id === upd.obj.id) {
          loadEmployeeDetail(upd.obj.id)
        }
      })

      setEmployees(newEmps)

      // reset the state.batchUpdatesEmployees
      stateUpdate.batchUpdatesEmployees = []
    }

    // reload the calendar.roleStats because the planned work hours of some people probably changed
    if (calendar?.loadedPeriods?.length && (this.state.batchUpdatesShifts?.length || this.state.batchUpdatesTimeOffs?.length)) {
      this.scheduleBatchReload('role-stats', (relevantUserIds.length ? relevantUserIds : undefined))
    }

    // tell BE to recompute the warnings and push warning updates
    // (compute the warnings only for relevant users and in relevant period)
    const warningsHidden = calendarFilters?.length && calendarFilters.find(fil => fil.hideWarnings === 'all' || fil.hideWarnings === true)
    if (!warningsHidden && isPluginEnabled('laborlaw')) {
      if (relevantUserIds.length) {
        const updateWarningsForRelevantUsers = () => {
          let startPeriod = moment().startOf('day')
          let endPeriod = moment().add(1, 'day').endOf('day').format()
          if (calendar && calendar.loadedPeriods && calendar.loadedPeriods.length) {
            endPeriod = moment(calendar.loadedPeriods.map(lp => moment(lp.end).format()).sort()[calendar.loadedPeriods.length - 1]).format()
            startPeriod = moment(calendar.loadedPeriods.map(lp => moment(lp.start).format()).sort()[0]).format()
          }

          // call the updates one after another, not on parallel (too many warning compoutation requests at once are terrible for the BE)
          for (var i = 0; i < relevantUserIds.length; i++) {
            const usrId = relevantUserIds[i]

            if (relevantUserIds.length >= 2 && this.getSecondsFromLastWarningUpdate(usrId) < 180) {
              // if this is a batch update (more than 1 shift), avoid calling
              // for warnings update if we recently did that for this user
            } else {
              const upd = {}
              upd[usrId] = Math.floor(Date.now() / 1000)
              stateUpdate.warningUpdatesLog = Object.assign({}, this.state.warningUpdatesLog, upd)

              updateWarnings({
                period: {
                  start: startPeriod,
                  end: endPeriod
                },
                user: usrId
              })
            }
          }
        }
        updateWarningsForRelevantUsers()
      }
    }

    // actually update the state
    if (miscUtil.safeStringify(stateUpdate) !== miscUtil.safeStringify({})) {
      this.setStateCustom(s => Object.assign({}, s, stateUpdate))
    }
  }

  _employeeHasChanged (originalEmpObj, currentEmpObj) {
    const orig = Object.assign({}, originalEmpObj, { lastSeen: undefined, lastMobileAppUse: undefined })
    const curr = Object.assign({}, currentEmpObj, { lastSeen: undefined, lastMobileAppUse: undefined })
    return (miscUtil.safeStringify(Object.assign({}, orig, curr)) !== miscUtil.safeStringify(orig))
  }

  async _addEmployeeUpdateToBatch (updateObj) {
    const newBatchUpdateEmps = this.state.batchUpdatesEmployees.some(bu => bu.obj.id === updateObj.obj.id && bu.type === updateObj.type)
      ? this.state.batchUpdatesEmployees.map(bu => {
        if (bu.obj.id === updateObj.obj.id && bu.type === updateObj.type) {
          const ret = Object.assign({}, bu)
          for (const key in updateObj.obj) {
            ret.obj[key] = updateObj.obj[key]
          }
          return ret
        } else {
          return bu
        }
      })
      : this.state.batchUpdatesEmployees.concat([updateObj])

    this.setStateCustom((s) => Object.assign({}, s, {
      batchUpdatesEmployees: newBatchUpdateEmps
    }))
  }

  _initSubscriptions () {
    const {
      auth, workspaceId, workspaces, me
    } = this.props
    const { location: { hostname } } = window
    const { [hostname]: { wsBackend } } = apiEndpoints

    // initialize the websocket subscriptions
    if (auth && workspaceId && (workspaces.some(ws => ws.id === workspaceId)) && me && me.id && (!this.wsClient || this.wsClientSubscribedToWsId !== workspaceId)) {
      // unsubscribe all the previous subscriptions
      if (Array.isArray(this.wsSubscriptions)) {
        this.wsSubscriptions.forEach(subscription => {
          if (subscription) {
            subscription.unsubscribe()
          }
        })
      }
      this.wsSubscriptions = []

      // create a new client & subscriptions
      const subClient = createClient({
        url: wsBackend,
        shouldRetry: () => true,
        retryAttempts: Infinity,
        connectionParams: {
          authorization: 'Bearer ' + auth
        },
        on: {
          closed: () => this.logDisconnect(),
          connected: () => this.logReconnect()
        }
      })
      this.wsClientSubscribedToWsId = workspaceId
      this.wsClient = new ApolloClient({
        link: new GraphQLWsLink(subClient),
        cache: new InMemoryCache({
          addTypename: false
        })
      })

      // ===============================
      // subscribe to shift changes
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.EMP_CALENDAR, PERMISSIONS.EMP_DASHBOARD, PERMISSIONS.BACKOFFICE.ATTENDANCE, PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription (
                $workspaceId: ID!
            ) {
                shifts (workspace: $workspaceId) {
                    type
                    obj {
                        ${q.SHIFT_PARAMS_BASIC}
                        revision {
                            diff
                        }
                    }
                }
            }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.shifts.obj
          // shift was deleted on BE -> delete it from store
          if (obj.deleted) {
            this.setStateCustom((s) => Object.assign({}, s, {
              batchUpdatesShifts: this.state.batchUpdatesShifts.concat({
                type: 'deleted',
                obj: obj
              })
            }))
          } else {
            const { shifts, employees } = store.getState()
            const inStoreShift = shifts.find(sf => sf.id === obj.id)

            // shift was updated on BE -> update it in store
            if (inStoreShift) {
              this.setStateCustom((s) => Object.assign({}, s, {
                batchUpdatesShifts: this.state.batchUpdatesShifts.concat({
                  type: 'updated',
                  obj: obj
                })
              }))
            } else {
            // shift was created on BE -> add it to the store
              if (!this.state.batchUpdatesShifts.some(upd => upd.type === 'created' && upd.obj?.id === obj.id)) { // don't add the creation of the same shift twice
                this.setStateCustom((s) => Object.assign({}, s, {
                  batchUpdatesShifts: this.state.batchUpdatesShifts.concat({
                    type: 'created',
                    obj: obj
                  })
                }))
              }
            }

            // if shift's userId is a user that's not in store.employees, load the external emps after a few seconds
            if (obj.userId && !employees[obj.userId]) {
              this.scheduleBatchReload('external-employees')
            }
          }
        }))
      }

      // ===================================
      // subscribe to timeOff changes
      // ===================================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.EMP_CALENDAR, PERMISSIONS.EMP_DASHBOARD, PERMISSIONS.BACKOFFICE.ATTENDANCE, PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription (
                $workspaceId: ID!
            ) {
                timeOffs (workspace: $workspaceId) {
                    type
                    obj ${q.TIME_OFF}
                }
            }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.timeOffs.obj
          const { calendar, workspaces, workspaceId } = store.getState()
          const ws = workspaces.find(ws => ws.id === workspaceId)

          // event was deleted on BE -> delete it from store
          if (res.data.timeOffs.type === 'deleted') {
            this.setStateCustom((s) => Object.assign({}, s, {
              batchUpdatesTimeOffs: this.state.batchUpdatesTimeOffs.concat({
                type: 'deleted',
                obj: obj
              })
            }))
          } else {
            const { timeOffs } = store.getState()
            const inStoreEvt = timeOffs.find(sf => sf.id === obj.id)

            // event was updated on BE -> update it in store
            if (inStoreEvt) {
              this.setStateCustom((s) => Object.assign({}, s, {
                batchUpdatesTimeOffs: this.state.batchUpdatesTimeOffs.concat({
                  type: 'updated',
                  obj: obj
                })
              }))
            } else {
            // event was created on BE -> add it to the store
              this.setStateCustom((s) => Object.assign({}, s, {
                batchUpdatesTimeOffs: this.state.batchUpdatesTimeOffs.concat({
                  type: 'created',
                  obj: obj
                })
              }))
            }
          }

          // if *timeOff that's able to generate work time* changed (category.consider is true), reload the calendar.roleStats because the vacation hours & work hours changed for some people
          const category = ws?.unavailabilityCategories?.find(cat => cat.id === obj.categoryId)
          if (category?.consider && calendar && calendar.loadedPeriods && calendar.loadedPeriods.length) {
            this.scheduleBatchReload('role-stats', [obj.userId])
          }
        }))
      }

      // ===================================
      // subscribe to availability changes
      // ===================================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.EMP_CALENDAR, PERMISSIONS.EMP_DASHBOARD, PERMISSIONS.BACKOFFICE.ATTENDANCE, PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription (
                $workspaceId: ID!
            ) {
                availabilities (workspace: $workspaceId) {
                    type
                    obj ${q.AVAILABILITY}
                }
            }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.availabilities.obj

          // event was deleted on BE -> delete it from store
          if (res.data.availabilities.type === 'deleted') {
            this.setStateCustom((s) => Object.assign({}, s, {
              batchUpdatesAvailabilities: this.state.batchUpdatesAvailabilities.concat({
                type: 'deleted',
                obj: obj
              })
            }))
          } else {
            const { availabilities } = store.getState()
            const inStoreEvt = availabilities.find(sf => sf.id === obj.id)

            // event was updated on BE -> update it in store
            if (inStoreEvt) {
              this.setStateCustom((s) => Object.assign({}, s, {
                batchUpdatesAvailabilities: this.state.batchUpdatesAvailabilities.concat({
                  type: 'updated',
                  obj: obj
                })
              }))
            } else {
            // event was created on BE -> add it to the store
              this.setStateCustom((s) => Object.assign({}, s, {
                batchUpdatesAvailabilities: this.state.batchUpdatesAvailabilities.concat({
                  type: 'created',
                  obj: obj
                })
              }))
            }
          }
        }))
      }

      // ===============================
      // subscribe to offer changes
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.EMP_CALENDAR, PERMISSIONS.EMP_DASHBOARD, PERMISSIONS.BACKOFFICE.ATTENDANCE, PERMISSIONS.BACKOFFICE.TIMEOFFREQUESTS])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription (
                $workspaceId: ID!
            ) {
                offers (workspace: $workspaceId) {
                    type
                    obj ${q.OFFER}
                }
            }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.offers.obj
          const alreadyInBatch = this.state.batchUpdatesOffers.findIndex(upd => upd.id === obj.id)
          if (alreadyInBatch === -1) { // don't store 2 updates for the same offer in batchUpdatesOffers
            this.setStateCustom((s) => Object.assign({}, s, {
              batchUpdatesOffers: this.state.batchUpdatesOffers.concat(obj)
            }))
          } else {
            this.setStateCustom((s) => Object.assign({}, s, {
              batchUpdatesOffers: this.state.batchUpdatesOffers.map(upd => {
                if (upd.id === obj.id) {
                  return Object.assign({}, upd, obj)
                } else {
                  return upd
                }
              })
            }))
          }
        }))
      }

      // ===============================
      // subscribe to notifications
      // ===============================
      this.wsSubscriptions.push(this.wsClient.subscribe({
        query: gql`
            subscription (
                $user: ID!
            ) {
                notifications (user: $user) {
                    type
                    obj ${q.NOTIFICATION}
                    plus
                }
            }`,
        variables: { user: me.id }
      }).subscribe((res) => {
        const newNotification = res.data.notifications.obj
        const notificationPlus = res.data.notifications?.plus
        const showNotification = notificationPlus?.find(plus => plus[0] === 'showToast' && plus[1]) ?? null
        const { notifications } = store.getState()
        if (notifications.find(n => n.id === newNotification.id)) {
          this.props.setNotifications(notifications.map(n => {
            if (n.id === newNotification.id) {
              return Object.assign({}, n, newNotification)
            } else {
              return n
            }
          }))
        } else {
          const newNotif = notifications
          newNotif.unshift(newNotification)
          this.props.setNotifications(newNotif)
          if (showNotification) notification.classic(newNotification)
        }
      }))

      // ===============================
      // subscribe to (event) warning changes
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.BACKOFFICE.ATTENDANCE])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
              subscription (
                  $workspaceId: ID!
              ) {
                  eventWarnings (workspace: $workspaceId) {
                      type
                      obj {
                        eventId
                        warnings ${q.EVENT_WARNING}
                      }
                      time
                  }
              }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.eventWarnings.obj
          this.setStateCustom((s) => Object.assign({}, s, {
            batchUpdatesWarnings: this.state.batchUpdatesWarnings.concat(obj)
          }))
        }))
      }

      // ===============================
      // subscribe to employee warning changes
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.WORKSPACE.EMPLOYEES])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
              subscription (
                  $workspaceId: ID!
              ) {
                  employeeWarnings (workspace: $workspaceId) {
                      userId
                      warnings ${q.EMPLOYEE_WARNING}
                  }
              }`,
          variables: { workspaceId }
        }).subscribe((res) => {
          const obj = res.data.employeeWarnings
          const { employees, calendarFilters } = store.getState()
          const hiddenWarnings = calendarFilters.find(fil => fil.hideWarnings) || { hideWarnings: [] }
          const inStoreEmp = employees[obj.userId]
          if (inStoreEmp && !inStoreEmp.employeeWarnings) inStoreEmp.employeeWarnings = []
          if (inStoreEmp && miscUtil.safeStringify(inStoreEmp.employeeWarnings) !== miscUtil.safeStringify(obj.warnings)) {
            const newEmps = Object.assign({}, employees)
            const allUnhiddenWarnings = obj.warnings.filter(ew => !hiddenWarnings || !hiddenWarnings.hideWarnings || !hiddenWarnings.hideWarnings.includes(ew.type))
            if (newEmps[inStoreEmp.id]) newEmps[inStoreEmp.id] = Object.assign({}, newEmps[inStoreEmp.id], { employeeWarnings: allUnhiddenWarnings })
            this.props.setEmployees(newEmps)
          }
        }))
      }

      // ===============================
      // subscribe to plans changes
      // ===============================
      if (permissionsUtil.canRead(PERMISSIONS.CALENDAR)) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
              subscription (
                  $workspaceId: ID!
              ) {
                  plans (workspace: $workspaceId) {
                      type
                      obj ${q.PLAN}
                  }
              }`,
          variables: { workspaceId }
        }).subscribe(async (res) => {
          const obj = res.data.plans.obj
          if (obj?.status) {
            const { workspaces } = store.getState()
            // update the currentPlan prop in currently selected WS
            await this.props.setWorkspaces(
              workspaces.map(ws => {
                if (ws.id === workspaceId) {
                  const newWs = Object.assign({}, ws)
                  newWs.currentPlan = obj
                  return newWs
                } else {
                  return ws
                }
              })
            )
            // multiselect just-planned shifts when planning finishes
            if (obj.status === 'done' && obj.selectedShifts?.length) {
              let { shifts } = store.getState()
              if (obj.selectedShifts.some(ss => !shifts.some(s => s.id === ss.id)) && obj.days?.length) {
                // if the shifts aren't loaded (for example when we're not currently on calendar page) load them before multiselecting them
                await this.props.setCalendar({ date: moment(obj.days[0].day, 'YYYY-MM-DD').startOf('day') })
              }
              shifts = store.getState().shifts
              await this.props.setCalendarMultiSelect({
                action: null,
                sourceEvents: shifts.filter(s => obj.selectedShifts.includes(s.id)),
                targets: [],
                isSelectingTargets: false
              })
              // make sure the 'planning' sidebar is open on the manager calendar page, when the planning just finished (sometimes it was closed, probably due to some race condition)
              await setTimeout(() => {
                if (window.location.pathname.includes('/schedule')) {
                  const { sidebar } = store.getState()
                  if (sidebar?.type !== 'planning') {
                    this.props.setSidebar('planning')
                  }
                }
              }, 250)
            }
          }
        }))
      }

      // ===============================
      // subscribe to web version updates
      // ===============================
      this.wsSubscriptions.push(this.wsClient.subscribe({
        query: gql`
            subscription {
                frontendUpdate (type: "web") {
                    type
                    version
                    commit
                    time
                }
            }`,
        variables: {}
      }).subscribe((res) => {
        const obj = res.data.frontendUpdate
        console.log('New FE version was released:', obj)
        notification.release()
      }))

      // ===============================
      // subscribe to roles updates
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.WORKSPACE, PERMISSIONS.BACKOFFICE])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription {
                roles (workspace: "${workspaceId}") {
                  type
                  obj {
                    hidden
                    terminateDate
                    group
                    customData {
                      firstName
                      lastName
                      telephone
                      employeeId
                      kmToWork
                    }
                    cycleId
                    cycleGroup
                    contract:contractNew ${q.CONTRACT}
                    contracts {
                      id
                      type
                      period {
                        start
                        end
                      }
                      options
                      contractId
                      positions {
                        ${q.POSITION_ASSIGNMENT}
                      }
                    }
                    employeeId
                    calendarOrder
                    terminalPIN

                    user {
                      id
                      email
                      firstName
                      lastName
                      telephone
                      name
                      dummy
                      lastActivity
                      lastMobileAppUse
                      mfa {
                        enabled
                      }
                    }
                  }
                }
            }`,
          variables: {}
        }).subscribe((res) => {
          const { workspaces, employees } = store.getState()
          const updateType = res.data.roles.type
          const r = res.data.roles.obj
          if (!workspaces || !r?.user?.id) return false

          const originalWS = workspaces.find(w => w.id === workspaceId)
          const enforcedLocality = originalWS ? miscUtil.getEnforcedLocality(originalWS) : false

          if (updateType === 'updated' || updateType === 'created') {
            const newEmpStoreObject = miscUtil.getEmployeeObjectForStore(r.user, r)
            const originalEmpInStore = Object.keys(employees).includes(r.user.id) ? employees[r.user.id] : null

            // if employee was just marked as hidden, we add 'hidden' to newEmpStoreObject, so this employee is
            // deleted from our store in the batch processing function
            if (r.hidden) newEmpStoreObject.hidden = true

            const empUpdateType = originalEmpInStore
              ? 'updated'
              : 'created'

            if (!(empUpdateType === 'created' && enforcedLocality && !originalWS?.localities.some(l => l.id === enforcedLocality && l.assigns.some(ass => ass.userId === r.user.id)))) { // if I have some 'enforcedLocality', skip creation of employees on different localities
              if ((empUpdateType === 'updated' && this._employeeHasChanged(originalEmpInStore, newEmpStoreObject)) || (empUpdateType === 'created')) {
                const roleUpdate = {
                  type: empUpdateType,
                  obj: newEmpStoreObject
                }

                // pass the roleUpdate to this.state.batchUpdatesEmployees so they're applied
                // to store in batch to prevent too many unnecessary rerenders
                this._addEmployeeUpdateToBatch(roleUpdate)
              }
            }
          }
        }))
      }

      // ===============================
      // subscribe to users updates
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.WORKSPACE.EMPLOYEES])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
            subscription {
                users (workspace: "${workspaceId}") {
                  obj {
                    id
                    email
                    firstName
                    lastName
                    telephone
                    name
                    dummy
                  }
                }
            }`,
          variables: {}
        }).subscribe((res) => {
          const { employees } = store.getState()
          const u = res.data.users.obj
          if (!Object.keys(employees).includes(u?.id)) return false

          const originalEmpInStore = Object.assign({}, employees[u.id])
          const updatedEmpInStore = Object.assign({}, originalEmpInStore, u)
          if (this._employeeHasChanged(originalEmpInStore, updatedEmpInStore)) {
            const updateObject = { id: updatedEmpInStore.id }
            for (const key in updatedEmpInStore) {
              if (!Object.keys(originalEmpInStore).includes(key) || originalEmpInStore[key] !== updatedEmpInStore[key]) {
                updateObject[key] = updatedEmpInStore[key]
              }
            }

            this._addEmployeeUpdateToBatch({
              type: 'updated',
              obj: updateObject
            })
          }
        }))
      }

      // ===============================
      // subscribe to changes on workspace, which includes
      // Workspace.localities changes or
      // Workspace.positions cahnges
      // ===============================
      if (permissionsUtil.canReadOneOf([PERMISSIONS.CALENDAR, PERMISSIONS.WORKSPACE, PERMISSIONS.BACKOFFICE, PERMISSIONS.SETTINGS])) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
              subscription {
                  workspace (workspace: "${workspaceId}") {
                      obj {
                          id
                          name
                          ${permissionsUtil.canRead(PERMISSIONS.WORKSPACE.EMPLOYEES) ? `invites ${q.INVITE}` : ''}
                          ${permissionsUtil.canRead(PERMISSIONS.SETTINGS.SETTINGSOFFERS) ? q.TRANSFER_GROUPS : ''}
                          
                          positions (archived: false) {
                              id
                              name
                              color
                          }

                          ${q.WORKSPACE_PARAMS_DETAILS}
                      }
                  }
              }`,
          variables: { workspaceId }
        }).subscribe(async (res) => {
          const { workspaces } = store.getState()
          let { employees } = store.getState()
          const obj = res.data.workspace.obj
          if (!workspaces || !obj.id) return
          const originalWS = workspaces.find(w => w.id === obj.id)
          if (!originalWS) return
          const memberUpdates = []

          // 0) if there is a user creation in the employee update batch, process it here, before we manipulate the
          // localities or positions, because assignment of localities/positions to users only works if
          // the store.employees already contains all the users whose localities/positions are being manipulated.
          // this is required mainly for assignment of position to employee immediately after he's added.
          if (this.state?.batchUpdatesEmployees?.some(bu => bu.type === 'created')) {
            await this.processBatchUpdates()
            employees = store.getState().employees
          }

          // 1) if locality assignments changed
          if (miscUtil.safeStringify(originalWS.localities) !== miscUtil.safeStringify(obj.localities)) {
            // update localities arrays on individual employees: store.employees[...].localities
            Object.values(employees).forEach(ee => {
              const newEmpLocalities = obj.localities.filter((l) => {
                return !!l.assigns.find((a) => a.userId === ee.id)
              }).map(l => l.id)
              if (miscUtil.safeStringify(newEmpLocalities) !== miscUtil.safeStringify(ee.localities)) {
                memberUpdates.push({
                  type: 'updated',
                  obj: { id: ee.id, localities: newEmpLocalities }
                })
              }
            })

            // update localities array on store: store.workspaces[...].localities
            this.props.setWorkspaces(
              workspaces.map(ws => {
                if (ws.id === obj.id) {
                  const newWs = Object.assign({}, ws)
                  newWs.localities = [...obj.localities]
                  return newWs
                } else {
                  return ws
                }
              })
            )
          }

          // 2) if 'calendarLockedUntil', 'calendarApprovedUntil', 'calendarApprovedOn', 'calendarApprovedBy' or their LVL2/LVL3 variants, or 'userAttributes' changed on the WS update the WS in store
          const keys = [
            'calendarLockedUntil',
            'calendarApprovedUntil',
            'calendarApprovedOn',
            'calendarApprovedBy',
            'calendarApprovedUntilLvl2',
            'calendarApprovedOnLvl2',
            'calendarApprovedByLvl2',
            'calendarApprovedUntilLvl3',
            'calendarApprovedOnLvl3',
            'calendarApprovedByLvl3',
            'userAttributes'
          ]
          if (keys.some(key => { return miscUtil.safeStringify(originalWS[key]) !== miscUtil.safeStringify(obj[key]) })) {
            this.props.setWorkspaces(
              workspaces.map(ws => {
                if (ws.id === obj.id) {
                  const newWs = Object.assign({}, ws)
                  keys.filter(key => { return miscUtil.safeStringify(originalWS[key]) !== miscUtil.safeStringify(obj[key]) }).forEach(key => {
                    newWs[key] = obj[key]
                  })
                  return newWs
                } else {
                  return ws
                }
              })
            )
          }

          // pass the memberUpdates to this.state.batchUpdatesEmployees so they're applied
          // to store in batch to prevent too many unnecessary rerenders
          if (memberUpdates.length) {
            memberUpdates.forEach(mu => {
              this._addEmployeeUpdateToBatch(mu)
            })
          }
        }))
      }

      // ===============================
      // subscribe to employees resolving their invites
      // ===============================
      if (permissionsUtil.canRead(PERMISSIONS.WORKSPACE.EMPLOYEES)) {
        this.wsSubscriptions.push(this.wsClient.subscribe({
          query: gql`
              subscription {
                  invites (workspace: "${workspaceId}") {
                      obj ${q.INVITE}
                  }
              }`,
          variables: {}
        }).subscribe((res) => {
          const obj = res.data.invites.obj
          // if someone resolved their invite (accepted), reload the invites (workspace) and employees list
          if (obj.resolved) {
            this.props.loadWorkspaceDetail(workspaceId, true).then(() => {
              this.props.loadEmployees({ excludeExistingEmployee: (obj.mergeWith || undefined) }).then(() => {
                this.props.loadPositions()
              })
            })
          } else {
            // if this invite is not yet in WS.invites array, add it there
            const { workspaces, workspaceId } = store.getState()
            const ws = workspaces.find(ws => ws.id === workspaceId)
            if (Array.isArray(ws?.invites) && !ws.invites.some(inv => inv.id === obj.id)) {
              this.props.setWorkspaces(
                workspaces.map(ws => {
                  if (ws.id === workspaceId) {
                    const newWs = Object.assign({}, ws)
                    newWs.invites.push(obj)
                    return newWs
                  } else {
                    return ws
                  }
                })
              )
              // and set corresponding user's 'invited' prop to this invite's ID
              if (obj.mergeWith) {
                this._addEmployeeUpdateToBatch({
                  type: 'updated',
                  obj: {
                    id: obj.mergeWith,
                    invited: obj.id
                  }
                })
              }
            }
          }
        }))
      }
    }
  }

  componentDidUpdate (nextProps, nextState) {
    // reinitialize the subscriptions if needed
    if (
      (!this.wsClient) || // needed if we're not subscribed yet
      (nextProps.workspaceId && nextProps.workspaceId !== this.props.workspaceId) || // if WS changed
      (nextProps.organizationId && nextProps.organizationId !== this.props.organizationId) || // if organization changed
      (nextProps.me.id !== this.props.me.id) // if user changed
    ) {
      this.initSubscriptions()
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.processUpdatesInterval)
    clearInterval(this.state.processReloadsInterval)
  }

  componentDidMount () {
    if (miscUtil.isDayswapsProInterface()) return // don't subscribe when we're just now opened DS PRO

    // subscribe to WSS updates
    this.initSubscriptions()
  }

  render () {
    return null
  }
}

export default connect(Websockets)
