import React from 'react'
import { t } from 'i18next'
import '../hackStyle.scss'
import {
  Input,
  Position,
  SkillSelect,
  Table,
  Text,
  Flex,
  Spacing
} from '@ui'
import { miscUtil } from '@app/util'
import moment from 'moment'

export const CapacityTable = ({
  handleCapacityChange,
  state,
  positionsOnWs,
  userId,
  capacityEnabled
}) => {
  const tableData = miscUtil.getPositionsOnRole(state, moment())
  const tableConfig = [
    {
      key: 'position',
      tlKey: 'POSITIONS',
      sortable: true,
      rowToHTML: (row) => {
        const pos = { ...row }
        pos.skill = null
        return <Position key={'quick_pos_' + pos.id} {...pos} />
      },
      rowToStr: (row) => {
        const pos = { ...row }
        pos.skill = null
        return <Position key={'quick_pos_' + pos.id} {...pos} />
      }
    },
    {
      key: 'skill',
      tlKey: 'EMPLOYEE_PROFILE_CARD_CONTRACT_SKILL',
      sortable: true,
      rowToHTML: (row) => {
        return (
          <div className='ds-skill-select'>
            <SkillSelect
              value={row?.skill || 'any'}
              onChange={(val) => handleCapacityChange(row.id, val, 'skill')}
              includeAny={false}
            />
          </div>
        )
      },
      rowToStr: (row) => {
        return row?.skill
      }
    },
    (capacityEnabled && {
      key: 'capacities',
      tlKey: 'CAPACITY',
      sortable: true,
      rowToHTML: (row) => {
        if (!row.productionResourceType) {
          return (
            <Text color={Text.COLORS.PRIMARY} weight={Text.WEIGHTS.BOLD}>
              {t('CAPP_NOT_ALLOWED')}
            </Text>
          )
        }
        return (
          <Flex align={Flex.POSITION.CENTER}>
            <Input
              value={row?.productionForecast ? row.productionForecast : row.productionResourceType.defaultHourlyProductivity}
              onChange={(val) => handleCapacityChange(row.id, parseFloat(val), 'productionForecast')}
              placehoder='100'
              type='number'
            />
            <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
            <Text color={Text.COLORS.PRIMARY} weight={Text.WEIGHTS.BOLD}>
              {row?.productionResourceType?.name}
            </Text>
          </Flex>
        )
      },
      rowToStr: (row) => {
        return row.productionForecast ? row.productionForecast : row.productionResourceType.defaultHourlyProductivity
      }
    })
  ].filter((config) => !!config)
  return !!tableData.length && (
    <>
      <Table
        className='ds-emp-card-contract-table'
        type={Table.TYPES.LEAN}
        isNonResponsive
        tableName='employeeProfileContractTable'
        showSelection={false}
        tableConfig={tableConfig}
        rows={tableData}
        showActionBar={false}
        size={Table.SIZE.SLIM}
        pagination={false}
      />
    </>
  )
}

CapacityTable.propTypes = {}
CapacityTable.defaultProps = {}
