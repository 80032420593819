/* eslint-disable react/jsx-fragments,no-extra-boolean-cast */
import React, { useState, Component, Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { ApolloClientFetch } from '@app/util/apollo-client-fetch'
import { FEED_ITEM_CREATE, FEED_ITEM_UPDATE } from '@app/request/query'
import { ALLOWED_FILES } from '@app/const/allowed-file-types'
import { uploadFile } from '@app/util/upload-file'
import { DropFiles } from '@core/manipulations'
import { ErrorField } from '@core/error-field'
import { sortUtil } from '@app/util'

import {
  FormField
} from '@core'
import {
  GLOBAL_SINGLE_PERSON,
  GLOBAL_POSITION,
  GLOBAL_ALL_USERS,
  GLOBAL_DATEPICKER,
  GLOBAL_LOCALITY,
  VALUES,
  ERRORS,
  META
} from '@app/const/globals'
import {
  Checkbox,
  Button,
  Input,
  Icon,
  Tooltip,
  Modal,
  Textarea,
  DaySelect,
  Flex,
  Spacing,
  Dropdown
} from '@ui'

import connect from './connect'
import styles from './index.scss'
import {
  NEWS_FEED_MODAL,
  SUBJECT,
  TEXT,
  REQUIRE_CONFIRMATION,
  PIN_A_MESSAGE,
  ASSIGN,
  CHECKED_ITEMS,
  AUDIENCE_BY
} from './constants'

const cx = classNames.bind(styles)

const CheckBoxInput = ({ label, name, tip }) => {
  const [state, setState] = useState({
    checked: null
  })

  const { checked } = state

  return (
    <div className='ds-news-feed-modal-checkbox'>
      <FormField name={name}>
        <Checkbox
          checked={checked}
          onChange={(val) => setState({ checked: val })}
        >
          {label}
        </Checkbox>
      </FormField>
      <Tooltip
        className='ds-ep-tooltip'
        anchor={<Icon ico='question' className='ds-ep-tooltip-icon' />}
      >
        {tip}
      </Tooltip>
    </div>
  )
}

class NewsFeed extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    AddFeed: PropTypes.func.isRequired,
    UpdateFeed: PropTypes.func.isRequired,
    loadShifts: PropTypes.func.isRequired,
    auth: PropTypes.string.isRequired,
    employees: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    shifts: PropTypes.array.isRequired,
    changeFormField: PropTypes.func.isRequired,
    [ERRORS]: PropTypes.object.isRequired,
    [VALUES]: PropTypes.object
  }

  static defaultTypes = {
    [VALUES]: {}
  }

  constructor (props) {
    super(props)
    const { initialize, subject, text, updateQuery, pinned, readRequire } =
      props

    initialize({
      [SUBJECT]: subject || '',
      [TEXT]: text || '',
      [AUDIENCE_BY]: updateQuery ? AUDIENCE_BY : '',
      [ASSIGN]: updateQuery ? ASSIGN : '',
      [REQUIRE_CONFIRMATION]: !!readRequire,
      [PIN_A_MESSAGE]: !!pinned,
      [CHECKED_ITEMS]: []
    })

    this.state = {
      files: [],
      submitting: false
    }
  }

  componentDidMount () {
    const { loadShifts } = this.props
    // TODO: For now we are loading shifts for a year by default,
    //  however we should by default load shifts for a month and update value in
    //  the store dynamically when user changes month on datepicker component
    loadShifts({
      period: {
        start: moment().startOf('year'),
        end: moment().add(12, 'month').endOf('day')
      },
      warningsHidden: true
    })
  }

  componentWillUnmount () {
    this.setState({ files: [], submitting: false })
  }

  onSubmit = (formValues) => {
    const {
      auth,
      workspaceId,
      setModal,
      AddFeed,
      UpdateFeed,
      updateQuery,
      me: { id },
      ReceiversMapper,
      employees,
      positions,
      workspaces,
      shifts,
      itemUpdateId,
      attachments
    } = this.props
    const {
      [REQUIRE_CONFIRMATION]: readRequire,
      [CHECKED_ITEMS]: checkedItems,
      [AUDIENCE_BY]: audienceBy
    } = formValues
    const { files } = this.state

    let trgs = (audienceBy === GLOBAL_POSITION)
      ? Object.values(employees).filter(ee => !ee.external && ee?.positions?.some(p => checkedItems.some(ci => ci.id === p.id))).map(ee => { return { userId: ee.id } })
      : [
        ...ReceiversMapper({
          audienceBy,
          values: checkedItems,
          employees,
          positions,
          workspaces,
          shifts
        }).filter((item) => !item.external && item.userId !== id)
      ]

    if (audienceBy === GLOBAL_DATEPICKER && checkedItems) {
      trgs = checkedItems
    }

    const CREATE_FEED = {
      mutation: gql`
        ${FEED_ITEM_CREATE}
      `,
      variables: {
        ...formValues,
        workSpaceId: workspaceId,
        targets: trgs.map((item) => {
          const { userId } = item
          return {
            userId,
            readRequire
          }
        })
      }
    }

    const UPDATE_FEED = {
      mutation: gql`
        ${FEED_ITEM_UPDATE}
      `,
      variables: {
        ...formValues,
        workSpaceId: workspaceId,
        id: itemUpdateId
      }
    }

    if (!updateQuery) {
      if (!!formValues.checkedItems.length || (formValues.audienceBy === GLOBAL_DATEPICKER || formValues.audienceBy === GLOBAL_ALL_USERS)) {
        if (files.length) {
          this.setState({ ...this.state, submitting: true })
          uploadFile(files, workspaceId, auth).then((res) => {
            ApolloClientFetch(auth)
              .mutate({
                ...CREATE_FEED,
                variables: { ...CREATE_FEED.variables, attachments: res.map((item) => item.id) }
              })
              .then((res) => {
                const {
                  data: { feedItemCreate }
                } = res
                AddFeed(feedItemCreate)
                setModal(null)
              })
          })
        } else {
          ApolloClientFetch(auth)
            .mutate(CREATE_FEED)
            .then((res) => {
              const {
                data: { feedItemCreate }
              } = res
              AddFeed(feedItemCreate)
              setModal(null)
            })
        }
      }
    } else {
      if (files.length) {
        const AttachedFiles =
          attachments &&
          attachments.length &&
          attachments.map((item) => item.file.id)
        this.setState({ ...this.state, submitting: true })
        uploadFile(files, workspaceId, auth).then((res) => {
          ApolloClientFetch(auth)
            .mutate({
              ...UPDATE_FEED,
              variables: {
                ...UPDATE_FEED.variables,
                attachments: [...res.map((item) => item.id), ...(AttachedFiles || [])]
              }
            })
            .then((res) => {
              const {
                data: { feedItemUpdate }
              } = res
              UpdateFeed(feedItemUpdate)
              setModal(null)
            })
        })
      } else {
        ApolloClientFetch(auth)
          .mutate(UPDATE_FEED)
          .then((res) => {
            const {
              data: { feedItemUpdate }
            } = res
            UpdateFeed(feedItemUpdate)
            setModal(null)
          })
      }
    }
  }

  render () {
    const {
      handleSubmit,
      changeFormField,
      [VALUES]: values,
      [ERRORS]: errors,
      [META]: meta,
      updateQuery,
      ReceiversMapper,
      employees,
      positions,
      workspaces,
      workspaceId,
      shifts,
      form,
      isPluginEnabled
    } = this.props
    const { submitting } = this.state
    const { audienceBy } = values || { audienceBy: GLOBAL_POSITION }
    const placeholder =
      audienceBy === GLOBAL_POSITION
        ? 'NF_SELECT_POSITION'
        : audienceBy === GLOBAL_LOCALITY
          ? 'NF_SELECT_LOCALITY'
          : 'NF_ASSIGN_PEOPLE'
    const ReceiversErrorMessages = {
      1: t('NF_MAN_ERROR'),
      2: t('GLOBAL_POSITION_ERROR'),
      3: t('NF_BY_DAY'), // It cant happen
      4: t('NF_LOCATION_ERROR'),
      5: t('NF_WHOLE_COMPANY') // It cant happen
    }

    const optsAudienceBy = [
      {
        value: GLOBAL_SINGLE_PERSON,
        label: t('NF_MAN')
      },
      {
        value: GLOBAL_POSITION,
        label: t('GLOBAL_POSITION')
      },
      {
        value: GLOBAL_DATEPICKER,
        label: t('NF_BY_DAY')
      },
      (isPluginEnabled('localities') ? {
        value: GLOBAL_LOCALITY,
        label: t('NF_LOCATION')
      } : null),
      {
        value: GLOBAL_ALL_USERS,
        label: t('NF_WHOLE_COMPANY')
      }
    ].filter(Boolean)

    let opts = []
    if (audienceBy === GLOBAL_SINGLE_PERSON) {
      opts = sortUtil
        .sortEmployees(employees)
        .map((emp) => {
          return {
            value: emp.id,
            label: emp.name,
            id: emp.id
          }
        })
    }
    if (audienceBy === GLOBAL_POSITION) {
      opts = positions
        .filter(p => !p.archived)
        .map((p) => {
          return {
            value: p.id,
            label: p.name,
            id: p.id
          }
        })
    }
    if (audienceBy === GLOBAL_LOCALITY) {
      const ws = workspaces?.find(w => w.id === workspaceId)
      if (ws) {
        opts = ws.localities
          .map((l) => {
            return {
              value: l.id,
              label: l.name,
              id: l.id
            }
          })
      }
    }

    return (
      <form
        noValidate
        name={NEWS_FEED_MODAL}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Modal
          className='ds-news-feed-modal'
          size={Modal.SIZES.XL}
          headerContent={<div className='ds-title'>{t('NF_CREATE')}</div>}
          sections={[
            <Fragment key={0}>
              <div className='ds-news-feed-modal-inputs'>
                {!updateQuery && (
                  <Dropdown
                    label={null}
                    singleSelect
                    size={Dropdown.SIZES.FULL_WIDTH}
                    type={Dropdown.TYPES.VARIABLE}
                    style={Dropdown.STYLES.LIGHT}
                    options={optsAudienceBy}
                    value={[optsAudienceBy.find(pt => pt.value === audienceBy)].filter(Boolean)}
                    placeholder={t('NF_FOR_WHOM_TO_SEND')}
                    onChange={(option) => {
                      changeFormField(AUDIENCE_BY, option.value)
                      changeFormField(ASSIGN, '')
                      changeFormField(CHECKED_ITEMS, [])
                    }}
                    hasError={Boolean(errors && errors[AUDIENCE_BY] && errors[AUDIENCE_BY] !== '' && meta && meta[SUBJECT] && meta[SUBJECT].touched)}
                    errorMessage={t('NF_FOR_WHOM_TO_SEND_ERROR')}
                  />
                )}
                {values && values.audienceBy === GLOBAL_DATEPICKER && (
                  <div className='ds-news-feed-dt'>
                    <DaySelect
                      singleDaySelection
                      value={moment()}
                      onChange={(val) => {
                        changeFormField(
                          CHECKED_ITEMS,
                          ReceiversMapper({
                            audienceBy: GLOBAL_DATEPICKER,
                            values: val,
                            employees,
                            positions,
                            workspaces,
                            shifts
                          }) || []
                        )
                      }}
                    />
                    {values && !values.checkedItems.length ? (
                      <span>{t('NF_NO_EMPLOYEES')}</span>
                    ) : (
                      <span>
                        {values.checkedItems.length} {t('NF_EMPLOYEE_SELCTED')}
                      </span>
                    )}
                  </div>
                )}
                {!updateQuery && values && !!values.audienceBy && (values.audienceBy !== GLOBAL_DATEPICKER && values.audienceBy !== GLOBAL_ALL_USERS) && (
                  <>
                    <div style={{ minWidth: '100%' }}>
                      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4}>
                        <Dropdown
                          label={null}
                          searchable
                          size={Dropdown.SIZES.FULL_WIDTH}
                          type={Dropdown.TYPES.VARIABLE}
                          style={Dropdown.STYLES.LIGHT}
                          options={opts}
                          value={
                        values?.checkedItems?.map(cb => { return { value: cb.id, label: cb.label } }) ||
                        []
                          }
                          placeholder={<>{t(placeholder)}</>}
                          onChange={(selected) => {
                            changeFormField(CHECKED_ITEMS, selected.map(s => { return { id: s.value, label: s.label } }))
                          }}
                          hasError={
                            Boolean(
                              !values.checkedItems.length &&
                              ReceiversErrorMessages[values.audienceBy] &&
                              meta &&
                              (meta[SUBJECT]?.touched || meta[TEXT]?.touched))
                          }
                          errorMessage={
                            !values.checkedItems.length &&
                            ReceiversErrorMessages[values.audienceBy]
                          }
                        />
                      </Spacing>
                    </div>
                  </>
                )}

                <div style={{ width: '100%', paddingTop: '0.5rem' }}>
                  <FormField name={SUBJECT}>
                    <ErrorField message={t('NF_SUBJECT_ERROR')}>
                      <Input
                        size={Input.SIZES.FULL_WIDTH}
                        className={cx('ds-news-feed-modal-input-field')}
                        type='text'
                        placeholder={t('NF_SUBJECT')}
                      />
                    </ErrorField>
                  </FormField>
                </div>
              </div>
              <div className='ds-news-feed-modal-textarea'>
                <FormField name={TEXT}>
                  <ErrorField message={t('NF_TEXTAREA_PLACEHOLDER_ERROR')}>
                    <Textarea
                      size={Textarea.SIZES.FULL_WIDTH}
                      placeholder={t('NF_TEXTAREA_PLACEHOLDER')}
                      required
                    />
                  </ErrorField>
                </FormField>
              </div>
            </Fragment>,
            <Fragment key={1}>
              <div className='ds-news-feed-modal-checkboxes-container'>
                {!updateQuery && (
                  <CheckBoxInput
                    name={REQUIRE_CONFIRMATION}
                    label={t('NF_CONFIRM_READING')}
                    tip={t('NF_REQUIRE_CONFIRMATION_DESC')}
                  />
                )}
                <CheckBoxInput
                  name={PIN_A_MESSAGE}
                  label={t('NF_PIN_A_MESSAGE')}
                  tip={t('NF_PIN_A_MESSAGE_DESC')}
                />
                <DropFiles
                  loading={submitting}
                  multiple
                  accept={ALLOWED_FILES.map((item) => item.mime)}
                  onChange={(e) => this.setState({ files: [...e] })}
                >
                  <div className='ds-news-feed-files'>
                    <span>{t('NF_DRAG_AND_DROP')}</span>
                  </div>
                </DropFiles>
                <div className='ds-nf-allowed-files'>
                  <span className='ds-nf-allowed-files-title'>
                    {t('NF_ALLOWED_FILE_TYPES') + ':'}
                  </span>
                  <div className='ds-nf-list-allowed-files-wrapper'>
                    <Flex>
                      {ALLOWED_FILES.map((item, k) => (
                        <Icon
                          key={'1' + k}
                          ico={'file-' + item.ext.toLowerCase()}
                        />
                      ))}
                    </Flex>
                  </div>
                </div>
              </div>
            </Fragment>
          ]}
          footerContent={
            <Button
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.GREEN}
              htmlType={Button.HTML_TYPES.SUBMIT}
              onClick={(e) => {
                if (e.detail === 0) {
                  e.preventDefault()
                }
              }}
              label={t('NF_SEND')}
              disabled={submitting || !(updateQuery ||
                (!Object.values(errors).filter((item) => item).length &&
                  values &&
                  (values.checkedItems.length || (values.audienceBy === GLOBAL_DATEPICKER || values.audienceBy === GLOBAL_ALL_USERS)))
              )}
            />
          }
        />
      </form>
    )
  }
}

export default connect(NewsFeed)
