import { toast } from 'react-hot-toast'
import isLoading from './is-loading'
import { routeUtil, client } from '@app/util'
import { setAuth } from '@app/action'

import resetStore from '../action/reset-store'
import store from '../store'
import requestLogout from '../request/logout'

export default (reload = false, navigate = true, request = true) => {
  return async (dispatch) => {
    dispatch(isLoading('log-out'))
    await toast.remove()
    request && await requestLogout(store.getState().auth)
    await dispatch(resetStore())
    await dispatch(setAuth(null))
    await client.resetStore()
    dispatch(isLoading('log-out', true))
    navigate && routeUtil.navigate('/login')
    if (reload) await window.location.reload(true)
  }
}
