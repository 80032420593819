import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Alert,
  Button,
  Flex,
  Input,
  Spacing,
  Text
} from '@ui'
import {
  notification,
  valUtil,
  useAccessRole
} from '@app/util'
import { PERMISSIONS } from '@app/const'
import requestUserInvite from '@app/request/invite-user'
import requestUserInviteResend from '@app/request/user-invite-resend'

import connect from '../connect'

export const Activate = connect(({
  employeeDetail,
  employees,
  workspaceId,
  workspace,
  auth,
  loadEmployeeDetail,
  loadEmployees,
  loadWorkspaceDetail,
  userId
}) => {
  const [state, setState] = useState({
    inviteEmail: null,
    saving: false,
    activeInvite: null
  })
  const ee = { ...employeeDetail, ...employees[userId] }
  const { canWrite } = useAccessRole()

  const getEmployeesInvite = () => workspace.invites && ee.invited && workspace.invites.find(inv => inv.id === ee.invited)

  const handleActivate = async (val) => {
    const err = valUtil.requiredEmail(val)
    if (err) {
      notification.error({
        code: 'emailWrongFormat'
      })
      return
    }

    const performActivate = async (email) => {
      const newCustomData = (ee && ee.customData) ? Object.assign({}, ee.customData) : {}
      if (!newCustomData.firstName) newCustomData.firstName = ee.firstName
      if (!newCustomData.lastName) newCustomData.lastName = ee.lastName

      const requestNewInvite = async () => {
        const result = await requestUserInvite({
          workspace: workspaceId,
          mergeWith: ee.id,
          email: email,
          roleData: {
            customData: newCustomData
          },
          userData: {
            email: email,
            firstName: ee.firstName,
            lastName: ee.lastName
          }
        }, auth)
        setState(() => ({ ...state, saving: false }))
        return result
      }

      const resend = async (id) => {
        const result = await requestUserInviteResend({
          workspace: workspaceId,
          id: id
        }, auth)
        setState(() => ({ ...state, saving: false }))
        return result
      }

      // send invite to this email & set it up to pair with this dummy once accepted
      let res
      if (!ee.invited) {
        const emailIsInUse = employees && Object.keys(employees).map(id => employees[id]).find(e => e.email === email)
        if (emailIsInUse) {
          res = { error: { code: 'emailAlreadyUsedOnWorkspace' } }
        } else {
          res = await requestNewInvite()
        }
      } else {
        const eeInv = getEmployeesInvite(ee)
        if (eeInv && email !== eeInv.email) {
          // if email was changed ...
          const oldInviteWithThisEmail = workspace.invites && workspace.invites.find(inv => inv.mergeWith === ee.id && inv.email === email)
          if (oldInviteWithThisEmail) {
            // if there already exists an older invite with this new email, resend it
            res = await resend(oldInviteWithThisEmail.id)
          } else {
            // if there is no invite with this new email yet, create a new invite
            res = await requestNewInvite()
          }
        } else {
          // if email wasn't changed, just resend the invite
          res = await resend(ee.invited)
        }
      }

      await loadEmployeeDetail(ee.id, true)
      await loadWorkspaceDetail(workspaceId) // we need to reload WS after sending the invite, because invites are part of the WS in store
      loadEmployees() // no need to wait for this to finish here - store.employees update doesn't affect the input being locked by this function
      return res
    }

    setState({ ...state, saving: true })
    const res = await performActivate(val)
    if (res.error) {
      notification.error(res.error)
    } else {
      notification.success({ code: 'emailSent' })
    }
    setState({ ...state, saving: false })
  }

  useEffect(() => {
    if (ee.invited && workspace && workspace.invites) {
      const eeInv = getEmployeesInvite()
      if (eeInv) {
        setState({ ...state, activeInvite: eeInv, inviteEmail: eeInv.email })
      }
    }

    if (!ee.dummy && ee.email && !ee.invited) {
      setState({ ...state, inviteEmail: ee.email })
    }
  }, [employeeDetail])
  return (
    <>
      <Flex direction={Flex.DIRECTION.COLUMN}>
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Alert
            text={t('EMPLOYEE_PROFILE_CARD_ACTIVATE_LONGER_TITLE')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
          <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
          <Text type={Text.TYPES.BODY_MEDIUM}>
            {ee.invited ? state.activeInvite ? t('EMPLOYEE_PROFILE_CARD_ACTIVATE_TXT_ALREADY_SENT_X', { x: moment(state.activeInvite.created).format('D.M.YYYY') }) : t('EMPLOYEE_PROFILE_CARD_ACTIVATE_TXT_ALREADY_SENT')
              : ee.dummy
                ? t('EMPLOYEE_PROFILE_CARD_ACTIVATE_TXT')
                : t('EMPLOYEE_PROFILE_CARD_ACTIVATE_TXT_ALREADY_SENT') + ' ' + t('EMPLOYEE_PROFILE_CARD_ACTIVATE_TXT_YOU_CAN_RESEND_ACTIV')}
          </Text>
          <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
          <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.END}>
            <Input
              type={Input.TYPES.EMAIL}
              size={Input.SIZES.LARGE}
              label={t('EMAIL')}
              placeholder={t('REG_EMAIL_PLACEHOLDER_EMP')}
              value={state.inviteEmail || ''}
              onSubmit={() => handleActivate(state.inviteEmail)}
              onChange={(val) => setState({ ...state, inviteEmail: val })}
            />
            <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8}>
              <Button
                style={Button.STYLES.CONTAINED}
                loading={state.saving}
                label={ee.invited
                  ? (!!getEmployeesInvite(ee) && state.inviteEmail !== getEmployeesInvite(ee).email)
                    ? t('EMPLOYEE_PROFILE_CARD_ACTIVATE_SUBMIT_NEW')
                    : t('RESEND')
                  : t('EMPLOYEE_PROFILE_CARD_ACTIVATE_SUBMIT')}
                onClick={() => handleActivate(state.inviteEmail)}
                disabled={!canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)}
              />
            </Spacing>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
})
