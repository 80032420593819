import React, { useEffect, useState } from 'react'
import { useMutate } from '@app/util/apollo'
import { t } from 'i18next'

import { Button, Flex, Icon, Link, Loader, Modal, Separator, Spacing, Text } from '@ui'
import { miscUtil, notification, useAccessRole, valUtil } from '@app/util'
import { PERMISSIONS } from '@app/const'

import { deleteUserAttributes, requestEmailCheckPublic, requestUserUpdate } from '@app/request'

import {
  Activate,
  CalendarConnect,
  CapacityTable,
  Contracts,
  Cycle,
  Data,
  Language,
  Mobile,
  Navigation,
  Other,
  Overview,
  Password,
  UserAttributes,
  Warnings
} from './components'
import connect from './connect'
import { TerminalGeneratePINForUser } from '@app/queries'
import { MultiFactor } from '@app/component/modals/employee-card/components/MFA/MultiFactor'
import setCalendarRoleVacationFund from '@app/ac/set-calendar-role-vacation-fund'
import moment from 'moment'
import { useRootDispatch, useRootSelector } from '@app/store'

export const EmployeeCard = connect(({
  workspace,
  employeeDetail: ee,
  setModal,
  userId,
  isLoading,
  loadEmployeeDetail,
  auth,
  // me,
  positions,
  workspaceId,
  loadExportToken,
  exportToken,
  updateEmployeeCard,
  isPluginEnabled,
  isPreventedByPlugin,
  employees,
  organizationId,
  afterClose
}) => {
  const [state, setState] = useState({
    form: {
      data: null,
      role: null,
      pass: null,
      accessRoles: []
    },
    activeCard: 'ds-ep-card-activate',
    userAttributes: [],
    openUserAtt: null,
    contracts: [],
    openContract: null,
    localities: [],
    passErrors: [],
    editedSections: [],
    loading: true,
    emailError: false,
    update: 0
  })
  const { me } = useRootSelector(({ me }) => ({ me }))
  const isOwn = me.id === userId
  const cyclesOnWS = workspace?.cycles && workspace.cycles.length ? workspace.cycles : []
  const localitiesOnWS = workspace?.localities && workspace.localities.length ? workspace.localities : []
  const isAdmin = document.querySelector('.ds-adminpanel')
  const { canWrite, canRead } = useAccessRole()
  const dispatch = useRootDispatch()

  // if workspace?.displayVacationsAsDays is not true, we will need to convert vacation fund hours to days (because BE keeps this in days only),
  // so we prepare the conversion here
  // also in Data.js
  const wsDisplayVacationsAsDays = workspace?.displayVacationsAsDays
  const filteredSortedContracts = state.contracts
    .filter(c => {
      const ct = workspace?.contractTypes?.find(ct => ct.id === c.type)
      return ct?.rules?.fixedWorkHours
    })
    .sort((a, b) => moment(a?.period?.start).isBefore(b?.period?.start) ? 1 : -1)
  const vacationDaysToHoursMultiplier = filteredSortedContracts?.length
    ? filteredSortedContracts[0]?.options?.operation === 'threeShift'
      ? 7.5
      : filteredSortedContracts[0]?.options?.operation === 'twoShift'
        ? 7.75
        : 8
    : 8

  const uniqueSection = (key) => new Set([...state.editedSections, key])

  const setForm = (key, data) => {
    setState({
      ...state,
      editedSections: uniqueSection(key),
      form: {
        ...state.form,
        [key]: {
          ...state.form[key],
          ...data
        }
      }
    })
  }

  const { mutation: terminalGeneratePIN } = useMutate(TerminalGeneratePINForUser, { variables: {} })

  const handleCapacityChange = (positionId, value, key) => {
    setState({
      ...state,
      ...{
        contracts: (state.contracts || []).map(c => Object.assign({}, c, {
          positions: (c.positions || []).map(p => {
            return p.positionId === positionId
              ? Object.assign({}, p, { [key]: value })
              : p
          })
        }))
      },
      editedSections: uniqueSection('contracts')
    })
  }

  const handleRoleChange = (acOptions) => {
    setState({
      ...state,
      form: {
        ...state.form,
        accessRoles: acOptions
      },
      editedSections: uniqueSection('role')
    })
  }

  const scrollToSection = (sectionClass) => {
    const el = document.querySelector('.' + sectionClass)
    setState({ ...state, activeCard: sectionClass })
    el.scrollIntoView({ behavior: 'smooth' })
  }

  const deleteAttribute = async (id) => {
    setState({
      ...state,
      userAttributes: state.userAttributes.filter((att) => att.id !== id)
    })
    await deleteUserAttributes({ workspaceId, id }, auth)
  }

  const validatePassword = () => {
    const pass = state.form.pass
    if (!pass) return []
    const passErrors = [
      valUtil.required(pass.oldPassword || '', 'oldPassword'),
      pass.newPassword && valUtil.password(pass.newPassword, 'newPassword'),
      pass.newPassword && valUtil.passwordsMatch(pass.newPassword, pass.repeatPassword, 'repeatedPassword')
    ]
    setState({ ...state, passErrors })
    return passErrors.filter(e => !!e)
  }
  const validateEmail = async () => {
    if (state.form?.data.email !== ee.email) {
      const res = await requestEmailCheckPublic({ email: state.form.data.email })
      setState({ ...state, emailError: res })
      return res
    }
  }

  const sectionsConfig = [
    {
      allowed: ee?.dummy || !ee?.email || ee?.invited,
      targetSection: 'ds-ep-card-activate',
      ico: Icon.ICONS.activation,
      label: t('EMPLOYEE_PROFILE_CARD_ACTIVATE'),
      component: <Activate userId={userId} />,
      customTitle: true
    },
    {
      allowed: true,
      targetSection: 'ds-ep-card-overview',
      ico: Icon.ICONS.calendar,
      label: t('EMPLOYEE_PROFILE_CARD_OVERVIEW'),
      component: (
        <Overview
          update={state.update}
          activeCard={state.activeCard}
          userId={userId}
          contractType={ee?.options?.contract?.type}
          contracts={state.contracts}
        />
      ),
      customTitle: true
    },
    {
      allowed: !!ee?.localities,
      targetSection: 'ds-ep-card-data',
      ico: Icon.ICONS.person,
      label: t('EMPLOYEE_PROFILE_CARD_DATA'),
      component: (
        <Data
          ee={ee}
          isMe={isOwn}
          form={state.form}
          handleChange={setForm}
          handleGenerateTerminalPIN={async (opts) => {
            const newPIN = await terminalGeneratePIN({
              variables: {
                workspace: workspaceId,
                user: userId,
                useEmployeeId: Boolean(opts?.useEmployeeId)
              }
            })
            if (newPIN.errors?.length) {
              notification.error({ code: newPIN.errors[0]?.extensions?.code })
            } else {
              if (newPIN?.data?.terminalGeneratePINForUser) {
                await setForm('role', { ...state.form?.role, terminalPIN: newPIN?.data?.terminalGeneratePINForUser })
              }
            }
          }}
          validateEmail={validateEmail}
          emailError={state.emailError}
          disabled={!canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES) || state.loading}
          workspace={workspace}
          contracts={state.contracts}
        />
      )
    },
    {
      allowed: true,
      targetSection: 'ds-ep-card-self',
      ico: Icon.ICONS.properties,
      label: t('CUSTOM'),
      component: (
        <UserAttributes
          setState={setState}
          state={state}
          setForm={setForm}
          hasWritePermission={canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)}
          deleteAttribute={deleteAttribute}
        />
      ),
      customTitle: true
    },
    {
      allowed: true,
      targetSection: 'ds-ep-card-contract',
      ico: Icon.ICONS.work,
      label: miscUtil.getFilteredContentForOrganization('agreement_name', t('AGREEMENT')),
      component: (
        <>
          <Contracts
            contractTypesOnWS={workspace ? (workspace.contractTypes || []) : []}
            localitiesOnWS={localitiesOnWS}
            positionsOnWs={positions}
            state={state}
            setState={(edit) => setState({ ...state, ...edit })}
            setValue={(edit) => setState({
              ...state,
              ...edit,
              editedSections: uniqueSection('contracts')
            })}
            hasWritePermission={canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)}
            isPluginEnabled={isPluginEnabled}
            isPreventedByPlugin={isPreventedByPlugin}
          />
          {canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES) && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} />
              <CapacityTable
                userId={userId}
                state={state}
                positionsOnWs={positions}
                handleCapacityChange={handleCapacityChange}
                capacityEnabled={isPluginEnabled('capacityplanning')}
              />
            </>
          )}
        </>
      )
    },
    {
      allowed: isOwn,
      targetSection: 'ds-ep-card-mobile',
      ico: Icon.ICONS.phone,
      label: t('ACC_SET_APP_TITLE'),
      component: <Mobile activeCard={state.activeCard} />,
      customTitle: true
    },
    {
      allowed: isOwn,
      targetSection: 'ds-ep-card-language',
      ico: Icon.ICONS.country,
      label: t('ACC_SET_LANGUAGE'),
      component: (
        <Language
          language={state.form.data?.language}
          handleChange={(val) => setForm('data', { language: val })}
        />
      )
    },
    {
      allowed: isOwn,
      targetSection: 'ds-ep-card-password',
      ico: Icon.ICONS.lock,
      label: t('ACC_SET_CHANGE_PASSWORD'),
      component: (
        <>
          <Password
            hasPassword={ee?.hasPassword}
            form={state.form.pass}
            errors={state.passErrors}
            validatePassword={validatePassword}
            setForm={(val) => setForm('pass', val)}
          />
          {isPluginEnabled('mfa') && (
            <MultiFactor me={me} />
          )}
        </>
      )
    },
    {
      allowed: !!cyclesOnWS && canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES),
      targetSection: 'ds-ep-card-cycle',
      ico: Icon.ICONS.cycle,
      label: t('CYCLES'),
      component: (
        <Cycle
          cycleId={state.form.role?.cycleId}
          cycleGroup={state.form.role?.cycleGroup}
          handleChange={(value) => setForm('role', { ...state.form.role, ...value })}
        />
      )
    },
    {
      allowed: canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES) || isOwn,
      targetSection: 'ds-ep-card-other',
      ico: Icon.ICONS.dots,
      label: t('EMPLOYEE_PROFILE_CARD_OTHER'),
      component: (
        <>
          <Other
            userId={userId}
            acRoles={state.form.accessRoles}
            hidden={state.form?.role?.hidden}
            setForm={setForm}
            form={state.form}
            hasWritePermission={canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)}
            isOwn={isOwn}
            setAcRole={handleRoleChange}
          />
        </>
      )
    },
    {
      allowed: isOwn,
      targetSection: 'ds-ep-card-connect',
      ico: Icon.ICONS.connection,
      label: t('CALENDAR'),
      component: (
        <CalendarConnect
          userId={userId}
          hasAccessToManagerCalendar={canRead(PERMISSIONS.CALENDAR)}
          loadExportToken={loadExportToken}
          exportToken={exportToken}
        />
      )
    }
  ]
  const allowedSections = sectionsConfig.filter((section) => section.allowed)

  const onSubmitPassword = async () => {
    if (validatePassword().length === 0) {
      await requestUserUpdate({
        id: userId,
        data: {
          password: {
            oldPassword: (state.form.pass.oldPassword || undefined),
            newPassword: state.form.pass.newPassword
          }
        }
      }, auth).then((res) => {
        if (!res.error) {
          setForm('pass', {
            newPassword: '',
            oldPassword: '',
            repeatPassword: ''
          })
          notification.success({ message: 'ACC_SET_CHANGE_PASSWORD_SUCCESS' })
          return true
        } else {
          notification.error({ message: res.error.message })
          notification.error({ message: 'ACC_SET_CHANGE_PASSWORD_FAILURE' })
        }
      })
    } else {
      return false
    }
  }

  const handleSubmit = async () => {
    setState({ ...state, loading: true })
    if (state.form.pass) {
      const passStatus = await onSubmitPassword()
      if (!passStatus) return false
    }
    const emailStatus = await validateEmail()
    if (emailStatus) return false

    const relevantAccessRoles = state.form.accessRoles.filter(ar => ar.workspaceId === workspaceId)
    const original = state.form.accessRolesOriginal.filter(ar => ar.workspaceId === workspaceId)
    const toAssign = relevantAccessRoles.filter((option) => !original.some((ac) => ac.id === option.id))
    const toUnAssign = original.filter((ac) => relevantAccessRoles.some((option) => !(ac.id === option.id)))

    const newState = {
      ...state,
      form: {
        ...state.form,
        accessRoles: {
          toAssign,
          toUnAssign
        }
      }
    }

    const res = await updateEmployeeCard(newState, userId, isOwn)
    if (res.length === 0) {
      notification.success({ code: 'employeeProfile' })

      const calendarRoleStatsValue = wsDisplayVacationsAsDays ? newState.form.role.vacationDays : newState.form.role.vacationDays * vacationDaysToHoursMultiplier * 60

      dispatch(setCalendarRoleVacationFund(userId, wsDisplayVacationsAsDays, calendarRoleStatsValue))
    } else {
      notification.error({ code: 'not_all' })
    }

    loadData(true)
  }

  const loadData = async (reload) => {
    const detail = await loadEmployeeDetail(userId, true, !!reload)
    setState({
      ...state,
      activeCard: allowedSections[0].targetSection,
      form: {
        ...state.form,
        accessRoles: detail?.options?.accessRoles,
        accessRolesOriginal: detail?.options?.accessRoles,
        data: {
          telephone: detail?.telephone,
          email: detail?.email,
          firstName: detail?.firstName,
          lastName: detail?.lastName,
          language: detail?.language
        },
        role: {
          cycleId: detail?.options?.cycleId,
          cycleGroup: detail?.options?.cycleGroup,
          vacationDays: detail?.options?.vacationDays,
          calendarOrder: detail?.options?.calendarOrder,
          customData: {
            address: detail?.customData?.address,
            employeeId: detail?.customData?.employeeId,
            firstName: detail?.customData?.firstName,
            lastName: detail?.customData?.lastName,
            kmToWork: detail?.customData?.kmToWork
          },
          customAttributes: detail?.options?.customAttributes || [],
          birthdate: detail?.birthdate,
          terminalPIN: detail?.terminalPIN,
          hidden: detail?.hidden
        }
      },
      contracts: detail?.options.contracts || [],
      localities: detail?.localities || [],
      userAttributes: workspace?.userAttributes,
      loading: false,
      openSelfId: null,
      openContract: null,
      update: state.update + 1 // needs refactor of all overview state up to EMP CARD
    })
  }
  useEffect(() => { loadData() }, [userId, workspaceId])

  return (
    <Modal
      size={Modal.SIZES.XL}
      isLoading={!ee}
      afterClose={afterClose}
      footerContent={
        <Flex justify={Flex.POSITION.SPC_BETWEEN} grow={1}>
          {isAdmin ? (
            <Link
              type={Link.TYPES.ON_CLICK}
              onClick={() => miscUtil.copyToClipBoard(ee?.id)}
            >
              ID: ${ee && ee.id}
            </Link>
          ) : <div />}
          <Flex>
            <Button
              size={Button.SIZES.LARGE}
              label={t('CLOSE')}
              onClick={() => setModal(null)}
            />
            <Button
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              label={t('SAVE')}
              disabled={state.emailError || (!canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES) && !isOwn)}
              loading={state.loading}
              onClick={handleSubmit}
            />
          </Flex>
        </Flex>
      }
      headerContent={
        <Text
          text={t('EMPLOYEE_PROFILE_CARD_TITLE')}
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.BOLD}
        />
      }
    >
      {ee && (
        <Flex>
          <Separator side={Separator.SIDES.RIGHT}>
            <Navigation
              ee={ee}
              sectionsConfig={sectionsConfig}
              scrollToSection={scrollToSection}
              setModal={setModal}
              hasWritePermission={canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)}
              activeCard={state.activeCard}
              employees={employees}
            />
          </Separator>
          <div
            className='ds-ep-cards'
            style={{ height: '34rem', overflowY: 'scroll' }}
          >
            <Loader
              isLoading={isLoading || state.loading}
              size={Loader.SIZES.LARGE}
              type={Loader.TYPES.OVERLAY}
            >
              <Spacing
                size={Spacing.SIZES.SIZE_16}
                type={Spacing.TYPES.HORIZONTAL}
              >
                <Spacing size={Spacing.SIZES.SIZE_20}>
                  {canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES) && <Warnings ee={ee} />}
                  {allowedSections.map((section, i) => (
                    <div className={'ec-card ' + section.targetSection} key={section.targetSection}>
                      {i !== 0 && <Spacing size={Spacing.SIZES.SIZE_20} />}
                      {!section.customTitle && (
                        <>
                          <Text
                            type={Text.TYPES.BODY_LARGE}
                            weight={Text.WEIGHTS.BOLD}
                            color={state.activeCard === section.targetSection ? Text.COLORS.PRIMARY : Text.COLORS.BLACK}
                          >
                            <Flex align={Flex.POSITION.CENTER}>
                              <Icon ico={section.ico} size={Icon.SIZES.SMALL} />
                              <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
                              {section.label}
                            </Flex>
                          </Text>
                          <Spacing size={Spacing.SIZES.SIZE_18} />
                        </>
                      )}
                      {section.component}
                      {allowedSections.length - 1 !== i && (
                        <>
                          <Spacing size={Spacing.SIZES.SIZE_20} />
                          <Separator />
                        </>
                      )}
                    </div>
                  ))}
                </Spacing>
              </Spacing>
            </Loader>
          </div>
        </Flex>
      )}
    </Modal>
  )
}
)
