import React, { useEffect, useState } from 'react'
import { Router } from 'react-router'
import { Offline, Online } from 'react-detect-offline'
import { Toaster } from 'react-hot-toast'

import { AdminPanel } from '@core'
import { Loader } from '@ui'
import { miscUtil, routeUtil } from '@app/util'
import requestMeta from '@app/request/meta'

import OfflinePage from './component/pages/other/offline'
import './style/index.scss'
import './dayswaps.scss'
import './favicons'
import connect from './connect'
import { Routes } from './routing'
import Websockets from './websockets'

const Dayswaps = ({
  login,
  auth,
  logout,
  locale
}) => {
  const [loading, setLoading] = useState(true)

  const loadMeta = () => {
    requestMeta()
      .then((res) => {
        // check for null returned due to old, invalid auth token
        if (!res) {
          console.log('Reseting the store and auth token.')
          logout(false, false, false)
        }

        if (!res?.frontend) return
        const currentCommitStorage = window.localStorage.getItem('ds-current-commit')
        window.localStorage.setItem('ds-current-commit', res.frontend.time)

        if (currentCommitStorage < res.frontend.time) {
          if (window.location.hostname !== 'localhost') {
            console.log('Version in localStorage is older than the current version at the server. Reloading the app.')
            window.location.reload(true)
          }
        }
      })
      .then(() => auth && !window.location.href.includes('auth') && login({ auth }))
      .then(() => setLoading(false))
  }

  useEffect(() => { loadMeta() }, [])
  useEffect(() => { /* Way to make app rerender on locale change */ }, [locale])

  if (loading) return <Loader size={Loader.SIZES.LARGE} />
  return (
    <>
      <AdminPanel />
      <Online polling={{ enabled: false }}>
        {(!miscUtil.isTerminalInterface()) && <Websockets />}
        <Router history={routeUtil.history}>
          <Routes />
        </Router>
      </Online>
      <Offline polling={{ enabled: false }}>
        <OfflinePage />
      </Offline>
      <Toaster position='top-right' />
    </>
  )
}

export default connect(Dayswaps)
