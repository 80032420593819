import React from 'react'

import './wsSelection.scss'
import classNames from 'classnames'
import { Tooltip } from '@ui'
import { t } from 'i18next'

export const WsSelection = ({ onClick, isOrg, wsName, dark }) => (
  <Tooltip
    text={wsName}
    className='ws-selection-tooltip'
    position={Tooltip.POSITIONS.BOTTOM}
    anchor={
      <div className={classNames('ws-selection', { dark: isOrg })} onClick={onClick}>
        <div className='ws-selection-text'>
          {wsName || t('SELECT')}
        </div>
      </div>
    }
  />
)
