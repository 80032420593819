import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { calendarUtil, timeUtil } from '@app/util'

import {
  Table,
  Text,
  MonthSelect,
  Icon,
  Flex,
  Spacing
} from '@ui'

import requestAvailabilitiesUser from '@app/request/availabilities-user'
import requestRoleStats from '@app/request/role-stats'
import momentUtil from '@app/util/moment'
import { formatHours } from '@app/util/format-hours'

import connect from '../../connect'
import { InfoBoxes } from './InfoBoxes'

export const Overview = connect(({
  calendar,
  auth,
  workspaceId,
  userId,
  workspace,
  activeCard,
  contractType,
  contracts,
  update
}) => {
  const [state, setState] = useState({
    date: calendar?.date || moment(),
    roleStats: [],
    unavailabilities: []
  })

  const loadReq = async (dateParam) => {
    let resRole = await requestRoleStats(
      {
        workspace: workspaceId,
        user: userId,
        referenceDate: state.date
      },
      auth
    )
    resRole = {
      ...resRole,
      yearUnavailabilities: (resRole?.yearUnavailabilities?.filter((unav) => unav.minutes !== 0)) || []
    }
    const d = dateParam || state.date

    // load employee's unavailabilities if we don't already have them
    const resUnavs = requestAvailabilitiesUser(
      {
        user: userId,
        workspace: workspaceId,
        period: {
          start: moment(d).startOf('month'),
          end: moment(d).endOf('month')
        }
      },
      auth
    )

    setState({
      ...state,
      unavailabilities: resUnavs,
      roleStats: resRole
    })
  }

  const getTableFundValues = (total, minutesRemaining) => {
    const totalHour = timeUtil.asHour(total)
    const hourRemaining = timeUtil.asHour(minutesRemaining)
    if (hourRemaining <= 0) {
      return t('NOT_SET2')
    }
    return t('EMPLOYEE_PROFILE_UNAV_TABLE_FUND_DATA', { hourRemaining: formatHours(hourRemaining), hourTotal: formatHours(totalHour) })
  }

  useEffect(() => { momentUtil.setLocale() }, [])
  useEffect(() => { loadReq() }, [state.date, update > 1])

  const filteredRoleStats = state.roleStats?.yearUnavailabilities && state.roleStats.yearUnavailabilities.filter((unav) => unav.categoryId !== 'dddddddddddddddddddddddd')
  const tableConfig = [
    {
      key: 'categoryId',
      tlKey: 'EMPLOYEE_PROFILE_UNAV_TABLE_CAT',
      sortable: true,
      rowToHTML: (row) => {
        const category = calendarUtil.getTimeOffOrAvailCategory(row, workspace)
        return (
          category?.name +
          (row?.available
            ? '_AVAIL'
            : '')
        )
      },
      rowToStr: (row) => {
        const category = calendarUtil.getTimeOffOrAvailCategory(row, workspace)
        return (
          category?.name +
          (row?.available
            ? '_AVAIL'
            : '')
        )
      }
    },
    {
      key: 'minutes',
      tlKey: 'HOURS',
      sortable: true,
      rowToHTML: (row) => {
        return formatHours(timeUtil.asHour(row.minutes))
      },
      rowToStr: (row) => {
        return formatHours(timeUtil.asHour(row.minutes))
      }
    },
    {
      key: 'minutesRemaining',
      tlKey: 'EMPLOYEE_PROFILE_UNAV_TABLE_FUND',
      sortable: true,
      rowToHTML: (row) => {
        return getTableFundValues(row.minutesMaximum, row.minutesRemaining)
      },
      rowToStr: (row) => {
        return getTableFundValues(row.minutesMaximum, row.minutesRemaining)
      }
    }
  ]

  const contractInSelectedMonth = contracts ? contracts.filter((contract) => calendarUtil.isPeriodsOverlap(contract?.period, { start: moment(state.date).startOf('month'), end: moment(state.date).endOf('month') })) : []
  const hasFixedHoursContract = workspace?.contractTypes
    ?.filter(ctp => ctp.id === contractType || contractInSelectedMonth.some((item) => ctp.id === item.type))
    .some((item) => item?.rules?.fixedWorkHours)

  const hasContractWithVacationFund = workspace?.contractTypes
    ?.filter(ctp => ctp.id === contractType || contractInSelectedMonth.some((item) => ctp.id === item.type))
    .some((item) => item?.rules?.vacationFund)

  const wsBalancingPeriod = workspace?.settings?.balancingPeriod
  const wsBalancingPeriodRelevant = (!!wsBalancingPeriod && (wsBalancingPeriod.unit !== 'month' || wsBalancingPeriod.amount !== 1))
  const wsDisplayVacationsAsDays = workspace?.displayVacationsAsDays

  const sectionName = 'ds-ep-card-overview'
  return (
    <>
      <Flex direction={Flex.DIRECTION.ROW} align={Flex.POSITION.CENTER}>
        <Text
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.BOLD}
          color={activeCard === sectionName ? Text.COLORS.PRIMARY : Text.COLORS.BLACK}
        >
          <Flex align={Flex.POSITION.CENTER}>
            <Icon ico='calendar' size={Icon.SIZES.SMALL} />
            <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
            {t('EMPLOYEE_PROFILE_CARD_OVERVIEW')}
          </Flex>
        </Text>
        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_20} />
        <MonthSelect
          returnType={MonthSelect.RETURN_TYPES.STRING}
          style={MonthSelect.STYLES.BORDERLESS}
          value={state.date ? moment(state.date) : null}
          onChange={(v) => {
            setState({
              ...state,
              date: moment(v).startOf('month').format('YYYY-MM-DD')
            })
          }}
        />
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_18} />
      <InfoBoxes
        roleStats={state.roleStats}
        hasFixedHoursContract={hasFixedHoursContract}
        hasContractWithVacationFund={hasContractWithVacationFund}
        wsBalancingPeriodRelevant={wsBalancingPeriodRelevant}
        wsDisplayVacationsAsDays={wsDisplayVacationsAsDays}
        contracts={contracts}
        workspaceId={workspaceId}
        userId={userId}
      />
      <Spacing size={Spacing.SIZES.SIZE_18} />
      {!!filteredRoleStats?.length && (
        <Table
          type={Table.TYPES.LEAN}
          size={Table.SIZE.SLIM}
          tableName='employeeProfileUnavsTable'
          showSelection={false}
          tableConfig={tableConfig}
          rows={filteredRoleStats}
          showActionBar={false}
          pagination={false}
        />
      )}
    </>
  )
})
