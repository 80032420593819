import React, { useState } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Button,
  Icon,
  Link,
  Radio,
  Text,
  Tooltip,
  Flex,
  Spacing
} from '@ui'
import clipUtil from '@app/util/clipboard'

export const CalendarConnect = ({
  hasAccessToManagerCalendar,
  userId,
  loadExportToken,
  exportToken
}) => {
  const [type, setType] = useState('google')
  const [onlyMyShifts, setOnlyMyShifts] = useState(true)

  const handleSubmit = () => {
    const st = moment().startOf('month').format('YYYY-MM-DD')
    const en = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')

    loadExportToken(
      'ical',
      st,
      en,
      type,
      (onlyMyShifts ? userId : undefined)
    ).then((res) => {
      if (type === 'google') {
        window.open(res.googleImportUrl, '_blank')
      }
      if (type === 'ical') {
        window.open(res.url, '_blank')
      }
    })
  }
  return (
    <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.CENTER}>
      <Text
        type={Text.TYPES.BODY_MEDIUM}
        text={t('ACC_SET_DS_SUPPORTS_CALENDAR')}
      />
      <Spacing size={Spacing.SIZES.SIZE_12} />
      <Tooltip
        anchor={
          <Button
            size={Button.SIZES.LARGE}
            label={t('ACC_SET_CONNECT')}
          />
        }
        position={Tooltip.POSITIONS.LEFT_BOTTOM}
        clickable
        width='16rem'
      >
        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
          <Flex direction={Flex.DIRECTION.COLUMN}>
            <Text
              type={Text.TYPES.BODY_MEDIUM}
              weight={Text.WEIGHTS.BOLD}
              text={t('EXPORT_CALS_TYPE')}
            />
            <Spacing size={Spacing.SIZES.SIZE_12} />
            <Flex align={Flex.POSITION.CENTER}>
              <Radio
                onClick={() => setType('google')}
                value={type === 'google'}
              >
                {t('EXPORT_CALS_TYPE1')}
              </Radio>
              <Radio
                value={type === 'ical'}
                label={t('EXPORT_CALS_TYPE2')}
                onClick={() => setType('ical')}
              />
              <Radio
                value={type === 'other'}
                onClick={() => {
                  setType('other')
                  !exportToken.icsUrl && loadExportToken('ical')
                }}
                label={t('EXPORT_CALS_TYPE3')}
              />
            </Flex>
          </Flex>
          <Spacing size={Spacing.SIZES.SIZE_12} />
          {type === 'other' && (exportToken?.icsUrl) && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_8} />
              {t('ACC_SET_CALENDAR_URL')}
              <Spacing size={Spacing.SIZES.SIZE_8} />
              <Link
                type={Link.TYPES.ON_CLICK}
                onClick={() => clipUtil.copy(exportToken?.icsUrl)}
              >
                <Flex align={Flex.POSITION.CENTER}>
                  <Icon ico={Icon.ICONS.link} />
                  <div style={{ lineBreak: 'anywhere', marginLeft: '0.5rem' }}>
                    <Text
                      weight={Text.WEIGHTS.BOLD}
                      type={Text.TYPES.BODY_MEDIUM}
                      text={exportToken?.icsUrl}
                    />
                  </div>
                </Flex>
              </Link>
            </>
          )}
          {hasAccessToManagerCalendar ? (
            <>
              <div style={{
                margin: '0.8rem 0',
                borderBottom: 'solid 1px #d9e3ff'
              }}
              />
              <Flex align={Flex.POSITION.CENTER}>
                <Radio
                  onClick={() => setOnlyMyShifts(true)}
                  value={onlyMyShifts === true}
                >
                  {t('EXPORT_CALS_ONLY_MY_SHIFTS_TRUE')}
                </Radio>
                <Radio
                  onClick={() => setOnlyMyShifts(false)}
                  value={onlyMyShifts === false}
                >
                  {t('EXPORT_CALS_ONLY_MY_SHIFTS_FALSE')}
                </Radio>
              </Flex>
              <Spacing size={Spacing.SIZES.SIZE_8} />
            </>
          ) : null}
          <Spacing size={Spacing.SIZES.SIZE_12} />
          <Button
            label={t('EXPORT_SUBMIT')}
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            onClick={handleSubmit}
          />
        </Spacing>
      </Tooltip>
    </Flex>
  )
}

CalendarConnect.propTypes = {}
CalendarConnect.defaultProps = {}
