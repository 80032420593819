import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import loadMoreNotifications from '@app/ac/load-more-notifications'
import {
  Text,
  Spacing,
  NotificationCard,
  Flex
} from '@ui'

import './notifications-infinite-list.scss'

export function NotificationsInfiniteList ({ notifications, onOpenNotification }) {
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const loading = useSelector(state => state.loading).includes('load-notifications')
  const dispatch = useDispatch()

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: async () => {
      const hasNextPage = await dispatch(loadMoreNotifications())
      console.log({ hasNextPage })
      setHasNextPage(hasNextPage)
    },
    rootMargin: '0px 0px 400px 0px'
  })

  let currentDate

  return (
    <div ref={rootRef} className='ds-notifications-infinite-list'>
      {notifications.map((notification, index) => {
        const createdDate = moment(notification.created)

        const isNewDate =
          currentDate == null || moment(currentDate).isSame(createdDate, 'day') === false

        if (isNewDate) {
          currentDate = createdDate
        }

        return (
          <React.Fragment key={notification.id}>
            {isNewDate && (
              <>
                {index === 0 ? null : (
                  <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16} />
                )}
                <Text
                  type={Text.TYPES.BODY_MEDIUM}
                  color={Text.COLORS.BLACK}
                  weight={Text.WEIGHTS.BOLD}
                >
                  {moment(notification.created).format('D.M. dddd')}
                </Text>
                <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_12} />
              </>
            )}
            <NotificationCard
              notification={notification}
              onOpen={
                notification.message.uri
                  ? () => onOpenNotification(notification)
                  : undefined
              }
            />
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
          </React.Fragment>
        )
      })}
      <Flex justify='center' stretch>
        <div ref={sentryRef} />
      </Flex>
    </div>
  )
}
