import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation UserInviteResendMutation (
  $id: ID!
  $workspace: ID!
) {
  userInviteResend (
    id: $id
    workspace: $workspace
  ) {
    id
  }
}`

async function requestUserInviteResend (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('user-invite-resend', errors)
  }
  return data.userInviteResend
}

export default requestUserInviteResend
