import React from 'react'
import { t } from 'i18next'

import { Avatar, Flex, Text, Icon, Spacing } from '@ui'
import { miscUtil } from '@app/util'

export function EmployeeList ({
  employees,
  onRemove,
  canBeRemoved = () => true
}) {
  return employees.map((employee, index) => {
    return (
      <React.Fragment key={employee.id}>
        <Flex align={Flex.POSITION.CENTER} justify={Flex.POSITION.SPC_BETWEEN} grow={1}>
          <Flex align='center'>
            <Avatar size='24' profile={employee.name ? employee : undefined} />
            <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
            <Text type={Text.TYPES.BODY_MEDIUM}>
              {employee.name
                ? employee.name
                : miscUtil.getEnforcedLocality()
                  ? t('POS_OTHER_LOC_EMP')
                  : t('POS_EXTERNAL_EMP')}
            </Text>
          </Flex>
          {canBeRemoved(employee) && (
            <Icon
              ico={Icon.ICONS.close1}
              color={Icon.COLORS.RED}
              size={Icon.SIZES.SMALL}
              onClick={() => onRemove(employee.id)}
            />
          )}
        </Flex>
        {index + 1 < employees.length && <Spacing size={Spacing.SIZES.SIZE_4} />}
      </React.Fragment>
    )
  })
}
