import React from 'react'
import { t } from 'i18next'
import { Button } from '@ui'
import { miscUtil } from '@app/util'
import moment from 'moment'

export const FooterButtons = ({
  processRequest,
  shifts,
  acceptOrDenyRequest,
  canSave,
  isEdited,
  setModal,
  afterSubmit,
  details,
  disabledEditation,
  isRequestingAbsence,
  checkEndBeforeStart,
  checkUserNotSelected,
  requestAbsence,
  newAvailabilityOrTimeOff,
  onSave,
  close,
  allDayRadio
}) => {
  const saveObject = ({
    period: details.period,
    periodType: allDayRadio === 'full'
      ? 'wholeDay'
      : allDayRadio === 'half'
        ? 'halfDay'
        : 'partDay',
    user: details.userId,
    categoryId: details.categoryId,
    available: details.available,
    note: details.note,
    recurrence: details.recurrence,
    workMinutesInclusion: details.workMinutesInclusion,
    workMinutes: details.workMinutes,
    workMinutesBy: details.workMinutesBy,
    attachments: details?.attachments ? details.attachments.map((item) => item.id) : []
  })

  const handleSave = () => {
    if (disabledEditation && disabledEditation === 'disabled-due-to-locked-period') return
    if (canSave) onSave()
    checkUserNotSelected()
    checkEndBeforeStart()
  }

  const handleApproveRequest = async () => {
    let result = null
    if (canSave) {
      result = await acceptOrDenyRequest({
        reply: 'yes',
        id: details.requestPendingAction,
        data: isEdited ? saveObject : null
      })
      await setModal(null)
      if (afterSubmit) await afterSubmit()
    }
    await checkEndBeforeStart()

    // if just-approved timeOff overlaps some existing shifts, open the 'timeoff-conflict' modal and let manager decide how to resolve it
    if (result?.result === 'ok' && details.periodType !== 'halfDay') {
      const overlappingShifts = shifts.filter(s =>
        s.userId &&
        s.userId === details.userId &&
        moment(s.period.start).isSameOrBefore(details.period.end) &&
        moment(s.period.end).isSameOrAfter(details.period.start)
      )

      if (overlappingShifts.length && overlappingShifts[0].stats?.length) {
        const halfDayType = (moment(details?.period?.end).diff(details?.period?.start, 'minutes') === (overlappingShifts[0]?.stats[0]?.workMinutes / 2))
          ? moment(overlappingShifts[0].period.start).isSame(details.period.start)
            ? 'start'
            : moment(overlappingShifts[0].period.end).isSame(details.period.end)
              ? 'end'
              : undefined
          : undefined

        setModal('timeoff-conflict', {
          conflictingShifts: overlappingShifts,
          resultTimeOffs: [{
            id: details.id,
            period: details.period
          }],
          halfDayType
        })
      }
    }
  }

  const handleDenyRequest = async () => {
    await acceptOrDenyRequest({
      reply: 'no',
      id: details.requestPendingAction
    })
    setModal(null)

    // if an 'afterSubmit' function was provided, we'll call it here
    if (afterSubmit) afterSubmit()
  }

  const handleRequestAbsence = async () => {
    if (canSave) {
      await requestAbsence()
      if (afterSubmit) afterSubmit()
    }
    checkEndBeforeStart()
  }

  const confirmButton = () => {
    if (disabledEditation === 'disabled-employee-hard-unav' && !isEdited) return null
    if (processRequest) {
      return (
        <Button
          label={t('ATTEN_APPROVE')}
          size={Button.SIZES.LARGE}
          style={Button.STYLES.CONTAINED}
          disabled={!canSave}
          onClickActiveWhenDisabled
          onClick={handleApproveRequest}
        />
      )
    }
    if (isRequestingAbsence) {
      return (
        <Button
          label={t('REQUEST_UNAVAILABILITY_MODAL_BTN')}
          size={Button.SIZES.EXTRA}
          style={Button.STYLES.CONTAINED}
          disabled={!canSave}
          onClickActiveWhenDisabled
          onClick={handleRequestAbsence}
        />
      )
    } else {
      return (
        <Button
          size={Button.SIZES.LARGE}
          style={Button.STYLES.CONTAINED}
          color={newAvailabilityOrTimeOff ? Button.COLORS.GREEN : Button.COLORS.PRIMARY}
          label={newAvailabilityOrTimeOff ? t('ADD_UNAV_OR_SHIFT') : t('SAVE')}
          disabled={!canSave || (disabledEditation && disabledEditation === 'disabled-due-to-locked-period')}
          onClickActiveWhenDisabled
          onClick={handleSave}
        />
      )
    }
  }

  return (
    <>
      {processRequest ? (
        <Button
          label={t('REQUEST_UNAV_DENY')}
          size={Button.SIZES.LARGE}
          onClick={handleDenyRequest}
        />
      ) : (
        <Button
          label={t('CLOSE')}
          size={Button.SIZES.LARGE}
          onClick={close}
        />
      )}
      {confirmButton()}
      {details.id && (
        <div
          style={{
            position: 'absolute',
            left: '1ex',
            bottom: '1ex',
            fontSize: '65%',
            color: 'rgb(204, 204, 204)'
          }}
          onClick={() => {
            miscUtil.copyToClipBoard(details.id)
            console.log(details.id)
          }}
        >
          id: {details.id}
        </div>
      )}
    </>
  )
}

FooterButtons.propTypes = {}
FooterButtons.defaultProps = {}
