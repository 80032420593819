import fetchRequest from '../util/fetch'
import errUtil from '../util/error'

const query = `mutation RemoveMfaFactorMutation($type: MFAMethods!) {
    removeMfaFactor(type: $type)
}
`

async function requestRemoveMfaFactor (variables) {
  const response = await fetchRequest({ query, variables })
  if (!response) return errUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errUtil.apiError('removeMfaFactor', errors)
  }
  return data.removeMfaFactor
}

export default requestRemoveMfaFactor
