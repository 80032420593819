import React, { Fragment } from 'react'
import {
  Avatar,
  Icon,
  Link,
  Text,
  Flex,
  Spacing,
  Dropdown
} from '@ui'

export const Navigation = ({
  ee,
  sectionsConfig,
  scrollToSection,
  setModal,
  activeCard,
  hasWritePermission,
  employees
}) => {
  const user = (
    <Flex align={Flex.POSITION.CENTER}>
      <Avatar size={30} profile={ee} />
      <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
      <Flex direction={Flex.DIRECTION.COLUMN}>
        <Text
          type={Text.TYPES.BODY_LARGE}
          weight={Text.WEIGHTS.SEMI_MEDIUM}
        >
          <div style={{ whiteSpace: 'break-spaces' }}>{ee.name}</div>
        </Text>
        {ee?.customData?.employeeId && (
          <Text type={Text.TYPES.BODY_MEDIUM} text={`ID: ${ee?.customData?.employeeId}`} />
        )}
      </Flex>
    </Flex>
  )
  return (
    <div className='ds-ep-nav' style={{ width: '11.5rem' }}>
      <Spacing size={Spacing.SIZES.SIZE_20}>
        <Flex>
          {(hasWritePermission && Object.values(employees).filter((employee) => !employee.external).length > 1) ? (
            <div>
              <Dropdown
                label={null}
                placeholder={user}
                searchable
                singleSelect
                size={Dropdown.SIZES.FULL_WIDTH}
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.BORDERLESS}
                options={Object.values(employees)
                  .filter((employee) => !employee.external)
                  .map((employee) => {
                    return {
                      value: employee.id,
                      label: employee.name
                    }
                  })}
                onChange={(employee) => {
                  setModal('employee-profile', { userId: employee.value })
                }}
              />
            </div>
          ) : (
            <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
              {user}
            </Spacing>
          )}
        </Flex>
      </Spacing>
      {sectionsConfig.map(
        (item) =>
          item.allowed && (
            <Fragment key={item.targetSection}>
              <Link
                type={Link.TYPES.ON_CLICK}
                onClick={() => scrollToSection(item.targetSection)}
              >
                <Text
                  color={
                    activeCard === item.targetSection
                      ? Text.COLORS.PRIMARY
                      : Text.COLORS.BLACK
                  }
                  weight={
                    activeCard === item.targetSection
                      ? Text.WEIGHTS.BOLD
                      : Text.WEIGHTS.SEMI_MEDIUM
                  }
                >
                  <Flex align={Flex.POSITION.CENTER}>
                    <Spacing
                      size={Spacing.SIZES.SIZE_16}
                      type={Spacing.TYPES.HORIZONTAL}
                    />
                    <Icon ico={item.ico} size={Icon.SIZES.SMALL} />
                    <Spacing
                      size={Spacing.SIZES.SIZE_6}
                      type={Spacing.TYPES.HORIZONTAL}
                    />
                    {item.label}
                  </Flex>
                </Text>
              </Link>
              <Spacing size={Spacing.SIZES.SIZE_14} />
            </Fragment>
          )
      )}
    </div>
  )
}
