import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import {
  updateShiftTemplate,
  updateLiveShift,
  setModal,
  setSidebar,
  setCalendarMultiSelect,
  setCalendar,
  deleteLiveShift,
  setCycleDetail,
  deleteCycleDetail
} from '@app/ac'
import setCycleDetailLastSaved from '@app/ac/set-cycle-detail-last-saved'
import createShift from '@app/ac/create-shift'
import { withDoubleClick } from '@core/hoc/withDoubleClick'
import { withPlugin } from '@core/hoc/withPlugin'

const mapStateToProps = ({ auth, calendar, positions, workspaces, workspaceId, calendarMultiSelect, calendarFilters, cycleDetail }) => {
  return {
    auth,
    calendarMultiSelect,
    calendarFilters,
    calendar: calendar || {},
    positions,
    workspace: workspaces.find(ws => ws.id === workspaceId) || {},
    cycleDetail
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setModal,
    setSidebar,
    setCalendarMultiSelect,
    setCalendar,
    updateLiveShift,
    updateShiftTemplate,
    deleteLiveShift,
    setCycleDetail,
    deleteCycleDetail,
    setCycleDetailLastSaved,
    createShift
  }, dispatch)
}

export default (component) => {
  return compose(
    withDoubleClick,
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
