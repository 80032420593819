import setSidebar from './set-sidebar'
import setModal from './set-modal'
import resetErrors from './reset-errors'

export default () => {
  return async (dispatch) => {
    dispatch(setSidebar(null))
    dispatch(setModal(null))
    dispatch(resetErrors())
  }
}
