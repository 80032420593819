import React, { Fragment } from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Button,
  CircledNumber,
  Dropdown,
  HelpTooltip,
  Icon,
  Input,
  Text,
  Flex,
  Spacing
} from '@ui'
import { formatHours } from '@app/util/format-hours'

export const Contracts = ({
  hasWritePermission,
  state,
  setState,
  setValue,
  contractTypesOnWS,
  localitiesOnWS,
  positionsOnWs,
  isPreventedByPlugin,
  isPluginEnabled
}) => {
  const { openContract, contracts, localities } = state
  const activeCon = contracts ? contracts.find((con) => con.id === openContract?.id) : []
  const contractTypesOptions = contractTypesOnWS.map((ct) => ({ label: ct.name, value: ct.id }))
  const currentContractType = contractTypesOnWS && activeCon ? contractTypesOnWS.find((ct) => ct.id === activeCon.type) : contractTypesOnWS.find((ct) => ct.id === 'cz.hp')
  const halfWidth = { width: '50%' }

  const positions = activeCon
    ? (positionsOnWs || []).filter(p => !p.archived && activeCon.positions?.some(cp => cp.positionId === p.id))
    : []

  const setOpenContract = (id) => setState({ openContract: id })
  const createNewContract = () => {
    setValue({
      openContract: { id: 'new', editable: true },
      contracts: [
        ...contracts,
        {
          id: 'new',
          type: contractTypesOnWS[0].id,
          options: {
            minutesOfWorkPerMonth: 9600,
            minutesOfWorkPerShift: 480,
            operation: 'singleShift'
          },
          period: { start: moment(), end: null }
        }
      ]
    })
  }
  const deleteNewContract = () => setState({
    openContract: null,
    contracts: contracts.filter((item) => item.id !== 'new')
  })

  const onFieldChange = (key, value) => {
    const previousIndex = contracts.findIndex((con) => con.id === openContract?.id)
    const contractsCopy = [...contracts]
    contractsCopy[previousIndex] = {
      ...contractsCopy[previousIndex],
      [key]: value
    }
    setValue({ contracts: contractsCopy })
  }

  const convertFormVal = (type, key, val, invert = false) => {
    let ret = val
    if (type === 'number') {
      if (ret === null || typeof ret === 'undefined') ret = 0
      ret = parseFloat(ret)
    }
    if (key === 'minutesOfWorkPerMonth' || key === 'minutesOfWorkPerShift') {
      if (!invert) {
        ret = ret / 60
      } else {
        ret = ret * 60
      }
    }
    return ret
  }
  const handleOptionsChange = (val, key, type) => {
    const changeMinutes = (month, shift) => onFieldChange('options', {
      ...activeCon.options,
      minutesOfWorkPerMonth: month,
      minutesOfWorkPerShift: shift,
      operation: val
    })
    switch (val) {
      case 'singleShift':
        return changeMinutes(9600, 480)
      case 'twoShift':
        return changeMinutes(9300, 465)
      case 'threeShift':
        return changeMinutes(9000, 450)
      default:
        return onFieldChange('options', {
          ...activeCon.options,
          [key]: convertFormVal(type, key, val.value, true)
        })
    }
  }
  const form = () => (
    <>
      <Spacing size={Spacing.SIZES.SIZE_14} />
      <div style={halfWidth}>
        <Flex>
          <Dropdown
            type={Dropdown.TYPES.VARIABLE}
            size={Dropdown.SIZES.FULL_WIDTH}
            style={Dropdown.STYLES.LIGHT}
            singleSelect
            value={contractTypesOptions.find((op) => op.value === activeCon?.type)}
            onChange={(val) => onFieldChange('type', val.value)}
            label={t('CONTRACT_TYPES')}
            disabled={!openContract.editable}
            options={contractTypesOptions}
          />
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
        </Flex>
      </div>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Flex align={Flex.POSITION.CENTER} flex='1'>
          <Flex direction={Flex.DIRECTION.COLUMN}>
            <Flex>
              <Text
                text={t('BATCH_EMPS_CONTRACT_HOURS_MONTH')}
                type={Text.TYPES.BODY_MEDIUM}
                weight={Text.WEIGHTS.BOLD}
              />
              <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
              <HelpTooltip text={t('EMPLOYEE_ADD_HOURS_TOOLTIP')} />
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_4} />
            <Input
              size={Input.SIZES.LARGE}
              type={Input.TYPES.NUMBER}
              min={0}
              disabled={!openContract.editable}
              placeholder='160'
              value={convertFormVal('number', 'minutesOfWorkPerMonth', activeCon?.options?.minutesOfWorkPerMonth)}
              onChange={(val) => onFieldChange(
                'options',
                {
                  ...activeCon.options,
                  minutesOfWorkPerMonth: convertFormVal('number', 'minutesOfWorkPerMonth', Math.abs(val), true)
                }
              )}
            />
          </Flex>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            style={{ paddingTop: '1.1rem' }}
            color={Text.COLORS.PRIMARY}
            text={t('HOUR_SHORTEST')}
          />
        </Flex>
        <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
        <Flex align={Flex.POSITION.CENTER} flex='1'>
          <Input
            type={Input.TYPES.NUMBER}
            size={Input.SIZES.LARGE}
            min={0}
            placeholder='8'
            disabled={!openContract.editable && !isPreventedByPlugin('editMinutesOfWorkPerShift')}
            value={convertFormVal('number', 'minutesOfWorkPerShift', activeCon?.options?.minutesOfWorkPerShift)}
            label={t('CONTRACT_OPT_LABEL_minutesOfWorkPerShift')}
            onChange={(val) => onFieldChange(
              'options',
              {
                ...activeCon.options,
                minutesOfWorkPerShift: convertFormVal('number', 'minutesOfWorkPerShift', Math.abs(val), true)
              }
            )}
          />
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
          <Text
            type={Text.TYPES.BODY_MEDIUM}
            weight={Text.WEIGHTS.BOLD}
            style={{ paddingTop: '1.1rem' }}
            color={Text.COLORS.PRIMARY}
            text={t('HOUR_SHORTEST')}
          />
        </Flex>
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Input
          size={Input.SIZES.FULL_WIDTH}
          type='date'
          disabled={!openContract.editable}
          value={activeCon?.period?.start ? moment(activeCon.period.start).format('YYYY-MM-DD') : null}
          label={t('BATCH_EMPS_CONTRACT_START')}
          onChange={(val) => {
            onFieldChange('period', {
              start: val || null,
              end: activeCon?.period?.end ? (moment(activeCon.period.end).isBefore(val) ? null : activeCon.period.end) : null
            })
          }}
        />
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        <Input
          size={Input.SIZES.FULL_WIDTH}
          type='date'
          disabled={!openContract.editable}
          min={activeCon?.period?.start ? moment(activeCon.period.start).format('YYYY-MM-DD') : null}
          value={activeCon?.period?.end ? moment(activeCon.period.end).format('YYYY-MM-DD') : null}
          label={t('BATCH_EMPS_CONTRACT_END')}
          onChange={(val) => onFieldChange('period', {
            start: activeCon?.period?.start ? activeCon.period.start : null,
            end: val || null
          })}
        />
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <div style={{ width: '50%' }}>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            value={activeCon?.contractId}
            disabled={!openContract.editable}
            label={t('CONTRACT_OPT_LABEL_contractId')}
            placeholder={t('CONTRACT_OPT_LABEL_contractId_PLACEHOLDER')}
            onChange={(val) => onFieldChange('contractId', val)}
          />
        </div>
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        {currentContractType?.options && Object.entries(currentContractType.options).map(([key, currentOption]) => {
          // skip some keys
          if ([
            'doesNotWorkOnDaysOfWeek', 'hourlySalary',
            'withoutActiveContract', 'minutesOfWorkPerMonth'
          ].includes(key)) return null
          // display the form element depending on the contract option type
          const type = currentOption.type
          const typeToInputType = { number: 'number', string: 'text' }
          if (type === 'number' || (type === 'string' && !currentOption.enum)) {
            return (
              <div style={halfWidth} key={key}>
                <Input
                  type={typeToInputType[type]}
                  value={convertFormVal(type, key, activeCon.options && activeCon.options[key])}
                  label={t('BATCH_EMPS_CONTRACT_OPERATION')}
                  disabled={!openContract.editable}
                  onChange={(val) => onFieldChange(
                    'options',
                    {
                      ...activeCon.options,
                      [key]: convertFormVal(type, key, val, true)
                    }
                  )}
                />
              </div>
            )
          }
          if (type === 'string' && currentOption.enum) {
            const enumOptions = currentOption.enum.map((item) => ({
              label: t('CONTRACT_OPT_SELECTOPT_' + item),
              value: item
            }))
            return (
              <div style={halfWidth} key={key}>
                <Dropdown
                  type={Dropdown.TYPES.VARIABLE}
                  size={Dropdown.SIZES.FULL_WIDTH}
                  style={Dropdown.STYLES.LIGHT}
                  singleSelect
                  value={enumOptions.find((op) => op.value === convertFormVal(type, key, activeCon.options && activeCon.options[key]))}
                  onChange={(val) => handleOptionsChange(val.value, type, key)}
                  label={t('BATCH_EMPS_CONTRACT_OPERATION')}
                  disabled={!openContract.editable}
                  options={enumOptions}
                />
              </div>
            )
          }
          return null
        })}
      </Flex>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_14} />
      {positionsAndLocalities}
      <Spacing size={Spacing.SIZES.SIZE_32} />
    </>
  )

  const handleLocalitySet = (selectedOptions) => {
    const valArr = selectedOptions.map((option) => option.value)
    setValue({
      localities: localitiesOnWS.filter((pos) => valArr.includes(pos.id))
    })
  }
  const positionOptions = positionsOnWs
    .filter((pos) => !pos.archived)
    .map((pos) => ({
      label: pos.name,
      value: pos.id
    }))
  const localityOptions = localitiesOnWS
    .map((loc) => ({
      label: loc.name,
      value: loc.id
    }))
  const positionsAndLocalities = (
    <Flex>
      <Dropdown
        style={Dropdown.STYLES.LIGHT}
        label={t('POSITIONS')}
        size={Dropdown.SIZES.EXTRA}
        type={Dropdown.TYPES.VARIABLE}
        options={positionOptions}
        icon={<Icon ico='search' />}
        value={positions.map((elem) => ({
          label: elem.name,
          value: elem.id
        }))}
        placeholder={t('SEARCH')}
        searchable
        disabled={!openContract?.editable && !isPreventedByPlugin('editOnlyEmployeeContractPosition')}
        onChange={(val) => {
          const newPositions = JSON.parse(JSON.stringify(activeCon.positions || [])) // start with current activeCon.positions
            .filter(p => val.some(v => v.value === p.positionId)) // filter out those positions that aren't selected in the dropdown
            .concat(val.filter(v => !(activeCon.positions || []).some(p => p.positionId === v.value)).map(v => { return { positionId: v.value, skill: null, productionForecats: null } })) // and add all the positions that are selected in the dropdown, but aren't in activeCon.positions
          onFieldChange(
            'positions',
            newPositions
          )
        }}
      />
      <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />

      {isPluginEnabled('localities') && (
        <Dropdown
          style={Dropdown.STYLES.LIGHT}
          label={t('LOCALITY')}
          size={Dropdown.SIZES.EXTRA}
          type={Dropdown.TYPES.VARIABLE}
          options={localityOptions}
          value={localities.map((elem) => ({
            label: elem.name,
            value: elem.id
          }))}
          icon={<Icon ico='search' />}
          placeholder={t('SEARCH')}
          searchable
          onChange={handleLocalitySet}
        />
      )}

    </Flex>
  )

  const closeBtn = (
    <Flex>
      {openContract?.id === 'new' && (
        <>
          <Button
            style={Button.STYLES.UNDERLINED}
            color={Button.COLORS.RED}
            ico={Icon.ICONS.delete}
            onClick={() => deleteNewContract()}
            label={t('DELETE')}
          />
          <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        </>
      )}
      <Button
        style={Button.STYLES.UNDERLINED}
        ico={Icon.ICONS.close1}
        onClick={() => setOpenContract(null)}
        label={t('CLOSE')}
      />
    </Flex>
  )

  const sortInactiveContracts = contracts && [...contracts]
    .sort((con1, con2) => {
      if (con1.id === 'new') return -1
      return moment(con1?.period?.start).isBefore(con2?.period?.start)
        ? -1
        : 1
    })
  return (
    <>
      {sortInactiveContracts.map((contract, i) => {
        const contractType = contractTypesOnWS.find((wsConType) => wsConType.id === contract.type)
        const isInactive = moment(contract?.period?.end).isValid() && moment(contract?.period?.end).isBefore(moment().startOf('day'))
        return (
          <Fragment key={contract.id}>
            <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.CENTER}>
              <Flex align={Flex.POSITION.CENTER}>
                <CircledNumber number={i + 1} />
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Text
                  color={Text.COLORS.PRIMARY}
                  type={Text.TYPES.BODY_LARGE}
                  weight={Text.WEIGHTS.BOLD}
                >
                  {contractType?.name || t('NAME_V2')}
                  {(typeof contract?.options?.minutesOfWorkPerMonth === 'number' && contract?.options?.minutesOfWorkPerMonth !== null) && ' (' +
                  formatHours(convertFormVal('number', 'minutesOfWorkPerMonth', contract?.options?.minutesOfWorkPerMonth)) +
                  ')'}
                </Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Text
                  type={Text.TYPES.BODY_LARGE}
                  weight={Text.WEIGHTS.BOLD}
                >
                  {contract?.period?.start ? moment(contract.period.start).format('D.M.YYYY') + ' ' : t('EMPLOYEE_PROFILE_CONT_DATE_UNDEFINED') + ' '}
                  -
                  {contract?.period?.end ? (' ' + moment(contract.period.end).format('D.M.YYYY')) : (' ' + t('EMPLOYEE_PROFILE_CONT_DATE_UNDEFINED'))}
                  {isInactive && ` (${t('EMPLOYEE_PROFILE_CONT_INACTIVE')})`}
                </Text>
              </Flex>
              {openContract?.id === contract.id ? closeBtn : hasWritePermission && !isPreventedByPlugin('editEmployeeContracts') && !isInactive ? (
                <Button
                  style={Button.STYLES.UNDERLINED}
                  ico={Icon.ICONS.edit}
                  onClick={() => setOpenContract({ id: contract.id, editable: true })}
                  label={t('EDIT')}
                />
              ) : (
                <Button
                  style={Button.STYLES.UNDERLINED}
                  ico={Icon.ICONS.view}
                  onClick={() => setOpenContract({ id: contract.id, editable: false })}
                  label={t('VIEW')}
                />
              )}
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_14} />
            {openContract?.id === contract.id && form()}
          </Fragment>
        )
      })}
      {openContract?.id !== 'new' && hasWritePermission && !isPreventedByPlugin('editEmployeeContracts') && (
        <Button
          style={Button.STYLES.UNDERLINED}
          ico={Icon.ICONS.plus}
          label={t('EMPLOYEE_PROFILE_CONT_ADD')}
          onClick={() => {
            if (!contracts.some((att) => att?.id === 'new')) {
              createNewContract()
            } else {
              setOpenContract({ id: 'new', editable: true })
            }
          }}
        />
      )}
    </>
  )
}

Contracts.propTypes = {}
Contracts.defaultProps = {}
