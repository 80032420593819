import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'array-flat-polyfill'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import ErrorBoundary from '@core/error-boundary'
import './style/index.scss'
import store from './store'
import debugUtil from './util/debug'
import App from './dayswaps'
import './favicons'
import packageInfo from '../package.json'
import { client } from '@app/util/apollo/apollo-client'
import { ChatClientProvider } from './util/hooks/useChatClient'

if (window.location.hostname !== 'localhost' && window.location.hostname !== 'app.local.dayswaps.top') {
  Sentry.init({
    dsn: 'https://d621519933a348b9923bcc10fc9781a1@o1306974.ingest.sentry.io/6550277',
    ignoreErrors: [
      // the following errors are reported by Sentry, but they're either out of our hands, or not really errors, so we ignore them
      'Error: Failed to fetch',
      'TypeError: Load failed',
      'TypeError: NetworkError when attempting to fetch resource.',
      'Error: NetworkError when attempting to fetch resource.',
      'Error: Non-Error exception captured with keys: message',
      'captureException: Non-Error exception captured with keys: message',
      'captureException: Object captured as exception with keys: message',
      'Error: collection.get no opts',
      'Invariant Violation: 18 (see https://github.com/apollographql/invariant-packages)' // violation 18 means 'Store reset while query was in flight (not completed in link chain)', which only happens if user logs out while some request is running. not important.
    ],

    release: packageInfo.version,

    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false
      })
    ],

    // ignore 401 errors (expired auth tokens)
    beforeSend: (event, hint) => {
      if (hint.originalException) {
        if (hint.originalException.status === 401) {
          return null
        }
      }
      return event // otherwise, let errors be sent to Sentry
    },

    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.2
  })
}

const fav16 = document.getElementById('fav16')
const fav32 = document.getElementById('fav32')
let location = window.location.host.split('.')[1]
if (location === 'local' || !location) location = 'dev'

if (location) {
  fav16.href = `/favicons/fav-${location}-16.png`
  fav32.href = `/favicons/fav-${location}-32.png`
}

const container = document.getElementById('ds-root')
const root = createRoot(container)
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ChatClientProvider>
          <App />
        </ChatClientProvider>
      </ApolloProvider>
    </Provider>
  </ErrorBoundary>
)

window.ds = debugUtil
