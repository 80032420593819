import aT from '../const/action-type'
import initial from '../store/initial'

export default (state = initial.employeeDetail, action) => {
  switch (action.type) {
    case aT.SET_EMPLOYEE_DETAIL:
      return action.data
    case aT.RESET_STORE:
      return initial.employeeDetail
    case aT.SWITCH_WORKSPACE:
      return initial.employeeDetail
    default:
      return state
  }
}
