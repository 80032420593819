import React from 'react'
import PropTypes from 'prop-types'

import {
  HelpTooltip,
  Separator,
  Spacing,
  Text,
  Flex,
  Icon,
  Button
} from '@ui'

export function Layout ({
  title,
  helpText,
  actionIcon,
  onClose,
  children,
  primaryButton,
  secondaryButton
}) {
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_20} />
      <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_16}>
        <Flex justify='space-between' align='center'>
          <Flex align='center'>
            <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
              {title}
            </Text>
            <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
            {helpText && <HelpTooltip text={helpText} />}
          </Flex>
          <Flex align='center'>
            {actionIcon && (
              <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8}>
                {actionIcon}
              </Spacing>
            )}
            <Icon
              ico={Icon.ICONS.close1}
              color={Icon.COLORS.PRIMARY}
              size={Icon.SIZES.SMALL}
              onClick={onClose}
            />
          </Flex>
        </Flex>
      </Spacing>
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16} />
      <Separator />
      <div style={{ flexGrow: '1', overflowY: 'auto' }}>{children}</div>
      <Separator />
      <Spacing type={Spacing.TYPES.BOTH} size={Spacing.SIZES.SIZE_16}>
        <Flex justify='space-between' align='center'>
          <Button
            label={secondaryButton.label}
            onClick={secondaryButton.handleClick}
            size={Button.SIZES.LARGE}
          />
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_6} />
          <Button
            label={primaryButton.label}
            onClick={primaryButton.handleClick}
            size={Button.SIZES.LARGE}
            style={Button.STYLES.CONTAINED}
          />
        </Flex>
      </Spacing>
    </div>
  )
}

const buttonPropType = PropTypes.shape({
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
})

Layout.propTypes = {
  title: PropTypes.string,
  helpText: PropTypes.string,
  actionIcon: PropTypes.element,
  onClose: PropTypes.func,
  children: PropTypes.element,
  primaryButton: buttonPropType.isRequired,
  secondaryButton: buttonPropType.isRequired
}
