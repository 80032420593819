import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import LoaderSVG from './loader.svg'
import { Icon } from '@ui'

import './loader.scss'
import { t } from 'i18next'

const SIZES = Object.freeze({
  LARGE: 'large',
  SMALL: 'small',
  TINY: 'tiny'
})

const TYPES = Object.freeze({
  DEFAULT: 'default',
  OVERLAY: 'overlay'
})

const POSITIONS = Object.freeze({
  STATIC: 'static',
  ABSOLUTE: 'absolute'
})

export const Loader = ({
  size,
  type,
  isLoading,
  text,
  children
}) => {
  return (
    <div
      className={classNames(
        'ds-loader',
        size,
        type,
        {
          'is-loading': isLoading
        }
      )}
    >
      {children && children}
      <div className='loading-container'>
        {isLoading && type === TYPES.OVERLAY && <div className='overlay-layer' />}
        {type === TYPES.DEFAULT && (
          <>
            {size === SIZES.TINY
              ? <Icon ico={Icon.ICONS.loader} />
              : <LoaderSVG />}

            {![SIZES.SMALL, SIZES.TINY].includes(size) && (
              <div className='loading-text'>
                {text || t('LOADING_YOUR_DATA')}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  type: PropTypes.oneOf(Object.values(TYPES)),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(POSITIONS)),
  text: PropTypes.string
}

Loader.defaultProps = {
  size: SIZES.LARGE,
  type: TYPES.DEFAULT,
  position: POSITIONS.ABSOLUTE,
  children: null,
  isLoading: false,
  text: ''
}

Loader.SIZES = SIZES
Loader.TYPES = TYPES
Loader.POSITIONS = POSITIONS
