import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Avatar,
  Separator,
  Text,
  Tooltip,
  Link,
  Flex,
  Spacing
} from '@ui'

export const Me = ({
  me,
  isOrg,
  logOut,
  openEmployeeCard,
  isLoading
}) => {
  const { email, name } = me
  const [closeCount, setCloseCount] = useState(0)
  return (
    <Tooltip
      position={Tooltip.POSITIONS.RIGHT_BOTTOM}
      clickable
      closeDetection={closeCount}
      anchor={<Avatar profile={me} />}
    >
      <>
        <Spacing size={Spacing.SIZES.SIZE_20} />
        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}>
          <Flex align={Flex.POSITION.CENTER}>
            <Avatar profile={me} />
            <Spacing size={Spacing.SIZES.SIZE_12} type={Spacing.TYPES.HORIZONTAL} />
            <Flex direction={Flex.DIRECTION.COLUMN}>
              <Text
                text={name}
                type={Text.TYPES.BODY_LARGE}
                weight={Text.WEIGHTS.BOLD}
                color={Text.COLORS.PRIMARY}
              />
              <Text
                text={email}
                type={Text.TYPES.BODY_MEDIUM}
                color={Text.COLORS.GRAY}
                weight={Text.WEIGHTS.SEMI_MEDIUM}
                ellipsis
              />
            </Flex>
          </Flex>
        </Spacing>
        <Spacing size={Spacing.SIZES.SIZE_16}>
          <Separator />
        </Spacing>

        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}>
          <Link
            type={Link.TYPES.ON_CLICK}
            onClick={() => {
              openEmployeeCard()
              setCloseCount(closeCount + 1)
            }}
          >
            <Text text={t('SETTINGS_NT')} type={Text.TYPES.BODY_LARGE} />
          </Link>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Link
            type={Link.TYPES.ROUTER_LINK}
            to='/my-notification'
          >
            <Text text={t('NOTIFICATION_SETT_TITLE')} type={Text.TYPES.BODY_LARGE} />
          </Link>
        </Spacing>

        <Spacing size={Spacing.SIZES.SIZE_16}>
          <Separator />
        </Spacing>

        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}>
          <Flex direction={Flex.DIRECTION.COLUMN}>
            <Link
              type={Link.TYPES.A_TAG}
              to={t('LANDING_CONDITIONS_URL')}
            >
              <Text text={t('LEGAL')} type={Text.TYPES.BODY_LARGE} />
            </Link>
            <Spacing size={Spacing.SIZES.SIZE_10} />
            <Link
              type={Link.TYPES.A_TAG}
              to={t('LANDING_PRIVACY_URL')}
            >
              <Text text={t('GDPR')} type={Text.TYPES.BODY_LARGE} />
            </Link>
          </Flex>
        </Spacing>
        <Spacing size={Spacing.SIZES.SIZE_16}>
          <Separator />
        </Spacing>
        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}>
          {!isLoading
            ? (
              <div onClick={() => logOut()} style={{ cursor: 'pointer' }}>
                <Text text={t('LOGOUT')} type={Text.TYPES.BODY_LARGE} />
              </div>
            )
            : (<Text text={t('LOADING')} type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.GRAY} />)}
        </Spacing>
        <Spacing size={Spacing.SIZES.SIZE_20} />
      </>
    </Tooltip>
  )
}
