import React, { Fragment, useState } from 'react'
import { t } from 'i18next'

import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Flex,
  Icon,
  Link,
  Locality,
  Position,
  Separator,
  Spacing,
  Table,
  Text
} from '@ui'

import connect from './connect'
import './style.scss'

export const Cycles = connect((
  {
    cycles,
    employees,
    localities,
    positions,
    workspace,
    selectedCycles,
    isInvalidEnd,
    isWithoutEmp,
    cb,
    fromUnassigned,
    isFromUnassigned
  }) => {
  const [filter, setFilter] = useState([])
  const ee = Object.keys(employees)
    .map((id) => employees[id])
  const empOptions = ee
    .filter((emp) => emp.cycleId === null)
    .map((emp) => ({
      label: emp.name,
      value: emp.id
    }))
  const positionOptions = [...positions
    .filter((pos) => !pos.archived)
    .map((pos) => ({
      label: <Position sm {...pos} />,
      value: pos.id
    })),
  ...[{ label: t('PLAN_CYCLES_TABLE_DROPDOWN'), value: null }]
  ]
  const localityOptions = [
    ...localities
      .map((loc) => ({
        label: <Locality {...loc} />,
        value: loc.id
      })),
    ...[{ label: t('PLAN_CYCLES_TABLE_DROPDOWN'), value: null }]
  ]

  const cyclesOptions = cycles.map(c => { return { value: c.id, label: `${c.title} (${t('X_EMPLOYEES', { x: ee.filter(emp => emp.cycleId === c.id).length })}, ${t('X_SHIFTS', { x: c.shifts.length })})` } })
  const getTableDropdownValue = (row, type) => {
    const cycle = selectedCycles.find(c => c.id === row.cycleId)
    const data = cycle?.overrides?.find(o => o.userId === row.id) ?? null
    if (type === 'pos' && data?.position) {
      return positionOptions?.find(opt => opt.value === data.position) ?? []
    }
    if (type === 'loc' && data?.locality) {
      return localityOptions?.find(opt => opt.value === data.locality) ?? []
    }
    return []
  }

  const getFilteredEmpOptions = (cycleId) => {
    const cycle = selectedCycles.find(c => c.id === cycleId)
    return empOptions.filter(emp => {
      if (!cycle.assigns.find(ass => ass.id === emp.value)) {
        return emp
      }
    })
  }

  const handleChangeTableDropdown = (obj, row, type) => {
    const cycles = [...selectedCycles]
    const cycle = cycles.find(c => c.id === row.cycleId)
    let user = cycle?.overrides?.find(o => row.id === o.userId)
    if (user) {
      const filteredOverrides = cycle?.overrides.filter(o => o.userId !== user.userId)
      cycle.overrides = filteredOverrides
      if (type === 'pos') {
        user.position = obj.value
      } else {
        user.locality = obj.value
      }
      user.cycleId = row.cycleId
      if (user.position || user.locality) filteredOverrides.push(user)
      cb(cycles)
    } else {
      user = {
        userId: row.id,
        cycleId: row.cycleId
      }
      if (type === 'pos') {
        user.position = obj.value
        user.locality = null
      } else {
        user.locality = obj.value
        user.position = null
      }
      const data = [...cycle.overrides]
      if (obj.value) data.push(user)
      cycle.overrides = data
      cb(cycles)
    }
  }

  const handleDeleteEmp = (row) => {
    const cycles = [...selectedCycles]
    const cycle = cycles.find(c => c.id === row.cycleId)
    const usersAss = cycle?.assigns?.filter(o => row.id !== o.id)
    const usersOverrides = cycle?.overrides.filter(o => o.userId !== row.id)
    if (usersAss && usersOverrides) {
      cycle.overrides = usersOverrides
      cycle.assigns = usersAss
      cb(cycles)
    }
  }

  const handleChangeEmp = (row, emp) => {
    const cycles = [...selectedCycles]
    const employees = [...ee]
    const cycle = cycles.find(c => c.id === row.cycleId)
    const usersAss = cycle?.assigns?.filter(o => row.id !== o.id)
    const selectedEmp = { ...employees.find(e => e.id === emp.value) }
    selectedEmp.cycleGroup = row.cycleGroup
    selectedEmp.cycleId = row.cycleId
    selectedEmp.isNew = true
    usersAss.push(
      selectedEmp
    )
    cycle.assigns = usersAss
    cb(cycles)
  }
  const handleAddEmp = (cycleId, cycleGroup) => {
    const cycles = [...selectedCycles]
    const cycle = cycles.find(c => c.id === cycleId)
    cycle.assigns.push({
      id: `new_${cycleId}_${cycleGroup}_${cycle.assigns.length}`,
      cycleId: cycleId,
      cycleGroup: cycleGroup
    })
    cb(cycles)
  }

  const tableConfig = [
    {
      key: 'name',
      tlKey: 'EMPLOYEE',
      sortable: true,
      filterable: true,
      rowToHTML: (row) => {
        if (row.name) {
          return row.name
        } else {
          return (
            <Dropdown
              style={Dropdown.STYLES.LIGHT}
              size={Dropdown.SIZES.LARGE}
              type={Dropdown.TYPES.VARIABLE}
              options={row?.cycleId ? getFilteredEmpOptions(row?.cycleId) : []}
              singleSelect
              value={[]}
              placeholder={t('ADD')}
              icon={<Icon ico={Icon.ICONS.plus} color={Icon.COLORS.PRIMARY} />}
              searchable
              onChange={(emp) => handleChangeEmp(row, emp)}
            />
          )
        }
      },
      rowToStr: (row) => {
        return row.name
      }
    },
    {
      key: 'positions',
      tlKey: 'POSITIONS',
      sortable: true,
      filterable: true,
      rowToHTML: (row) =>
        <Dropdown
          style={Dropdown.STYLES.LIGHT}
          size={Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          options={positionOptions}
          singleSelect
          value={getTableDropdownValue(row, 'pos')}
          placeholder={t('PLAN_CYCLES_TABLE_DROPDOWN')}
          searchable
          disabled={!row.name}
          onChange={(position) => handleChangeTableDropdown(position, row, 'pos')}
        />,
      rowToStr: () =>
        positions ? positions.reduce((a, s) => { return (a + ' ' + s.name.toLowerCase()) }, '') : ''
    },
    {
      key: 'localities',
      tlKey: 'LOCALITIES',
      sortable: true,
      filterable: true,
      rowToHTML: (row) =>
        <Dropdown
          style={Dropdown.STYLES.LIGHT}
          size={Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          options={localityOptions}
          singleSelect
          value={getTableDropdownValue(row, 'loc')}
          placeholder={t('PLAN_CYCLES_TABLE_DROPDOWN')}
          searchable
          disabled={!row.name}
          onChange={(locality) => handleChangeTableDropdown(locality, row, 'loc')}
        />,
      rowToStr: () =>
        localities
          .map((locId) => workspace.localities.find((loc) => loc.id === locId))
          .filter((loc) => !!loc)
          .reduce((a, s) => a + ' ' + s.name.toLowerCase(), '')
    },
    {
      key: 'menu',
      sortable: false,
      rowToHTML: (row) =>
        <Button
          color={Button.COLORS.RED}
          label={t('DELETE')}
          onClick={() => handleDeleteEmp(row)}
          ico={Icon.ICONS.delete}
        />
    }
  ].filter((config) => !!config)

  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Flex align={Flex.POSITION.CENTER}>
        <Icon ico={Icon.ICONS.cycle} color={Icon.COLORS.PRIMARY} size={Icon.SIZES.SMALL} />
        <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_8} />
        <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>{t('CYCLES')}</Text>
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_10} />
      <Dropdown
        size={Dropdown.SIZES.EXTRA}
        options={cyclesOptions}
        searchable
        value={filter}
        label={t('PLAN_SETUP_MODAL_CYCLES')}
        placeholder={t('CHOOSE')}
        type={Dropdown.TYPES.VARIABLE}
        icon={<Icon ico={Icon.ICONS.search} />}
        onChange={(val) => {
          const cycleData = ee.length > 0 ? val.map(v => {
            return {
              ...cycles.find(c => c.id === v.value),
              assigns: ee.filter((e) => e.cycleId === v.value),
              overrides: []
            }
          }) : []
          cb(cycleData)
          setFilter(val)
        }}
      />
      <Spacing size={Spacing.SIZES.SIZE_14} />
      <Checkbox
        checked={isFromUnassigned}
        onChange={(ch) => {
          fromUnassigned(ch)
        }}
      >
        {t('PLAN_CYCLES_FROM_UNASSIGNED_SHIFTS')}
      </Checkbox>
      {!cycles.length && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_10} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_WARNING_CYCLES')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>)}
      {selectedCycles.length > 0 && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_28} />
          <Separator />
          <Spacing size={Spacing.SIZES.SIZE_28} />
          {isInvalidEnd && (
            <>
              <Alert
                variant={Alert.VARIANTS.SLIM}
                text={t('PLAN_SELECT_END_DATE')}
                type={Alert.TYPES.ERROR}
                size={Alert.SIZES.FULL_WIDTH}
              />
              <Spacing size={Spacing.SIZES.SIZE_10} />
            </>)}
          {isWithoutEmp.length > 0 && (
            <>
              <Alert
                variant={Alert.VARIANTS.SLIM}
                text={t('PLAN_SETUP_MODAL_ERROR_CYCLES', { interpolation: { escapeValue: false }, x: isWithoutEmp.join(', ') })}
                type={Alert.TYPES.ERROR}
                size={Alert.SIZES.FULL_WIDTH}
              />
              <Spacing size={Spacing.SIZES.SIZE_10} />
            </>)}
          <Alert
            variant={Alert.VARIANTS.SLIM}
            text={t('PLAN_SETUP_MODAL_INFO_CYCLES')}
            type={Alert.TYPES.INFO}
            size={Alert.SIZES.FULL_WIDTH}
            customIco={Icon.ICONS.tip}
          />
          {selectedCycles.map((cycle) => {
            const cycleGroups = [...new Set(cycle?.shifts.map(s => s.group))]
            return (
              <Fragment key={cycle}>
                <Spacing size={Spacing.SIZES.SIZE_18} />
                <Collapse
                  key={cycle}
                  label={`${cycle.title} (${t('X_EMPLOYEES', { x: ee.filter(emp => emp.cycleId === cycle.id).length })}, ${t('X_SHIFTS', { x: cycle.shifts.length })})`}
                >
                  {cycleGroups.map((group, idx) => {
                    const eeInGroup = cycle?.assigns?.filter(ass => ass.cycleGroup === group) ?? []
                    const groupName = cycle?.groups?.find(gp => gp.group === group)?.name ?? []
                    return (
                      <Fragment key={group}>
                        <Spacing size={Spacing.SIZES.SIZE_20} />
                        <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{groupName.includes(t('CYC_GROUPS')) ? t('CYC_GROUPS', { x: idx + 1 }) : groupName}</Text>
                        <Spacing size={Spacing.SIZES.SIZE_14} />
                        {eeInGroup.length === 0 && (
                          <Alert
                            variant={Alert.VARIANTS.SLIM}
                            text={t('PLAN_SETUP_MODAL_WARNING_CYCLES')}
                            type={Alert.TYPES.WARNING}
                            size={Alert.SIZES.FULL_WIDTH}
                            customIco={Icon.ICONS.warning}
                          />
                        )}
                        <Table
                          className='ds-planning-table'
                          type={Table.TYPES.LEAN}
                          tableName='planningCyclesTable'
                          showSelection={false}
                          tableConfig={tableConfig}
                          rows={eeInGroup}
                          showActionBar={false}
                          size={Table.SIZE.SLIM}
                          pagination={false}
                        />
                        <Spacing size={Spacing.SIZES.SIZE_14} />
                        <Flex>
                          <Link
                            onClick={() => handleAddEmp(cycle.id, idx)}
                            type={Link.TYPES.ON_CLICK}
                          >
                            <Flex align={Flex.POSITION.CENTER}>
                              <Icon ico={Icon.ICONS.plus} color={Icon.COLORS.PRIMARY} />
                              <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                              <Text
                                type={Text.TYPES.BODY_MEDIUM}
                                weight={Text.WEIGHTS.BOLD}
                                color={Icon.COLORS.PRIMARY}
                                style={{ textDecoration: 'underline' }}
                                text={t('CYC_GROUPS_ADD_NEXT_EMP')}
                              />
                            </Flex>
                          </Link>
                        </Flex>
                      </Fragment>
                    )
                  })}
                </Collapse>
              </Fragment>
            )
          })}
        </>
      )}
    </Flex>
  )
})
