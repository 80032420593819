import React from 'react'
import { t } from 'i18next'
import moment from 'moment'
import ActionsOnShifts from '@app/component/modals/confirm/actions-on-shifts'
import {
  Spacing,
  Flex,
  Text
} from '@ui'

export const terminateUserModal = ({
  shiftsAfterDate,
  employeeName,
  immediately,
  deleteRoleDate,
  performTerminate,
  setModal
}) => {
  const shiftsActionFnRef = React.createRef()
  setModal('confirm', {
    title: t('BATCH_EMPS_CONTRACT_DELETE'),
    subtitle: t('CONFIRM_DELETE_ROLE_SUBTITLE'),
    recordsList: shiftsAfterDate,
    text: (
      <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_16}>
        <Flex>
          <Text>{t('EMPLOYEE')}:</Text>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
          <Text>{employeeName}</Text>
        </Flex>
        <Flex>
          <Text>{t('EMPLOYEE_PROFILE_ROLE_END_DATE')}:</Text>
          <Spacing type={Spacing.TYPES.HORIZONTAL} size={Spacing.SIZES.SIZE_4} />
          <Text>
            {(immediately ? moment() : moment(deleteRoleDate)).format('D.M.YYYY')}
          </Text>
        </Flex>
        {shiftsAfterDate.length
          ? (
            <ActionsOnShifts
              labelTxt={t('CONFIRM_DELETE_ROLE_RELATED_SHIFTS_X', { x: shiftsAfterDate.length })}
              shifts={shiftsAfterDate}
              shiftsActionFnRef={shiftsActionFnRef}
            />
          )
          : null}
      </Spacing>
    ),
    confirmLabel: t('DELETE'),
    onConfirm: () => {
      if (shiftsAfterDate.length && shiftsActionFnRef.current) {
        shiftsActionFnRef.current().then(() => {
          performTerminate(immediately)
        })
      } else {
        performTerminate(immediately)
      }
    },
    cancelLabel: t('CLOSE'),
    onCancel: () => {},
    overSidebar: true
  })
}
