import { fetchRequest, errorUtil } from '@app/util'

const query = `mutation UserAttributeCreate(
    $workspaceId: ID!,
    $name: String!,
    $shortName: String!,
    $type: CustomAttributeValueType!
      ) {
      userAttributeCreate(
        workspace: $workspaceId,
        name: $name,
        shortName: $shortName,
        type: $type
       ) {
    id
    name
    shortName
    type
  }
}`

async function createUserAttributes (variables, token) {
  const response = await fetchRequest({ query, variables, token })
  if (!response) return errorUtil.noResponse()

  const { errors, data } = response
  if (errors) {
    return errorUtil.apiError('', errors)
  }
  return data.userAttributeCreate
}

export default createUserAttributes
