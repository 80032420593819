import { toast } from 'react-hot-toast'
import { t } from 'i18next'

import {
  Alert,
  Link,
  NotificationCard
} from '@ui'
import { notification, routeUtil } from '@app/util/index'

const toastConfig = {
  duration: 5000
}

const error = ({ code, message }) => {
  if (code || message) {
    if (code && code !== 'INTERNAL_SERVER_ERROR') {
      message = 'ERR_MESSAGE.' + code
    }

    toast.custom(() => (
      <Alert
        type={Alert.TYPES.ERROR}
        text={t(message)}
      />
    ),
    toastConfig)
  }
}

const warn = ({ code, message, config }) => {
  if (code && code !== 'INTERNAL_SERVER_ERROR') {
    message = 'WARN_MESSAGE.' + code
  }

  return toast.custom(() => (
    <Alert
      type={Alert.TYPES.WARNING}
      text={t(message)}
    />
  ), {
    ...toastConfig,
    ...config
  })
}

const success = ({ code, message }) => {
  if (code) {
    message = 'SUCCESS_MESSAGE.' + code
  }

  toast.custom(() => (
    <Alert
      type={Alert.TYPES.SUCCESS}
      text={t(message)}
    />
  ),
  toastConfig)
}

const release = () => {
  toast.custom(() => (
    <Link
      type={Link.TYPES.ON_CLICK}
      onClick={() => window.location.reload(true)}
    >
      <NotificationCard
        type={NotificationCard.TYPES.POPUP}
        notification={{
          type: 'release',
          message: {
            title: t('RELOAD_NOTICE_1'),
            text: t('RELOAD_NOTICE_2')
          }
        }}
      />
    </Link>
  ),
  {
    id: 'release',
    duration: Infinity
  })
}

const classic = ({
  message,
  id
}) => {
  toast.custom(() => (
    <NotificationCard
      notification={{ message }}
      onOpen={() => {
        routeUtil.navigate(message.uri)
        notification.remove(id)
      }}
      type={NotificationCard.TYPES.POPUP}
      onClose={() => notification.remove(id)}
    />
  ),
  {
    duration: Infinity,
    id: id,
    typeIdentifier: 'classic'
  })
}

const remove = (id) => toast.remove(id)
export default {
  error,
  warn,
  success,
  release,
  remove,
  classic
}
