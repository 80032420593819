import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import loadChangedShiftsCount from '@app/ac/load-changed-shifts-count'
import updateWarnings from '@app/ac/update-warnings'
import loadEmployees from '@app/ac/load-employees'
import loadEmployeeDetail from '@app/ac/load-employee-detail'
import loadExternalEmployees from '@app/ac/load-external-employees'
import loadPositions from '@app/ac/load-positions'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import loadRoleStatsMulti from '@app/ac/load-role-stats-multi'
import setCalendarMultiSelect from '@app/ac/set-calendar-multi-select'
import setCalendar from '@app/ac/set-calendar'
import setSidebar from '@app/ac/set-sidebar'
import setShifts from '@app/action/set-shifts'
import setTimeOffs from '@app/action/set-time-offs'
import setAvailabilities from '@app/action/set-availabilities'
import setOffers from '@app/action/set-offers'
import setEmployees from '@app/action/set-employees'
import setPositions from '@app/action/set-positions'
import setWorkspaces from '@app/action/set-workspaces'
import setNotifications from '@app/action/set-notifications'
import { withPlugin } from '@core/hoc/withPlugin'

const mapStateToProps = ({ auth, workspaceId, organizationId, workspaces, me }) => {
  return {
    auth,
    workspaceId,
    organizationId,
    workspaces,
    me
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadChangedShiftsCount,
    loadEmployees,
    loadEmployeeDetail,
    loadExternalEmployees,
    loadPositions,
    loadWorkspaceDetail,
    loadRoleStatsMulti,
    setCalendarMultiSelect,
    setCalendar,
    setSidebar,
    setShifts,
    setTimeOffs,
    setAvailabilities,
    setOffers,
    setEmployees,
    setPositions,
    setWorkspaces,
    setNotifications,
    updateWarnings
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
