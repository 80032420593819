"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatClientProvider = exports.useChatClient = void 0;
const config_1 = require("@app/const/config");
const react_1 = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const stream_chat_1 = require("stream-chat");
const is_defined_1 = require("../is-defined");
const { location: { hostname } } = window;
const { [hostname]: { chatApiKey } } = config_1.apiEndpoints;
const ChatClientContext = (0, react_1.createContext)(undefined);
const useChatClient = () => {
    const context = (0, react_1.useContext)(ChatClientContext);
    if (!context) {
        throw new Error('useChatClient must be used within a ChatClientProvider');
    }
    return context.client;
};
exports.useChatClient = useChatClient;
const MAX_CONNECTION_ATTEMPTS = 15;
const ChatClientProvider = ({ children }) => {
    if (!chatApiKey)
        throw new Error('chatApiKey is not defined');
    const [chatClient, setChatClient] = react_1.default.useState(stream_chat_1.StreamChat.getInstance(chatApiKey));
    const [connectionAttempt, setConnectionAttempt] = react_1.default.useState(0);
    const me = (0, react_redux_1.useSelector)(state => state.me);
    const id = me === null || me === void 0 ? void 0 : me.id;
    const name = me === null || me === void 0 ? void 0 : me.name;
    const chatToken = me === null || me === void 0 ? void 0 : me.chatToken;
    react_1.default.useEffect(() => {
        if (!id || !chatToken || !chatApiKey)
            return;
        const client = stream_chat_1.StreamChat.getInstance(chatApiKey);
        let didUserConnectInterrupt = false;
        const connectionPromise = client.connectUser({ id, name }, chatToken).then(() => {
            if (!didUserConnectInterrupt)
                setChatClient(client);
        }).catch(err => {
            console.log('Error while connecting user', err);
            if (connectionAttempt <= MAX_CONNECTION_ATTEMPTS) {
                setConnectionAttempt(connectionAttempt + 1);
            }
        });
        return () => {
            const action = () => __awaiter(void 0, void 0, void 0, function* () {
                didUserConnectInterrupt = true;
                if ((0, is_defined_1.isUndefined)(connectionPromise))
                    return;
                try {
                    yield connectionPromise;
                }
                catch (err) {
                    console.log('Error while connecting user', err);
                }
                try {
                    yield client.disconnectUser();
                    console.log(`Connection for user "${id}" has been closed`);
                }
                catch (err) {
                    console.log('Error while disconnecting user', err);
                }
                setChatClient(undefined);
            });
            void action();
        };
    }, [id, name, chatToken, chatApiKey, connectionAttempt]);
    return react_1.default.createElement(ChatClientContext.Provider, { value: { client: chatClient } }, children);
};
exports.ChatClientProvider = ChatClientProvider;
