import React, { useState } from 'react'
import moment from 'moment'
import { Radio, RadioGroup } from 'react-radio-group'
import { t } from 'i18next'
import connect from '../connect'

import {
  requestShifts,
  requestRoleUpdate,
  requestRoleDelete
} from '@app/request'
import {
  Button,
  Dropdown,
  Flex,
  Input,
  Separator,
  Spacing,
  Text,
  Alert,
  Tooltip
} from '@ui'
import {
  notification,
  miscUtil
} from '@app/util'
import { terminateUserModal } from './terminateUserModal'
import { useFetch } from '@app/util/apollo'
import { AcRolesGet } from '@app/queries'

export const Other = connect(({
  workspaceId,
  auth,
  loadEmployeeDetail,
  employeeDetail,
  createContract,
  updateContract,
  loadExternalEmployees,
  userId,
  setEmployees,
  me,
  hasWritePermission,
  isPreventedByPlugin,
  isOwn,
  hidden,
  workspace,
  setAcRole,
  setForm,
  form,
  acRoles,
  setModal,
  employees
}) => {
  const [state, setState] = useState({
    saving: [],
    deleteRoleDate: moment().format('YYYY-MM-DD'),
    deleteRoleNow: false,
    closeTool: 0
  })

  const trueFalseOptions = [
    {
      label: t('YES'),
      value: true
    },
    {
      label: t('NO'),
      value: false
    }
  ]

  const getContracts = () => {
    const contracts = Array.isArray(employeeDetail?.options?.contracts)
      ? [...employeeDetail.options?.contracts].filter(Boolean)
      : []
    if (!contracts.length) {
      const contract = employeeDetail.options?.contract || {}
      contracts.push({ ...contract, isDefaultContract: true })
    }
    return contracts
  }

  const activeContracts = getContracts().filter((con) => {
    if (con.period && con.period.start && moment(con.period.start).isAfter(moment())) return false
    if (con.period && con.period.end && moment(con.period.end).isBefore(moment())) return false
    return true
  })

  // asynchronously perform the value save on BE (depending on the key), and
  // keep the key in state.saving array while it's being saved
  const saveValOther = async (key, val) => {
    const performSave = async (key, val) => {
      // terminate the user (set end of the role)
      if (key === 'terminateDate') {
        await requestRoleUpdate({
          workspace: workspaceId,
          user: employeeDetail.id,
          data: {
            terminateDate: val
          }
        }, auth)
        if (val) {
          for (let i = 0; i < activeContracts.length; i++) {
            const currentContract = activeContracts[i]
            if (currentContract.isDefaultContract) {
              await createContract(employeeDetail.id, {
                type: currentContract.type,
                period: {
                  start: (currentContract.period ? currentContract.period.start : null) || null,
                  end: moment(val) || null
                },
                options: currentContract.compiled.options
              })
            } else {
              await updateContract(employeeDetail.id, currentContract.id, {
                type: currentContract.type,
                period: {
                  start: (currentContract.period ? currentContract.period.start : null) || null,
                  end: moment(val)
                }
              })
            }
          }
        }
        await loadEmployeeDetail(employeeDetail.id, true)
      }

      // delete user's role immediately
      if (key === 'deleteRole') {
        const removeFromStore = () => {
          const newEmployees = { ...employees }
          newEmployees[userId] = {
            ...newEmployees[userId],
            external: true
          }
          setEmployees(newEmployees)
        }

        await requestRoleDelete({
          workspace: workspaceId,
          user: employeeDetail.id
        }, auth)

        removeFromStore()
      }
    }

    setState((prev) => ({ ...prev, saving: state.saving.concat([key]) }))
    await performSave(key, val)
    setState((prev) => ({ ...prev, saving: state.saving.filter(k => k !== key) }))
  }

  const terminateUser = async (immediately) => {
    const shifts = await requestShifts({
      user: userId,
      id: workspaceId,
      period: {
        start: moment(),
        end: moment().add(1, 'year')
      }
    }, auth)

    const performTerminate = (immediately) => {
      if (immediately || moment(state.deleteRoleDate).isBefore(moment().startOf('day'))) {
        saveValOther('deleteRole', null).then(() => setModal(null))
      } else {
        saveValOther('terminateDate', moment(state.deleteRoleDate))
      }
    }

    const shiftsAfterDate = shifts.filter((s) => moment(s.period.start).isAfter(moment(state.deleteRoleDate).endOf('day')))
    terminateUserModal({
      shiftsAfterDate,
      employeeName: employeeDetail.name,
      immediately,
      deleteRoleDate: state.deleteRoleDate,
      performTerminate,
      setModal
    })
  }

  const contractsDeactivationDates = []
  if (activeContracts.length) {
    activeContracts.forEach(con => {
      if (con.period && con.period.end) {
        if (!contractsDeactivationDates.includes(moment(con.period.end).format('YYYY-MM-DD'))) {
          contractsDeactivationDates.push(moment(con.period.end).format('YYYY-MM-DD'))
        }
      }
    })
  }

  const { data } = useFetch(
    AcRolesGet,
    {
      variables: {
        workspaceId: workspace.id
      }
    })
  const wsAccessRoles = data?.workspace?.accessRoles
  const accessRolesOptions = wsAccessRoles?.map((acRole) => ({
    value: acRole.id,
    label: acRole.name
  })) || []
  const acRoleDropdownValue = accessRolesOptions
    ?.filter((option) => acRoles?.some((acRoles) => acRoles.id === option.value))

  let hasDefaultAccessRole = false
  if (acRoles?.some(ar => ar.workspaceId === workspaceId && wsAccessRoles?.some(wsAr => wsAr.isDefaultAccessRoleEmployee && wsAr.id === ar.id))) hasDefaultAccessRole = 'EMPLOYEE'
  if (acRoles?.some(ar => ar.workspaceId === workspaceId && wsAccessRoles?.some(wsAr => wsAr.isDefaultAccessRoleManager && wsAr.id === ar.id))) hasDefaultAccessRole = 'MANAGER'

  return (
    <>
      {hasWritePermission && (
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
            {t('PRIVILEGES')}
          </Text>
          <Spacing size={Spacing.SIZES.SIZE_2} />
          <Flex>
            <Flex flex='3' align={Flex.POSITION.CENTER}>
              {hasDefaultAccessRole ? (
                <Text type={Text.TYPES.BODY_MEDIUM}>
                  {t('EMPLOYEE_PROFILE_PRIVILEGES_' + hasDefaultAccessRole)}
                </Text>
              ) : null}
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_40} type={Spacing.TYPES.HORIZONTAL} />
            <Flex flex='1'>
              <Dropdown
                size={Dropdown.SIZES.LARGE}
                style={Dropdown.STYLES.LIGHT}
                type={Dropdown.TYPES.VARIABLE}
                value={acRoleDropdownValue}
                singleSelect
                onChange={(val) => { setAcRole([wsAccessRoles.find((wsAcRole) => val.value === wsAcRole.id)].filter(Boolean)) }}
                disabled={me.id === employeeDetail.id || isPreventedByPlugin('editEmployeeRoleType')}
                placeholder={t('PRIVILEGES')}
                options={accessRolesOptions}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      {hasWritePermission && (
        <Flex direction={Flex.DIRECTION.COLUMN}>
          {me.id !== employeeDetail.id
            ? (
              <>
                <Spacing size={Spacing.SIZES.SIZE_14} type={Spacing.TYPES.VERTICAL} />
                <Flex direction={Flex.DIRECTION.COLUMN}>
                  <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
                    {t('EMPLOYEE_PROFILE_ROLE_END_LABEL')}
                  </Text>
                  <Spacing size={Spacing.SIZES.SIZE_2} />
                  <Flex>
                    <Flex flex='3' align={Flex.POSITION.CENTER}>
                      {employeeDetail.terminateDate ? (
                        <Text type={Text.TYPES.BODY_MEDIUM}>
                          {t('EMPLOYEE_PROFILE_ROLE_WILL_END_DATE', { x: moment(employeeDetail.terminateDate).format('D.M.YYYY') }) + ' '}
                          {!isPreventedByPlugin('terminateEmployee') && (
                            <span
                              style={{ visibility: state.saving.includes('terminateDate') ? 'hidden' : 'visible' }}
                              className='ds-link'
                              onClick={(e) => saveValOther('terminateDate', null)}
                            >
                              {t('CANCEL')}
                            </span>
                          )}
                        </Text>
                      ) : (
                        <Text type={Text.TYPES.BODY_MEDIUM} text={t('EMPLOYEE_PROFILE_ROLE_END_TXT')} />
                      )}

                    </Flex>
                    <Spacing size={Spacing.SIZES.SIZE_40} type={Spacing.TYPES.HORIZONTAL} />
                    <Flex flex='1'>
                      {(isPreventedByPlugin('terminateEmployee') || employeeDetail.terminateDate) ? (
                        <Button
                          disabled
                          size={Button.SIZES.LARGE}
                          label={t('DELETE')}
                        />
                      ) : (
                        <Tooltip
                          clickable
                          className='ds-ep-other-remove-role'
                          position={Tooltip.POSITIONS.LEFT}
                          closeDetection={state.closeTool}
                          anchor={(
                            <Button
                              size={Button.SIZES.LARGE}
                              color={Button.COLORS.RED}
                              label={t('DELETE')}
                              disabled={employeeDetail.terminateDate}
                            />
                          )}
                        >
                          <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                            <Text
                              text={t('EMPLOYEE_PROFILE_ROLE_END_TITLE')}
                              type={Text.TYPES.BODY_LARGE}
                              weight={Text.WEIGHTS.BOLD}
                            />
                          </Spacing>
                          <Separator />
                          <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                            <RadioGroup
                              selectedValue={state.deleteRoleNow}
                              onChange={(v) => {
                                setState((prev) => ({
                                  ...prev,
                                  deleteRoleNow: Boolean(v),
                                  deleteRoleDate: v ? moment().format('YYYY-MM-DD') : prev.deleteRoleDate
                                }))
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            >
                              <label>
                                <Radio value />
                                <span>{t('EMPLOYEE_PROFILE_ROLE_END_NOW')}</span>
                              </label>
                              <label>
                                <Radio value={false} />
                                <span>{t('EMPLOYEE_PROFILE_ROLE_END_AT_DATE')}</span>
                              </label>
                            </RadioGroup>
                            <Spacing size={Spacing.SIZES.SIZE_16} />
                            <Input
                              size={Input.SIZES.FULL_WIDTH}
                              label={t('EMPLOYEE_PROFILE_ROLE_END_DATE')}
                              type='date'
                              value={state.deleteRoleNow ? moment().format('YYYY-MM-DD') : moment(state.deleteRoleDate).format('YYYY-MM-DD')}
                              onChange={(val) => {
                                if (!moment(val).isBefore(moment())) {
                                  setState((prev) => ({ ...prev, deleteRoleDate: val }))
                                }
                              }}
                              disabled={state.deleteRoleNow}
                            />
                          </Spacing>
                          <Separator />
                          <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.BOTH}>
                            <Flex>
                              <Button
                                size={Button.SIZES.FULL}
                                label={t('CLOSE')}
                                onClick={() => setState({ closeTool: state.closeTool + 1 })}
                              />
                              <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
                              <Button
                                size={Button.SIZES.FULL}
                                style={Button.STYLES.CONTAINED}
                                color={Button.COLORS.RED}
                                label={t('DELETE')}
                                onClick={(e) => terminateUser(Boolean(state.deleteRoleNow))}
                              />
                            </Flex>
                          </Spacing>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : null}
        </Flex>
      )}
      {hasWritePermission && miscUtil.getFilteredContentForOrganization('employee_card_hide_emp', (
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
            {t('EMPLOYEE_PROFILE_HIDDEN')}
          </Text>
          <Spacing size={Spacing.SIZES.SIZE_2} />
          <Flex align={Flex.POSITION.CENTER}>
            <Flex flex='3' align={Flex.POSITION.CENTER}>
              {hidden
                ? <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8}><Alert text={t('EMPLOYEE_PROFILE_HIDDEN_DESCRIPTION')} type={Alert.TYPES.ERROR} size={Alert.SIZES.FULL_WIDTH} variant={Alert.VARIANTS.SLIM} /></Spacing>
                : <Text type={Text.TYPES.BODY_MEDIUM}>{t('EMPLOYEE_PROFILE_HIDDEN_DESCRIPTION')}</Text>}
            </Flex>
            <Spacing size={Spacing.SIZES.SIZE_40} type={Spacing.TYPES.HORIZONTAL} />
            <Flex flex='1'>
              <Dropdown
                size={Dropdown.SIZES.LARGE}
                style={Dropdown.STYLES.LIGHT}
                type={Dropdown.TYPES.VARIABLE}
                value={trueFalseOptions.find(opt => opt.value === Boolean(hidden))}
                singleSelect
                onChange={(val) => {
                  setForm('role', {
                    ...form.role,
                    hidden: Boolean(val.value)
                  })
                }}
                placeholder={t('EMPLOYEE_PROFILE_HIDDEN')}
                options={trueFalseOptions}
              />
            </Flex>
          </Flex>
        </Flex>
      ))}
      {isOwn && (
        <Flex direction={Flex.DIRECTION.COLUMN}>
          <Spacing size={Spacing.SIZES.SIZE_14} />
          <Flex justify={Flex.POSITION.SPC_BETWEEN}>

            <Flex direction={Flex.DIRECTION.COLUMN}>
              <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>
                {t('EMPLOYEE_PROFILE_PRIVILEGES_CLEAN_TIT')}
              </Text>
              <Spacing size={Spacing.SIZES.SIZE_2} />
              <Text type={Text.TYPES.BODY_MEDIUM}>
                {t('EMPLOYEE_PROFILE_PRIVILEGES_CLEAN_DESC')}
              </Text>
            </Flex>
            <Button
              label={t('EMPLOYEE_PROFILE_PRIVILEGES_CLEAN')}
              size={Button.SIZES.LARGE}
              color={Button.COLORS.RED}
              onClick={() => {
                const store = JSON.parse(window.localStorage.getItem('ds-store'))
                store.tablesConfig = {}
                window.localStorage.removeItem('ds-org-persistent-state')
                window.localStorage.setItem('ds-store', JSON.stringify(store))
                notification.success({ message: t('EMPLOYEE_PROFILE_PRIVILEGES_CLEAN_SUCCESS') })
              }}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
})
