import { t } from 'i18next'

import resetStore from '../action/reset-store'
import eT from '../const/error-type'

const reduceErrors = (errors = []) => {
  if (!errors.length) {
    return null
  }
  const newErrors = errors.map((error) => {
    return _resolveError(undefined, [error]) || error
  })

  // TODO reduce eeror of same type - so far backend doesnt send too much info about error type

  return newErrors
}

function _resolveError (request, errors) {
  if (!errors.length) return null

  if (errors[0].message === 'Context creation failed: Invalid token') {
    // auth token no longer valid - call logOut() function
    console.log('Auth token no longer valid. Reseting store and reloading the app.')
    resetStore()

    return {
      request,
      type: eT.API_ERR_INVALID_TOKEN,
      message: t('API_ERR_INVALID_TOKEN')
    }
  }

  if (errors[0].message === 'Bad login') {
    return {
      request,
      type: eT.API_ERR_BAD_LOGIN,
      message: t('API_ERR_BAD_LOGIN')
    }
  }
  if (errors[0].message === 'Same email already requested on this workspace') {
    return {
      request,
      type: eT.API_ERR_ALREADY_REQUESTED,
      message: t('API_ERR_ALREADY_REQUESTED')
    }
  }
  if (errors[0].message === 'User email already exists on target workspace') {
    return {
      request,
      type: eT.API_ERR_ALREADY_MEMBER,
      message: t('API_ERR_ALREADY_MEMBER')
    }
  }
  if (errors[0].message === 'Invalid token') {
    return {
      request,
      type: eT.API_ERR_INVALID_ACTIVATE,
      message: t('API_ERR_INVALID_ACTIVATE')
    }
  }
  if (errors[0].message.match('Invalid input: PATTERN should match pattern')) {
    return {
      request,
      type: eT.API_ERR_BAD_PHONE,
      message: t('API_ERR_BAD_PHONE')
    }
  }
  if (errors[0].message.match('Period duration must be between')) {
    return {
      request,
      type: eT.API_ERR_ILLEGAL_PERIOD_DURATION,
      message: t('API_ERR_ILLEGAL_PERIOD_DURATION')
    }
  }

  if (errors[0].message === 'Not found: user') {
    // we don't really want to tell here
    // so we leave it as unknown error
    if (request === 'user-password-forgotten') {
      return defaultError(request)
    }
    return {
      request,
      type: eT.API_ERR_USER_NOT_FOUND,
      message: t('API_ERR_USER_NOT_FOUND')
    }
  }
  if (errors[0].message === 'You already answered') {
    return {
      request,
      type: eT.API_ERR_ALREADY_ANSWERED,
      message: t('API_ERR_ALREADY_ANSWERED')
    }
  }
  if (errors[0].message === 'Error: Old password does not match.') {
    return {
      request,
      type: eT.API_ERR_WRONG_OLD_PASSWORD,
      message: t('API_ERR_WRONG_OLD_PASSWORD')
    }
  }

  if (errors[0].message === 'Error: Cannot delete this shift, there are shifts created from this stand by shift.') {
    return {
      request,
      type: eT.API_ERR_CANT_DELETE_STANDBY,
      message: t('API_ERR_CANT_DELETE_STANDBY')
    }
  }

  return null
}

function defaultError (request, message, code) {
  return {
    error: {
      type: eT.ERR_UNKNOWN,
      request,
      message,
      code
    }
  }
}

function apiError (request, errors) {
  const error = _resolveError(request, errors)
  if (error) return { error }
  return defaultError(
    request,
    (errors && errors.length > 0 && errors[0].message) ? errors[0].message : '',
    (errors && errors.length > 0 && errors[0].extensions) ? errors[0].extensions.code : null
  )
}

function noResponse () {
  return {
    error: {
      type: eT.API_ERR_NO_RESPONSE
    }
  }
}

// view helpers

function hasField (field, errors) {
  return !!errors.find((err) => err.field === field)
}

function hasType (type, errors) {
  return !!errors.find((err) => err.type === type)
}

function filterField (field, errors) {
  return errors.filter((err) => err.field !== field)
}

// typically for missing-requires, potentially other types
// avoid multiple errors of same type in common error areas
function uniqueTypes (errors) {
  return errors.reduce((acc, err) => {
    if (hasType(err.type, acc)) return acc
    return [...acc, err]
  }, [])
}

function getErrorMessage (errVal) {
  for (var i = 0; i < Object.keys(eT).length; i++) {
    const k = Object.keys(eT)[i]

    if (eT[k] === errVal) {
      return t(k)
    }
  }
}

export default {
  defaultError,
  apiError,
  noResponse,
  hasField,
  hasType,
  filterField,
  uniqueTypes,
  getErrorMessage,
  reduceErrors
}
