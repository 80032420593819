import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  safePolygon,
  useClick,
  useDismiss,
  useFloating, useHover,
  useInteractions
} from '@floating-ui/react-dom-interactions'
import {
  autoUpdate,
  flip,
  hide,
  shift
} from '@floating-ui/react-dom'

import './index.scss'

const POSITIONS = Object.freeze({
  TOP: 'top-start',
  BOTTOM: 'bottom-start',
  LEFT: 'left-start',
  LEFT_BOTTOM: 'left-end',
  RIGHT: 'right-start',
  RIGHT_BOTTOM: 'right-end',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start'
})

export const Tooltip = ({
  position,
  anchor,
  children,
  text,
  width,
  maxHeight,
  clickable,
  openOnMount,
  className, // Content classname
  closeCallback,
  closeDetection,
  middleware
}) => {
  const [open, setOpen] = useState(false)
  const { x, y, context, reference, floating, strategy } = useFloating({
    open,
    onOpenChange: (open) => {
      setOpen(open)
      if (closeCallback && !open) closeCallback()
    },
    strategy: 'fixed',
    placement: position,
    middleware: middleware || [flip(), shift(), hide()],
    whileElementsMounted: (reference, floating, update) => {
      autoUpdate(reference, floating, update, {
        animationFrame: true
      })
    }
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: clickable
    }),
    useHover(context, {
      enabled: !clickable,
      handleClose: safePolygon({
        buffer: 10
      }),
      delay: {
        open: 200,
        close: 20
      }
    }),
    useDismiss(context, {
      outsidePointerDown: true
    })
  ])
  const floatContainer = {
    ...getFloatingProps({
      ref: floating,
      style: {
        position: strategy,
        left: x ?? 0,
        top: y ?? 0
      }
    })
  }

  useEffect(() => { openOnMount && setOpen(true) }, [])
  useEffect(() => { !openOnMount && setOpen(false) }, [closeDetection])

  return (
    <div className={classNames('tooltip')}>
      <div className='anchor' {...getReferenceProps({ ref: reference })}>
        {anchor}
      </div>
      {open && (
        <div
          className={classNames(
            'detect-zone',
            className,
            { 'text-only': !!text }
          )}
          {...floatContainer}
        >
          <div
            className='content'
            style={{ width, maxHeight }}
          >
            {children}
            {!!text && (
              <div className='text'>
                {text}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(Object.values(POSITIONS)),
  anchor: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  text: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  clickable: PropTypes.bool,
  openOnMount: PropTypes.bool,
  className: PropTypes.string,
  closeDetection: PropTypes.number,
  closeCallback: PropTypes.func, // optional callback function that's called when the tooltip is closed
  middleware: PropTypes.arrayOf(PropTypes.object)
}
Tooltip.defaultProps = {
  position: POSITIONS.RIGHT,
  width: 'auto',
  maxHeight: 'auto',
  children: null,
  text: null,
  clickable: false,
  openOnMount: false,
  classNames: '',
  closeDetection: 0,
  closeCallback: null,
  middleware: undefined
}

Tooltip.POSITIONS = POSITIONS
