import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { t } from 'i18next'
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout
} from 'react-table'

import {
  isNotNull,
  miscUtil,
  notification
} from '@app/util'
import { Alert, Checkbox, EditInfoPanel, Icon } from '@ui'

import connect from './connect'
import {
  LoadingTable,
  NoData,
  Pagination,
  SelectFilter,
  ActionBar,
  TableHead,
  TableFooter,
  NoColumns,
  TableBody
} from './components'
import { handleScrollShadows } from './helpers'
import './style.scss'

const SIZE = {
  SLIM: 'table-slim',
  REGULAR: 'table-regular'
}

const TYPES = {
  LEAN: 'lean',
  FILL: 'fill'
}

const Table = ({
  rows: data,
  extraButtonContent,
  extraContent,
  extraContentRow,
  pagination,
  handleOnSelect,
  handleOnFilteredRows,
  tableName,
  tableConfig,
  isLoading,
  className,
  exportHidden,
  exportFileName,
  rowOnMouseLeave,
  customNoDataText,
  onTableSave,
  onBatchActions,
  batchActionsDisabled,
  bordered,
  size,
  extraRow,
  initialSortBy,
  initialHiddenColumns,
  handleDisabledSubmit,
  showActionBar,
  showConfig,
  showSelection,
  actionBarRef,
  tablesConfig,
  updateTablesConfig,
  type,
  maxHeight,
  savingLoading,
  loadingData
}) => {
  const [editedRows, setRows] = useState([])
  const [tableData, setTableData] = useState([])

  const savedHiddenColumns = Object.keys(tablesConfig).includes(tableName)
    ? tablesConfig[tableName].hiddenColumns : null
  const [initHiddenCols, setInitHiddenCols] = useState(savedHiddenColumns || initialHiddenColumns)

  const savedSortBy = Object.keys(tablesConfig).includes(tableName)
    ? tablesConfig[tableName].sortBy : null

  const [initSortBy, setInitSortBy] = useState(savedSortBy || initialSortBy)

  const defaultActionBarRef = useRef()
  const tableRef = useRef()

  const getTransformedColumns = useMemo(
    () =>
      tableConfig.map((column) => {
        const sortFunction = (rowA, rowB) => {
          return column.rowToStr &&
            column.rowToStr(rowA.original) < column.rowToStr(rowB.original) ? 1 : -1
        }
        const filterFunction = (rows, columnIds, filterValue) => {
          const normalize = (value) => {
            const revertedValue = value === 'true' ? true : value === 'false' ? false : value
            const stringified =
              typeof revertedValue === 'number'
                ? revertedValue.toString()
                : revertedValue
            return typeof stringified === 'boolean' ? stringified : stringified
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          }

          return rows.filter(
            (row) =>
              filterValue &&
              filterValue.some((el) => {
                const rowItem = column.rowToFilterType
                  ? column.rowToFilterType(row.original)
                  : column.rowToStr(row.original)
                if (
                  typeof rowItem === 'string' ||
                  typeof rowItem === 'number' ||
                  typeof rowItem === 'boolean'
                ) {
                  return (normalize(rowItem) || '').includes(normalize(el))
                } else if (Array.isArray(rowItem)) {
                  return (
                    rowItem &&
                    rowItem.some((item) => normalize(item.value) === normalize(el))
                  )
                } else {
                  return rowItem && normalize(rowItem.value) === normalize(el)
                }
              })
          )
        }

        const widthAdjustment = column.groupBorder ? (size = SIZE.SLIM ? 1 : 1.25) : 0
        return {
          accessor: column.key,
          disableSortBy: !column.sortable,
          toggleHidden: true,
          width: (8.75 + widthAdjustment) + 'rem',
          sortType: (!!column.sortable && !!sortFunction) ? sortFunction : () => { },
          filter: filterFunction,
          Filter: column.filterable
            ? ({ rows, ...props }) => {
              const rowsRef = useRef()
              rowsRef.current = rows

              return (
                <SelectFilter
                  handleOnCollapsed={handleOnFilteredRows ? () => {
                    // call the handleOnFilteredRows(rows) callback when the list of rows changes due to filter change
                    handleOnFilteredRows(rowsRef.current)
                  } : undefined}
                  configColumn={column}
                  {...props}
                />
              )
            }
            : null,
          ...column
        }
      }),
    [tableConfig]
  )
  const memoizedData = useMemo(() => data || [], [data])

  const defaultColumn = useMemo(() => ({ Filter: null }), [])

  const {
    getTableProps,
    selectedFlatRows,
    headerGroups,
    rows,
    prepareRow,
    page,
    allColumns,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    pageOptions,
    setPageSize,
    setAllFilters,
    setHiddenColumns,
    state: { pageIndex, pageSize, filters, hiddenColumns, sortBy }
  } = useTable(
    {
      columns: getTransformedColumns,
      data: memoizedData,
      defaultColumn,
      autoResetPage: false,
      autoResetFilters: false,
      initialState: {
        pageSize: 100,
        hiddenColumns: initHiddenCols,
        sortBy: initSortBy
          ? Array.isArray(initSortBy)
            ? initSortBy
            : [initSortBy]
          : undefined
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      showSelection && hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '3.25rem',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox
              type='interminate'
              {...getToggleAllRowsSelectedProps()}
            />
          ),
          Cell: ({ row }) => (
            <Checkbox
              type='interminate'
              {...row.getToggleRowSelectedProps()}
            />
          )
        },
        ...columns
      ])
    }
  )

  React.useEffect(() => {
    if (isNotNull(handleOnFilteredRows)) { handleOnFilteredRows(rows) }
  }, [data])

  const hasEmpty = filters.some((filter) => filter.value === null || filter.value === '')
  const dataSource = pagination ? page : rows
  const shownColumns = !!allColumns.map((col) => col.id).filter((col) => ![...hiddenColumns, 'selection', 'menu'].includes(col)).length

  const handleHiddenColumns = (hidden) => {
    setHiddenColumns(hidden)
    if (!Object.keys(tablesConfig).includes(tableName) || miscUtil.safeStringify(hidden) !== miscUtil.safeStringify(tablesConfig[tableName].hiddenColumns)) {
      setInitHiddenCols(hidden)
      updateTablesConfig(tableName, { hiddenColumns: hidden })
    }
  }

  const handleSortBy = () => {
    const newSortBy = (sortBy && sortBy.length) ? sortBy[0] : null
    if (!Object.keys(tablesConfig).includes(tableName) || miscUtil.safeStringify(newSortBy) !== miscUtil.safeStringify(tablesConfig[tableName].sortBy)) {
      setInitSortBy(newSortBy)
      updateTablesConfig(tableName, { sortBy: newSortBy })
    }
  }
  useEffect(() => { handleSortBy() }, [miscUtil.safeStringify(sortBy)])

  useEffect(() => { setInitFilters() }, [hasEmpty])

  useEffect(() => {
    !!handleOnSelect && handleOnSelect(selectedFlatRows)
  }, [selectedFlatRows.length])

  useEffect(() => {
    setTableData(dataSource)
    setRows([])
  }, [dataSource])

  const handleActionBarDettachment = () => {
    if (actionBarRef) {
      const oldParent = document.querySelector('.ds-table-detachable-node')

      while (oldParent.childNodes.length > 0) {
        if (actionBarRef) {
          actionBarRef.current.appendChild(oldParent.childNodes[0])
        } else {
          defaultActionBarRef.current.appendChild(oldParent.childNodes[0])
        }
      }
    }
  }
  useEffect(() => { handleActionBarDettachment() }, [])

  const handleEditSave = async () => {
    try {
      const res = await onTableSave(editedRows)
      if (res !== false) {
        setRows([])
        notification.success({
          message: 'SUCCESS_MESSAGE.editSaved'
        })
      }
    } catch (e) {
      console.log(e)
      notification.error({ message: 'ERR_MESSAGE.editFailed' })
    }
  }

  const handleFooterDataSource = () => {
    if (extraRow && tableData.length && tableConfig.some((el) => !!el.extraRowToHTML)) {
      return [{ ...tableData[tableData.length - 1] }]
    }
    return []
  }

  const setInitFilters = () => setAllFilters(
    filters.filter((filter) => !(filter.value === null || filter.value === ''))
  )
  const showDataLoader = isLoading || loadingData

  return (
    <div className={classNames(
      'table-wrapper',
      type,
      size,
      {
        'empty-header': !!actionBarRef && !extraContent && !editedRows.length && !showDataLoader
      }
    )}
    >
      {(extraContent || !!actionBarRef || !!editedRows.length || showDataLoader) && (
        <div className='table-header'>
          {extraContent}
          <div ref={defaultActionBarRef} />
          {showDataLoader && (
            <div className='data-loader'>
              <Alert
                text={t('LOADING_DATA')}
                customIco={Icon.ICONS.loader}
                size={Alert.SIZES.FULL_WIDTH}
                type={Alert.TYPES.INFO}
              />
            </div>
          )}
          <ActionBar
            showActionBar={showActionBar}
            editedRows={editedRows}
            extraButtonContent={extraButtonContent}
            allColumns={allColumns}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={handleHiddenColumns}
            exportHidden={exportHidden}
            exportFileName={exportFileName}
            tableName={tableName}
            selectedFlatRows={selectedFlatRows}
            getTransformedColumns={getTransformedColumns}
            onBatchActions={onBatchActions}
            batchActionsDisabled={batchActionsDisabled}
            showConfig={showConfig}
          />
          {!!editedRows.length && (
            <EditInfoPanel
              onSave={handleEditSave}
              onCancel={() => setRows([])}
              count={editedRows.length}
              isDisabled={handleDisabledSubmit === null ? false : handleDisabledSubmit(editedRows, data)}
              savingLoading={savingLoading}
            />
          )}
        </div>
      )}
      {extraContentRow && (<div className='extra-content-row'>{extraContentRow}</div>)}
      {shownColumns ? (
        <div
          ref={tableRef}
          onScroll={(e) => handleScrollShadows(e, tableRef)}
          className={classNames(
            'table-new',
            `table-${tableName}`,
            className,
            size,
            {
              bordered: bordered
            }
          )}
          {...getTableProps()}
          style={maxHeight ? { maxHeight } : undefined}
        >
          {isLoading && (<LoadingTable withHead />)}
          {!isLoading && (
            <>
              <TableHead
                headerGroups={headerGroups}
              />
              {tableData && !!tableData.length && (
                <TableBody
                  rowOnMouseLeave={rowOnMouseLeave}
                  prepareRow={prepareRow}
                  tableData={tableData}
                  setRows={setRows}
                  editedRows={editedRows}
                  getTableBodyProps={getTableProps}
                />
              )}
              {extraRow && !!tableData?.length && (
                <TableFooter
                  dataSource={handleFooterDataSource()}
                  prepareRow={prepareRow}
                  rowOnMouseLeave={rowOnMouseLeave}
                  extraRow={extraRow}
                />
              )}
              {loadingData && (!tableData || !tableData.length) && (
                <LoadingTable />
              )}
              {!loadingData && tableData && !tableData.length && (
                <NoData customNoDataText={customNoDataText} />
              )}
            </>
          )}
        </div>
      ) : <NoColumns />}
      {pagination && (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={rows.length}
        />
      )}
    </div>
  )
}

Table.propTypes = {
  initialHiddenColumns: PropTypes.arrayOf(PropTypes.string),
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),
  showActionBar: PropTypes.bool,
  showConfig: PropTypes.bool,
  showSelection: PropTypes.bool,
  extraButtonContent: PropTypes.node,
  extraContent: PropTypes.node,
  extraContentRow: PropTypes.node,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  loadingData: PropTypes.bool,
  dataReloading: PropTypes.bool,
  exportHidden: PropTypes.bool,
  exportFileName: PropTypes.string,
  className: PropTypes.string,
  customNoDataText: PropTypes.string,
  handleOnSelect: PropTypes.func,
  handleDisabledSubmit: PropTypes.func,
  handleOnFilteredRows: PropTypes.func,
  onTableSave: PropTypes.func,
  onBatchActions: PropTypes.func,
  batchActionsDisabled: PropTypes.bool,
  bordered: PropTypes.bool,
  size: PropTypes.string,
  extraRow: PropTypes.bool,
  type: PropTypes.string,
  maxHeight: PropTypes.string
}

Table.defaultProps = {
  rows: [],
  initialSortBy: [],
  initialHiddenColumns: [],
  showActionBar: true,
  showSelection: true,
  showConfig: true,
  extraButtonContent: null,
  extraContent: null,
  extraContentRow: null,
  className: '',
  isLoading: false,
  loadingData: false,
  exportHidden: false,
  exportFileName: null,
  customNoDataText: null,
  bordered: false,
  handleOnSelect: null,
  handleDisabledSubmit: null,
  handleOnFilteredRows: null,
  pagination: true,
  onTableSave: () => null,
  onBatchActions: null,
  batchActionsDisabled: false,
  size: SIZE.REGULAR,
  extraRow: false,
  type: TYPES.FILL,
  maxHeight: '',
  rowOnMouseLeave: null,
  actionBarRef: null,
  savingLoading: false,
  updateTablesConfig: null
}

Table.SIZE = SIZE
Table.TYPES = TYPES

export default connect(Table)
