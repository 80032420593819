"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const ac_1 = require("@app/ac");
const mapStateToProps = (state) => {
    const { workspaceId, workspaces, sidebar, calendarMultiSelect, positions, me, calendar } = state;
    const ws = workspaces.find(w => w.id === workspaceId);
    return {
        me,
        ws,
        sidebar,
        calendarMultiSelect,
        calendar,
        positions,
    };
};
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({
        createPlan: ac_1.createPlan,
        deleteCurrentPlan: ac_1.deleteCurrentPlan,
        setSidebar: ac_1.setSidebar,
        setModal: ac_1.setModal,
        setCalendarMultiSelect: ac_1.setCalendarMultiSelect,
    }, dispatch);
};
exports.default = (component) => {
    return (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(component);
};
