import React from 'react'
import { t } from 'i18next'

import { Dropdown } from '@ui'

import config from '@app/const/config'

export const Language = ({
  language,
  handleChange
}) => {
  const languages = config.supportedLanguages

  return (
    <Dropdown
      singleSelect
      style={Dropdown.STYLES.LIGHT}
      size={Dropdown.SIZES.EXTRA}
      type={Dropdown.TYPES.VARIABLE}
      placeholder={t('ACC_SET_SELECT_LANGUAGE')}
      value={languages.find((lan) => lan.value === language)}
      label={t('ACC_SET_SELECT_LANGUAGE')}
      onChange={(val) => handleChange(val.value)}
      options={languages}
    />
  )
}

Language.propTypes = {}
Language.defaultProps = {}
