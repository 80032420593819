import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Flex,
  Text,
  Icon,
  Sidebar,
  Spacing
} from '@ui'
import { StatusBadge } from '@ui/NotificationCard/StatusBadge'

import './notifications.scss'
import connect from './connect'
import { NotificationsInfiniteList } from './NotificationsInfiniteList'
import NoNotificationsSvg from '@app/image/no-notifs-image.svg'

function _NotificationsSidebar ({
  onClose,
  notifications,
  history,
  setSidebar,
  ResolveNotification,
  ReadNotifications
}) {
  React.useEffect(() => () => ReadNotifications(), [])

  const sortedNotifications = React.useMemo(() => {
    return notifications
      ? notifications.sort((a, b) => {
        if (moment(a.created).isBefore(moment(b.created))) {
          return 1
        }
        if (moment(a.created).isAfter(moment(b.created))) {
          return -1
        }
        return 0
      })
      : []
  }, [notifications])

  const unreadNotificationsCount = React.useMemo(
    () => sortedNotifications.filter((notification) => !notification.read).length,
    [sortedNotifications]
  )

  function handleOpenNotification ({ id, message }) {
    ResolveNotification(id)
    if (message.uri.startsWith('/')) {
      history.push(message.uri)
    } else {
      window.open(message.uri, '_blank')
    }
    setSidebar(null)
  }

  return (
    <Sidebar
      onClose={onClose}
      width='18.25rem'
      side={Sidebar.SIDES.LEFT}
      showNavBar
    >
      <div className='ds-notifications-sidebar'>
        <div className='header-container'>
          <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.CENTER} stretch>
            <Text type={Text.TYPES.HEADLINE2} color={Text.COLORS.PRIMARY}>
              {t('NOTIFICATION')}
            </Text>
            <Icon
              color={Icon.COLORS.PRIMARY}
              ico={Icon.ICONS.close1}
              size={Icon.SIZES.MEDIUM}
              onClick={onClose}
            />
          </Flex>
          {unreadNotificationsCount > 0 && (
            <>
              <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_8} />
              <StatusBadge
                count={unreadNotificationsCount}
                type={StatusBadge.TYPES.SUMMARY}
              />
            </>
          )}
          {sortedNotifications.length === 0 && (
            <Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_24}>
              <NoNotificationsSvg />
            </Spacing>
          )}
        </div>
        <NotificationsInfiniteList
          notifications={sortedNotifications}
          onOpenNotification={handleOpenNotification}
        />
      </div>
    </Sidebar>
  )
}

export const NotificationsSidebar = connect(_NotificationsSidebar)
