import { requestWorkspaceLocalities, requestEmployeeDetail } from '@app/request'
import setEmployeeDetail from '../action/set-employee-detail'

import isLoading from './is-loading'
import store from '../store'
import requestPositions from '@app/request/positions'
import moment from 'moment'
import { miscUtil } from '@app/util'

export default (user, silent, reload) => {
  return async (dispatch) => {
    const {
      auth,
      workspaceId,
      workspaces,
      positions: wsPositions,
      employees
    } = store.getState()
    const workspace = workspaces.find((ws) => ws.id === workspaceId)

    if (!workspaceId) return
    if (!silent) dispatch(isLoading('employee-detail'))

    const vars = { user, workspace: workspaceId }
    let finalLocalities = []
    let finalPositions = []
    let finalRole = {}
    let finalEmployee = {}

    if (!reload && !!employees.length) {
      finalLocalities = workspace?.localities || []
      finalPositions = wsPositions || []
      const res = await requestEmployeeDetail(vars, false, auth)
      finalEmployee = { ...employees[user], ...res.user }
      finalRole = res || {}
    } else {
      finalLocalities = await requestWorkspaceLocalities({ workspaceId }, auth)
      finalPositions = await requestPositions({ id: workspaceId }, auth)
      const res = await requestEmployeeDetail(vars, true, auth)

      finalEmployee = (res && res.user)
        ? {
          ...res.user,
          contracts: res.contracts,
          employeeWarnings: (res?.user?.workspaces?.find((ws) => ws.id === workspaceId)?.employeeWarnings?.length) ? res.user.workspaces.find((ws) => ws.id === workspaceId).employeeWarnings[0].warnings : []
        }
        : {}

      finalRole = res || {}
    }

    finalLocalities = Array.isArray(finalLocalities) ? finalLocalities.filter((loc) => loc.assigns && loc.assigns.some((ass) => ass.userId === user)) : []
    finalPositions = miscUtil.getPositionsOnRole(finalRole, moment())

    // workspace-specific, manager-given name in Role.customData can override the original user's name
    let customName = null
    if (finalRole.customData && (finalRole.customData.firstName || finalRole.customData.lastName)) {
      customName = [
        finalRole.customData.firstName || finalRole.user?.firstName,
        finalRole.customData.lastName || finalRole.user?.lastName
      ].filter(s => !!s).join(' ')
    }
    const employeeDetail = Object.assign(
      {},
      finalEmployee,
      {
        options: {
          customAttributes: finalRole.customAttributes,
          contract: finalRole.contract,
          vacationDays: finalRole.vacationDays,
          calendarOrder: finalRole.calendarOrder,
          cycleId: finalRole.cycleId,
          cycleGroup: finalRole.cycleGroup,
          accessRoles: finalRole.user?.accessRoles,
          contracts: finalEmployee.contracts
        },
        employeeWarnings: finalEmployee.employeeWarnings,
        customData: finalRole.customData,
        terminateDate: finalRole.terminateDate,
        birthdate: finalRole.birthdate,
        terminalPIN: finalRole.terminalPIN,
        hidden: finalRole.hidden,
        name: customName || finalEmployee.name,
        localities: finalLocalities,
        positions: finalPositions
      }
    )
    dispatch(setEmployeeDetail(employeeDetail))
    if (!silent) dispatch(isLoading('employee-detail', true))
    return employeeDetail
  }
}
