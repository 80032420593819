import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { t } from 'i18next'
import DragSelect from 'dragselect'

import {
  Button,
  Tooltip,
  WeekSelect,
  DaySelect,
  Loader,
  Icon,
  Text,
  Switcher,
  MonthSelect,
  Flex,
  Spacing
} from '@ui'

import CalendarManager from '@ui/calendar/calendar-manager'
import {
  multiSelectUtil,
  momentUtil,
  calendarUtil
} from '@app/util'

import './index.scss'
import connect from './connect'

const Schedule = connect(({
  openSidebarType,
  calendar,
  calendarMultiSelect,
  workspaceId,
  workspaces,
  me,
  changedShiftsOnWS,
  isLoading,
  isLoadingEmployees,
  isLoadingPositions,
  isLoadingShiftsOrCalendar,
  shifts,
  availabilities,
  timeOffs,
  employees,
  positions,
  loadEmployees,
  loadPositions,
  loadTemplates,
  setModal,
  setCalendarMultiSelect,
  setCalendar,
  auth
}) => {
  momentUtil.setLocale() // sets moment's locale according to our store.locale value, so that the date formats are displayed correctly
  const [alreadyMounted, setAlreadyMounted] = useState(false)
  const [dragSelect, setDragSelect] = useState(null)

  const dragSelectRef = useRef()
  dragSelectRef.current = dragSelect
  const shiftsRef = useRef()
  shiftsRef.current = shifts
  const availRef = useRef()
  availRef.current = availabilities
  const timeOffRef = useRef()
  timeOffRef.current = timeOffs
  const msRef = useRef()
  msRef.current = calendarMultiSelect
  const calRef = useRef()
  calRef.current = calendar

  const _isMobileOrTouchscreen = () => {
    if (window.matchMedia('only screen and (max-device-width: 768px)').matches) return true // mobile devices
    if (window.matchMedia('only screen and (pointer: coarse)').matches) return true // touch screens
    return false
  }

  const _getSectionClosestToDragStart = (dragStartEvt) => {
    let closest = null
    let closestDist = 999999999
    Array.prototype.forEach.call(document.getElementsByClassName('ds-c-section'), (sect) => {
      const boundingBox = sect.getBoundingClientRect()
      if (dragStartEvt.clientY && boundingBox && dragStartEvt.clientY >= boundingBox.top) {
        const dist = dragStartEvt.clientY - boundingBox.top
        if (dist < closestDist) {
          closest = sect
          closestDist = dist
        }
      }
    })
    return closest
  }

  const _domElementToEvtData = (elem) => {
    const evtData = []
    const isSelectingMsTargets = !!msRef.current.isSelectingTargets
    const elemId = isSelectingMsTargets
      ? calendarUtil.getElementsColIdentifier(elem)
      : calendarUtil.getElementsEvtIdentifier(elem)
    if (!elemId) return []

    if (isSelectingMsTargets && elemId.length >= 2) {
      // data for a column
      const dataDate = elemId[1].split(calendarUtil.getIdentifierSeparator())[1]
      const dataUser = elemId[1].split(calendarUtil.getIdentifierSeparator())[2]
      evtData.push({ date: dataDate, userId: dataUser })
    } else {
      const evtId = elemId[0].split(calendarUtil.getIdentifierSeparator())[1]
      const sh = shiftsRef.current.find((s) => s.id === evtId)
      // data for a Shift
      if (sh) {
        evtData.push(sh)
      } else {
        // data for an Availability
        const av = availRef.current.find((ev) => ev.id === evtId)
        if (av) {
          evtData.push(av)
        } else {
          // data for a TimeOff
          const to = timeOffRef.current.find((ev) => ev.id === evtId)
          if (to) {
            evtData.push(to)
          }
        }
      }
    }

    return evtData
  }

  // former componentDidMount()
  useEffect(() => {
    if (!alreadyMounted && !!auth) {
      setAlreadyMounted(true)
      if ((!employees || !Object.keys(employees).length) && !isLoadingEmployees) loadEmployees()
      if ((!positions || !positions.length) && !isLoadingPositions) loadPositions()
      loadTemplates()

      // initialize the dragSelect
      if (!_isMobileOrTouchscreen() && !dragSelect && !dragSelectRef.current) {
        const ds = new DragSelect({
          customStyles: true, // If set to true, no styles (except for position absolute) will be applied by default.
          selectedClass: null,
          hoverClass: 'ds-ms-selector-hover',
          selectorClass: 'ds-ms-selector',
          selectableClass: null,
          draggability: false
        })

        // 'callback' callback :)
        ds.subscribe('callback', ({ event }) => {
          const isSelectingMsTargets = !!msRef.current.isSelectingTargets
          const isNotDrag = Math.abs(window.lastDragStartEvt?.screenX - event.screenX) <= 10 && Math.abs(window.lastDragStartEvt?.screenY - event.screenY) <= 10
          const isMultiselectKeyPressed = event.ctrlKey || event.metaKey || event.shiftKey
          const isClickedOnSelectable = isNotDrag && event.target && ds.getSelectables().includes(event.target)

          // since the list that we get from the dragselect library is not correct, we construct the
          // list of elements that should be selected on our own, depending on the situation:
          let elements = []

          // dragging over some area
          if (!isNotDrag) {
            elements = [...new Set([
              ...document.querySelectorAll('.ds-ms-selector-hover'), // whatever is hovered over by the selection box
              ...(isMultiselectKeyPressed ? document.querySelectorAll('.is-ms-selected') : []) // plus whatever was already selected - if Ctrl/Shift/Meta key is pressed
            ])]
          }

          // clicking on some selectable element
          if (isNotDrag && isClickedOnSelectable) {
            if (isMultiselectKeyPressed) elements = [...document.querySelectorAll('.is-ms-selected')] // if Ctrl/Shift/Meta key is pressed, we start with previously selected elements (otherwise with [])
            if (event.target.classList.contains('is-ms-selected')) elements = elements.filter(el => el !== event.target) // unselected previously selected elem if clicked
            if (!event.target.classList.contains('is-ms-selected')) elements.push(event.target) // add previously unselected elem if clicked
          }

          // clicking on some UNselectable element
          if (isNotDrag && !isClickedOnSelectable) {
            // cancel the current selection if we're not holding the Ctrl/Shift/Meta and we're not selecting MS targets
            if (!isMultiselectKeyPressed && !isSelectingMsTargets) {
              multiSelectUtil.multiSelectClear()
              elements = []
            }

            // if we have column context menu displayed, stop displaying that
            if (calRef.current?.contextMenuForColumn) {
              setCalendar({ contextMenuForColumn: undefined })
            }
          }

          // make sure we only select the elements from the section (top
          // or bottom), in which we started the drag event. otherwise, it tends to
          // select non-visible top-section elements that are scrolled under the
          // bottom-section elements
          if (!isSelectingMsTargets) {
            const dragStartEvt = (ds && window.lastDragStartEvt) ? window.lastDragStartEvt : null
            if (dragStartEvt) {
              const parentSection = _getSectionClosestToDragStart(dragStartEvt)
              const unasSectionEls = elements.filter(el => el.parentElement && el.parentElement.parentElement && el.parentElement.parentElement.parentElement && el.parentElement.parentElement.parentElement.classList.contains('is-section-unassigned'))
              const offSectionEls = elements.filter(el => el.parentElement && el.parentElement.parentElement && el.parentElement.parentElement.parentElement && el.parentElement.parentElement.parentElement.classList.contains('is-section-offers'))
              const botSectionEls = elements.filter(el => el.parentElement && el.parentElement.parentElement && el.parentElement.parentElement.parentElement && el.parentElement.parentElement.parentElement.classList.contains('is-section-bottom'))
              if (parentSection && parentSection.classList.contains('is-section-bottom')) {
                elements = botSectionEls
              } else {
                if (parentSection && parentSection.classList.contains('is-section-unassigned')) {
                  elements = unasSectionEls
                } else {
                  elements = offSectionEls
                }
              }
            }
          }

          // get event data for each of the selected elements in 'select' array
          let select = []
          Array.from([...elements]).forEach((elem) => {
            const dataEvt = _domElementToEvtData(elem)
            dataEvt.forEach(ev => {
              if (isSelectingMsTargets) {
                select.push(ev)
              } else {
                if (!select.find(e => (e.id === ev.id && e.recurring === ev.recurring && moment(e.period.start).isSame(ev.period.start) && moment(e.period.end).isSame(ev.period.end)))) {
                  select.push(ev)
                }
              }
            })
          })

          // when drag-selecting multiple target dates for 'copy' action, make sure we don't
          // select the date of the source event. this should only be allowed by single-selection.
          if (isSelectingMsTargets && calendarMultiSelect.action === 'copy' && calendarMultiSelect.sourceEvents.length === 1 && select.length > 1) {
            const source = calendarMultiSelect.sourceEvents[0]
            select = select.filter(s => s.userId !== source.userId || s.date !== moment(source.period.start).format('YYYY-MM-DD'))
          }

          // add selected events with their data into calendarMultiSelect.sourceEvents
          if (select.length) {
            if (isSelectingMsTargets) {
              setCalendarMultiSelect({
                targets: (msRef.current?.targets?.filter(t => !select.some(s => s.userId === t.userId && s.date === t.date)) || []).concat(select.map((evtData) => {
                  return Object.assign({}, evtData)
                }))
              })
            } else {
              setCalendarMultiSelect({
                sourceEvents: select.map((evtData) => {
                  return Object.assign({}, evtData, { day: moment(evtData.period.start).format('YYYY-MM-DD') })
                })
              })
            }
          }

          // remove hover indicator from everywhere
          document.querySelectorAll('.ds-ms-selector-hover').forEach(el => { el.classList.remove('ds-ms-selector-hover') })
        })

        // 'dragstart' event callback
        ds.subscribe('predragstart', ({ event }) => {
          const isSelectingMsTargets = !!msRef.current.isSelectingTargets

          // update the dragSelect's list of selectable events
          const els = isSelectingMsTargets
            ? document.querySelectorAll('.ds-c-col')
            : document.querySelectorAll('.ds-c-event:not(.is-external-offer)')
          if (els?.length) {
            ds.removeSelectables(ds.getSelectables(), true)
            ds.setSettings({ selectables: els }) // ds.setSelectables(els, true)
          }

          // prevent dragging from some places
          const dragForbiddenOn = [
            'ds-schedule-top',
            'ds-c-controls',
            'ds-c-header',
            'ds-c-statistics',
            'ds-c-capacities',
            'ds-c-cap-expand',
            'ds-modal-container',
            'ds-modal-content',
            'ds-sidebar',
            'ds-nav',
            'ds-c-event',
            'ds-c-section-divider',
            'ds-input-el',
            'ds-c-row-label',
            'detect-zone'
          ]
          let stopDrag = false
          let pageX = event.pageX
          let pageY = event.pageY
          if (typeof pageX === typeof undefined || typeof pageY === typeof undefined) {
            if (event.touches && event.touches.length) {
              pageX = event.touches[0].pageX
              pageY = event.touches[0].pageY
            }
          }

          dragForbiddenOn.forEach(cls => {
            if (!stopDrag && document.elementsFromPoint(pageX, pageY).find((el) => el.classList.contains(cls))) {
              stopDrag = cls
            }
          })

          // treat the clicks/drags on illegal elements
          if (stopDrag) {
            // remove hover indicator from everywhere and break
            document.querySelectorAll('.ds-ms-selector-hover').forEach(el => { el.classList.remove('ds-ms-selector-hover') })
            ds.break()
          } else {
            window.lastDragStartEvt = event // set window.lastDragStartEvt (it's used later from callback())

            const active = document.activeElement
            active.focus()
            active.blur()
            event.preventDefault()
          }
        })
        setDragSelect(ds)
      }

      return () => {
        // former componentWillUnmount()
        if (dragSelectRef.current) {
          dragSelectRef.current.stop(false, false)
          setDragSelect(null)
        }
        delete window.lastDragStartEvt
      }
    }
  }, [])

  // reload when switching WS
  useEffect(() => {
    loadEmployees()
    loadPositions()
    loadTemplates()
  }, [workspaceId])

  const csCount = changedShiftsOnWS
  if (isLoading || !workspaces.length) return <Loader size={Loader.SIZES.LARGE} />

  return (
    <div className={'ds-schedule' + (openSidebarType === 'planning' ? ' is-planning-sidebar-open' : '')}>
      <div className='ds-schedule-top'>
        <div className='ds-schedule-title ds-title'>
          {t('CALENDAR')}
        </div>

        {/* Select View */}
        <Switcher
          size={Switcher.SIZES.COMPACT}
          options={[
            { label: t('DAY'), value: 'day' },
            { label: t('WEEK'), value: 'week' },
            { label: t('MONTH'), value: 'month' }
          ]}
          value={(calendar && calendar.view) ? calendar.view : null}
          onSelect={(v) => {
            setCalendar({ view: v }, false)
          }}
        />
        <Spacing size={Spacing.SIZES.SIZE_12} type={Spacing.TYPES.HORIZONTAL}>
          {/* Current Date */}
          {calendar && calendar.view === 'month' && (
            <MonthSelect
              returnType={MonthSelect.RETURN_TYPES.STRING}
              style={MonthSelect.STYLES.BORDERLESS}
              value={calendar.date}
              onChange={(v) => {
                setCalendar({ date: v }, false)
              }}
            />
          )}

          {calendar && calendar.view === 'day' && (
            <Tooltip
              clickable
              anchor={
                <Button
                  bold
                  style={Button.STYLES.LIGHT}
                >
                  {moment(calendar.date).format(_isMobileOrTouchscreen() ? 'D.M.' : 'D. MMMM')}
                  <div className='ds-select-caret' />
                </Button>
              }
            >
              <DaySelect
                singleDaySelection
                value={moment(calendar.date)}
                onChange={(v) => {
                  if (v && v.length) setCalendar({ date: v[0] }, false)
                }}
              />
            </Tooltip>
          )}
          {calendar && calendar.view === 'week' && (
            <Tooltip
              clickable
              anchor={
                <Button
                  style={Button.STYLES.LIGHT}
                  bold
                >
                  {moment(calendar.date).startOf('week').format('D.')}
                  {moment(calendar.date).startOf('week').month() !== moment(calendar.date).endOf('week').month() ? moment(calendar.date).startOf('week').format('M.') : null}
                  {' - '}
                  {moment(calendar.date).endOf('week').format('D.M.')}
                  <div className='ds-select-caret' />
                </Button>
              }
            >
              <WeekSelect
                value={moment(calendar.date).startOf('week')}
                onChange={(v) => setCalendar({ date: v }, false)}
              />
            </Tooltip>
          )}
        </Spacing>

        {/* (<) Past / Future (>) */}
        <Button
          bold
          style={Button.STYLES.LIGHT}
          disabled={isLoadingShiftsOrCalendar}
          onClick={() => {
            if (calendar) {
              const newDate = moment(calendar.date).add(-1, `${calendar.view}s`).startOf(calendar.view).format('YYYY-MM-DD')
              setCalendar({ date: newDate }, false)
            }
          }}
          ico={Icon.ICONS.arrowLeft}
        />

        {/* Today */}
        <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.HORIZONTAL}>
          <Button
            bold
            style={Button.STYLES.LIGHT}
            onClick={() => {
              const newDate = moment().format('YYYY-MM-DD')
              setCalendar({ date: newDate }, false)
            }}
            label={t('TODAY')}
            testid='today-button'
          />
        </Spacing>

        <Button
          disabled={isLoadingShiftsOrCalendar}
          style={Button.STYLES.LIGHT}
          bold
          onClick={() => {
            if (calendar) {
              const newDate = moment(calendar.date).add(1, `${calendar.view}s`).startOf(calendar.view).format('YYYY-MM-DD')
              setCalendar({ date: newDate }, false)
            }
          }}
          ico={Icon.ICONS.arrowRight}
        />

        {csCount > 0 && (
          <div className='ds-schedule-btn-announce-wrapper'>
            <Flex>
              <Icon
                ico='upload'
                color={Icon.COLORS.WHITE}
              />
              <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
              <Text color={Text.COLORS.WHITE} weight={Text.WEIGHTS.BOLD}>
                {t('NOT_PUBLISH_X_CHANGES', { x: csCount })}
              </Text>
            </Flex>
            <Button
              size={Button.SIZES.SMALL}
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.WHITE}
              bold
              onClick={() => {
                if (me.hasPassword) {
                  setModal('publish-changes')
                }
              }}
              label={t('ANNOUNCE_SHORTER')}
            />
          </div>
        )}
      </div>

      <CalendarManager />
    </div>
  )
})

export default Schedule
