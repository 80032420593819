import React from 'react'
import { t } from 'i18next'
import moment from 'moment'
import {
  Alert,
  Text,
  Flex,
  Spacing
} from '@ui'

export const Warnings = ({ ee }) => {
  const employeeWarningsToDisplay = ee?.employeeWarnings ? ee.employeeWarnings.filter(ew => ew.type !== 'dummy') : []
  if (employeeWarningsToDisplay.length) {
    return employeeWarningsToDisplay.map((ew, i) => {
      const data = Object.assign({}, ew?.data)
      if (data?.date && moment(data.date).isValid()) data.date = moment(data.date).format('D. M. YYYY') // format data.date if it was sent from BE
      return (
        <Flex key={ew.type} direction={Flex.DIRECTION.COLUMN}>
          <Alert
            text={t('EMPLOYEE_WARNINGS.' + ew.type + '.title')}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
          <Spacing size={Spacing.SIZES.SIZE_8} />
          <Text type={Text.TYPES.BODY_MEDIUM}>
            {t('EMPLOYEE_WARNINGS.' + ew.type + '.desc', data)}
          </Text>
          <Spacing size={Spacing.SIZES.SIZE_8} />
        </Flex>
      )
    })
  }
  return null
}
