import React from 'react'
import { t } from 'i18next'

import { Flex, Input, Spacing } from '@ui'
import { errorUtil } from '@app/util'

export const Password = ({
  hasPassword,
  form,
  errors,
  setForm,
  validatePassword
}) => {
  const handleChange = (key, value) => setForm({ [key]: value })
  const oldPassErrors = errors.find((e) => e?.field === 'oldPassword')
  const newPassErrors = errors.find((e) => e?.field === 'newPassword')
  const repeatedPassErrors = errors.find((e) => e?.field === 'repeatedPassword')

  return (
    <form>
      {hasPassword && (
        <Input
          type={Input.TYPES.PASSWORD}
          disableLastpass
          size={Input.SIZES.LARGE}
          value={form?.oldPassword || ''}
          label={t('ACC_SET_OLD_PASSWORD')}
          onChange={(val) => handleChange('oldPassword', val)}
          hasError={!!oldPassErrors}
          onBlur={validatePassword}
          errorMessage={oldPassErrors && t('FORM_ERR_MISSING_REQUIRED')}
          placeholder=''
        />
      )}
      <Spacing size={Spacing.SIZES.SIZE_14} />
      <Flex>
        <Input
          type={Input.TYPES.PASSWORD}
          disableLastpass
          size={Input.SIZES.LARGE}
          value={form?.newPassword || ''}
          label={t('ACC_SET_NEW_PASSWORD')}
          onChange={(val) => handleChange('newPassword', val)}
          hasError={!!newPassErrors}
          onBlur={validatePassword}
          errorMessage={errorUtil.getErrorMessage(newPassErrors?.type)}
          placeholder=''
        />
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        <Input
          type={Input.TYPES.PASSWORD}
          disableLastpass
          size={Input.SIZES.LARGE}
          value={form?.repeatPassword}
          label={t('ACC_SET_REPEAT_PASSWORD')}
          onChange={(val) => handleChange('repeatPassword', val)}
          onBlur={validatePassword}
          hasError={!!repeatedPassErrors}
          errorMessage={errorUtil.getErrorMessage(repeatedPassErrors?.type)}
          placeholder=''
        />
      </Flex>
    </form>
  )
}

Password.propTypes = {}
Password.defaultProps = {}
